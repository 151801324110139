import React from 'react';
import { withRouter } from 'react-router-dom';
//import './videos_actions.scss'
import PageView from './render/pageRender.view'
import ToolbarListPage from '../../components/toolbar/toolbarListPage'

class App extends React.Component {

  constructor(props) {
    super(props);

    this.modelName = this.props.match.params.modelName || null
    this.Id = this.props.match.params.Id || null
  }

  render() {

    return (
      <>
        <ToolbarListPage modelName={this.modelName} />
        <PageView modelName={this.modelName} Id={this.Id} title={this.modelName + " дэлгэрэнгүй"} />
      </>
    );
  }

}

export default withRouter(App);

