import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IonLoading } from '@ionic/react';
import apiService from "api/apiService";
import MainCard from 'ui-component/cards/MainCard';

import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useForm } from "react-hook-form";

import { makeStyles } from '@material-ui/core/styles';
import {
    CardContent,
    Button,
    TextField,
    Grid,
    Snackbar,
    Alert
} from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

const IEOGraphAdd = () => {
    const classes = useStyles();
    const routerParams = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [IEO, setIEO] = useState();

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
      } = useForm();

    useEffect(() => {
        setValue('startDate', dayjs());
        setValue('endDate', dayjs());
        getIeo();
    }, [])

    const addGraphData = async (data) => {
        data.ieo = routerParams.ieoId;
        data.floorPrice = Number(data.floorPrice)
        data.ceilingPrice = Number(data.ceilingPrice)
        data.executionProcess = Number(data.executionProcess);
        data.startDate = dayjs(data.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        data.endDate = dayjs(data.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        data.description = data.description ? data.description : '';
        
        const response = await apiService.post('ieoGraph/create', data);
        if(response && response.status && response.status.success){
            setShowToast(true)
            setSuccess('Амжилттай нэмэгдлээ!')
        } else {
            setShowToast(true)
            setError(`Алдаа гарлаа! - ${response.error}`);
        }
    }

    const getIeo = async () => {
        const res = await apiService.findOne('ieo', routerParams.ieoId);
        console.log('response: ', res)
        if(res.data){
            setIEO(res.data);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowToast(false);
        setSuccess('')
        setError('')
    };

    return (
        loading ? <IonLoading isOpen={loading} /> :
        <MainCard title={`IEO Арилжааны үнэ нэмэх - ${IEO && IEO.name}`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <Typography variant="subtitle2" align="center" sx={{color: 'InfoText', paddingY: '1rem'}}>
                <strong>{showToast}</strong>
            </Typography> */}
            {success && <Snackbar open={showToast} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {success}
                </Alert>
            </Snackbar>}
            {error && <Snackbar open={showToast} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>}
            <CardContent className={classes.projectTableCard}>
                <form onSubmit={handleSubmit(addGraphData)}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller 
                                render={({field}) => (
                                    <DatePicker
                                        {...field}
                                        label="Эхлэх огноо"
                                        value={getValues('startDate')}
                                        onChange={(newValue) => setValue("startDate", newValue)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />
                                )}
                                control={control}
                                name="startDate"
                            />
                            
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                render={({field}) => (
                                    <DatePicker
                                        {...field}
                                        label="Дуусах огноо"
                                        value={getValues('endDate')}
                                        onChange={(newValue) => setValue('endDate', newValue)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />)
                                }
                                control={control}
                                name="endDate"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        required
                                        id="floorPrice"
                                        label="Шал үнэ"
                                        type="number"
                                        onChange={(event) => setValue('floorPrice', event.target.value)}
                                        fullWidth
                                    />
                                )}
                                control={control}
                                name="floorPrice"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        required
                                        id="ceilingPrice"
                                        label="Тааз үнэ"
                                        type="number"
                                        fullWidth
                                        onChange={(event) => setValue('ceilingPrice', event.target.value)}
                                    />
                                )}
                                control={control}
                                name="ceilingPrice"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        required
                                        id="executionProcess"
                                        label="Гүйцэтгэлийн хувь"
                                        type="number"
                                        onChange={(event) => setValue('executionProcess', event.target.value)}
                                        fullWidth
                                    />
                                )}
                                control={control}
                                name="executionProcess"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        id="description"
                                        label="Тайлбар"
                                        type="text"
                                        fullWidth
                                        onChange={(event) => setValue('description', event.target.value)}

                                    />
                                )}
                                control={control}
                                name="description"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type="button"
                                onClick={() => history.goBack()}
                                variant="outlined" color="primary">
                                Буцах
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained" color="primary">
                                Хадгалах
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            </LocalizationProvider>
        </MainCard>
    )
}

export default IEOGraphAdd;