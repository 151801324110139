import React from 'react';
import { withRouter } from 'react-router-dom';
import EventEmitter from 'events'
import ValidationSummary from 'devextreme-react/validation-summary';
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule
  } from 'devextreme-react/validator';
//import ValidationGroup from 'devextreme-react/validation-group';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import RadioGroup from 'devextreme-react/radio-group';
import notify from 'devextreme/ui/notify';
// import { Form } from 'devextreme-react/form';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import TagBox from 'devextreme-react/tag-box';
import CheckBox from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import NumberBox from 'devextreme-react/number-box';
import Gallery from 'devextreme-react/gallery';
import FileUploader from 'devextreme-react/file-uploader';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
// import ArrayStore from 'devextreme/data/array_store';
// import List from 'devextreme-react/list';
// import TileView from 'devextreme-react/tile-view';

import backendStore from '../api/backendStore';
import apiService from '../api/apiService'

import LoadIndicator from 'devextreme-react/load-indicator';
import ErrorComponent from '../components/error'

import './view.scss'

import { getModel, getDisplayFieldValue, isNotEmpty, getCircularReplacer } from './helpers'
import { itemRender } from './renderFunctions'

//import '../components/form/form.scss';
class PageRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            form: null,
            isAdded: false,
            submitButtonText: 'хадгалах',
            _id: null,
            modelData: {},
            formData: {},
            selectedFiles: [],
            hasAttachFile: false,
            modelStore: null,
            collections: [] // for Lookup, SelectBox data's
        }

        // getting Model settings name
        this.formId = this.props.match.params.formId || this.props.formId
        this.formDataId = this.props.match.params.formDataId || this.props.formDataId
        this.model = null //getModel(this.props.formId)
        
        this.renderComponent = this.renderComponent.bind(this)
        this.renderTextBox = this.renderTextBox.bind(this)
        this.renderTextArea = this.renderTextArea.bind(this)
        this.renderRadioGroup = this.renderRadioGroup.bind(this)
        this.renderCheckBox = this.renderCheckBox.bind(this)
        this.renderDateBox = this.renderDateBox.bind(this)
        this.renderNumberBox = this.renderNumberBox.bind(this)
        this.renderSelectBox = this.renderSelectBox.bind(this)
        this.renderLookup = this.renderLookup.bind(this)
        this.renderFileUpload = this.renderFileUpload.bind(this)
        this.renderTitle = this.renderTitle.bind(this)
        this.renderTagBox = this.renderTagBox.bind(this)
        this.renderHtmlEditor = this.renderHtmlEditor.bind(this)

        this.getFieldValue = this.getFieldValue.bind(this)
        this.getFieldCollectionValue = this.getFieldCollectionValue.bind(this)

        this.valueChanged = this.valueChanged.bind(this)
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onSelectedFilesChanged = this.onSelectedFilesChanged.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);

        //console.log(' model :::: ' + JSON.stringify(this.model))
    }

    // data from Parent comp props
    static getDerivedStateFromProps(nextProps) {
        return {
                formId: nextProps.formId, formDataId: nextProps.formDataId
            };
    }
    // static getDerivedStateFromProps(nextProps) {
    //     const propsModel = getModel(nextProps.formId)
    //     const store = backendStore({entityName: propsModel.name});
    //     console.log(' data: ' + JSON.stringify(propsModel))

    //     //if (nextProps.dataSource) {   /// TODO: DataSource from parantComponents
    //         console.log('dataSource from parentComponent ' + nextProps.formId)
    //         console.log(' data: ' + JSON.stringify(nextProps.dataSource))

    //         return {
    //             model: propsModel, modelData: nextProps.dataSource, modelStore: store
    //         };
    //     //}
    // }
    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.formId !== this.props.match.params.formId) {
          this.setState({ isLoading: true })
          const { formId, formDataId } = this.props.match.params
          await this.fetchData(formId, formDataId)
        }
    }

    async componentDidMount() {
        await this.fetchData(this.formId, this.formDataId || null)
    }

    async fetchData(formId, formDataId) {
        // loading Data
        //console.log(' formId ' + JSON.stringify(formId) + ' formDataId ' + JSON.stringify(formDataId))
        const propsForm = await getModel(formId)
        //const store = backendStore({ entityName: propsModel.name });
        //console.log(' Loaded Data from API ' + JSON.stringify(propsModel))
        this.setState({ form: propsForm })
        // by ID
        if (formDataId) {
            const result = await apiService.findOne('formdata', formDataId)
            if (result.status.success) {
                //console.log(' Loaded Data from API ' + JSON.stringify(result))
                const modelData = JSON.parse(result.data.data)
                this.setState({ modelData: modelData, formData: modelData, isAdded: true, _id: formDataId })
                //this.setState({ ...this.state, modelStore: store })
                // Loading Assets files
                if (result.data.assets) {
                    // Show to user the SelectedFiles changes
                    let newFiles = []
                    result.data.assets.map(file => {
                        const newFile = process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + file.fileName
                        newFiles.push(newFile)
                    })
                    this.setState({ ...this.state, selectedFiles : newFiles })
                }
            }
        }

        // Loading Collections Data
        // let collections = {}
        await Promise.all(
            propsForm.fields.map(async attrib => {
            //console.log(' Attr ' + attrib.name)
            if (attrib.type === 'ref') {
                // Fetch Collection Data
                const newCollection = await getData(attrib.collectionName)
                const newColl = { ...this.state.collections, [attrib.collectionName] : newCollection };
                this.setState({ collections: newColl }) // TODO: improve this
            }
            else if (attrib.type === 'refForm') {
                //console.log(' refForm ' + attrib.collectionName)
                // Fetch Collection Data
                const newCollection = await getFormData(attrib.collectionName)
                const newColl = { ...this.state.collections, [attrib.collectionName] : newCollection };
                this.setState({ collections: newColl }) // TODO: improve this
            }
        }))

        this.setState({ submitButtonText: this.state.isAdded ? 'засварлах' : 'хадгалах' })
        //console.log(' STATES ' + JSON.stringify(this.state.collections, getCircularReplacer()))
        this.setState({isLoading: false})
        //console.log(' after model :::: ' + JSON.stringify(this.state.formData))
    }

    render() {
        //try {
            const { isLoading, form } = this.state
            return (
                <>
                {
                    isLoading ?
                        <LoadIndicator visible={isLoading} />
                    :
                    <>
                        {form && this.renderTitle(form.form.name)}
                        
                        <div className="dx-fieldset">
                            {form && form.fields.map(attr => {
                                return this.renderComponent(attr)
                            })}
                            <div className="dx-field">
                                <div className="dx-field-value">
                                    <ValidationSummary id="summary"></ValidationSummary>
                                    <Button
                                        id="submit"
                                        text={this.state.submitButtonText}
                                        icon="save"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={this.onFormSubmit} />
                                    
                                    <Button
                                        id="back"
                                        text="Буцах"
                                        type="default"
                                        stylingMode="text"
                                        useSubmitBehavior={false}
                                        onClick={()=>this.props.history.goBack()} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                </>
                )
            // } catch(e) {
            //     return ErrorComponent(e)
            // }
    }

    onSelectedFilesChanged(e) {
        //this.setState({ selectedFiles: e.value.name });
    }

    async onFormSubmit(e) {
        let result = e.validationGroup.validate();
        if (result.isValid)
            try {
                const store = backendStore({ entityName: 'formdata' }); // TODO: Moving this action apiService
                let res = null
                if ( !this.state.isAdded )
                    res = await apiService.insertFormData(this.formId, { data: this.state.formData })
                else
                    res = await store.update(this.state._id, { data: this.state.formData })
                
                if (res.status.success) {
                    console.log('data : ' + JSON.stringify(res.items))
                    this.setState( { _id: res.items._id, submitButtonText: 'Засварлах', isAdded: true } )
                    
                    // Emmitting Event
                    if (typeof this.props.onFormSubmit === 'function')
                        this.props.onFormSubmit({ formId: this.formId, formDataId: res.items._id, formData: this.state.formData });

                    notify({
                        message: 'Мэдээллийг амжилттай хадгаллаа... ',
                        position: {
                        my: 'center middle',
                        at: 'center middle'
                        }
                    }, 'success', 3000);
                }
                else notify({
                    message: 'Уучлаарай, мэдээллийг хадгалахад алдаа гарлаа',
                    position: {
                        my: 'center middle',
                        at: 'center middle'
                    }
                }, 'error2', 3000)
            } catch (error) {
                ErrorComponent(error)
                notify('Error: ' + error, 'error', 1000)
            }
        //e.preventDefault();
    }

    async onFileUploaded(e) {
        //After file uploaded
        const { name, size } = e.file
        const result = await apiService.insert('asset', { fileName: name})
        console.log(' asset upload ' + JSON.stringify(result))
        if (result.items) {
            // Patch new Asset file into Entity
            const newAssetId = result.items._id
            console.log('asset post result : ' + newAssetId)
            let key = this.state._id
            let postData = { asset: newAssetId }

            //const result2 = await apiService.attach(this.props.modelName, key, postData )
            const result2 = await apiService.attach('formdata', key, postData )
            if (result2.items) {
                // adding Uploaded item to selectedFiles
                const newFiles = this.state.selectedFiles
                newFiles.push(process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + name)
                this.setState({ ...this.state, selectedFiles : newFiles })
                console.log(' selectedFiles : ' + JSON.stringify(this.state.selectedFiles) )

                notify({
                    message: 'Зурган файл амжилттай хуулагдлаа ',
                    position: {
                      my: 'center middle',
                      at: 'center middle'
                    }
                  }, 'success', 1000)
            }
            else notify('error')
            
        }
        else {
            notify({
                message: 'Уучлаарай, файлыг сервер рүү хуулахад алдаа гарлаа',
                position: {
                  my: 'center middle',
                  at: 'center middle'
                }
              }, 'error', 3000)
        }
    }

    renderTitle(title) {
        return (<h4>{title} {this.state.isAdded ? 'засах' : 'хадгалах'}</h4>)
    }

    renderComponent(attr) {
       if (attr.private) // not render Private Fields
            return ''

        switch(attr.editor) {
            case 'TextBox':
            default:
                return this.renderTextBox(attr);
            case 'TextArea': 
                return this.renderTextArea(attr)
            case 'TagBox': 
                return this.renderTagBox(attr)
            case 'CheckBox': 
                return this.renderCheckBox(attr)
            case 'RadioGroup':
                return this.renderRadioGroup(attr)
            case 'NumberBox': 
                return this.renderNumberBox(attr)
            case 'FileUpload': 
                return this.renderFileUpload(attr)
            case 'Lookup':
                return this.renderLookup(attr)
            case 'DateBox':
                return this.renderDateBox(attr)
            case 'SelectBox': 
                return this.renderSelectBox(attr)
            case 'HtmlEditor': 
                return this.renderHtmlEditor(attr)
        }
    }
    
    renderTextBox(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <TextBox defaultValue={this.getFieldValue(att)} 
                        showClearButton={true} 
                        stylingMode="filled" 
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг бөглөнө үү`} />
                            </Validator>
                            : ''
                        }
                    </TextBox>
                </div>
            </div>
        )
    }
    
    renderRadioGroup(att) {
        //console.log(' radio group ' + JSON.stringify(att))
        let values = []
        if (att.type === 'boolean')
            values = [true, false]

        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <RadioGroup 
                        defaultValue={this.getFieldValue(att)} 
                        items={values}
                        layout="horizontal"
                        onValueChanged={(e) => this.valueChanged(att.name, e)} >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </RadioGroup>
                </div>
            </div>
        )
    }

    renderTextArea(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <TextArea stylingMode="filled"
                        defaultValue={this.getFieldValue(att)}
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                    >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг бөглөнө үү`} />
                            </Validator>
                            : ''
                        }
                    </TextArea>
                </div>
            </div>
        )
    }

    renderCheckBox(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <CheckBox 
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        defaultValue={this.getFieldValue(att)}
                        text="Тийм">
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </CheckBox>
                </div>
            </div>
        )
    }

    renderNumberBox(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <NumberBox 
                        defaultValue={this.getFieldValue(att)}
                        format="#,##0.########" 
                    >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </NumberBox>
                </div>
            </div>
        )
    }

    renderLookup(att) {
        const { collections } = this.state

        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <Lookup
                        defaultValue={this.getFieldValue(att)} 
                        items={collections[att.collectionName]}
                        //dataSource={this.getLookupDataSource(att.collectionName)}
                        searchEnabled={true}
                        searchExpr={['name']}
                        //displayExpr="name"
                        displayExpr={getDisplayFieldValue(att.collectionName)}
                        itemRender={(item) => itemRender(att.collectionName, item)}
                        valueExpr="_id"
                        stylingMode="filled"
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                    >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </Lookup>
                </div>
            </div>
        )
    }
    
    renderDateBox(att) {
        const fieldVal = this.getFieldValue(att) || null
        const thisDatetime = new Date(fieldVal)

        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <DateBox
                        type="datetime"
                        displayFormat="yyyy-MMM-dd HH:mm"
                        showClearButton={true}
                        stylingMode="filled"
                        defaultValue={thisDatetime}
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </DateBox>
                </div>
            </div>
        )
    }

    renderSelectBox(att) {
        const { collections } = this.state
        //console.log('  selectbox value ' + JSON.stringify(collections[att.collectionName]))
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">{att.label}</div>
                <div className="dx-field-value">
                    <SelectBox 
                        defaultValue={this.getFieldCollectionValue(att)} 
                        items={collections[att.collectionName]}
                        //displayExpr={att.name}
                        displayExpr={getDisplayFieldValue(att.collectionName)}
                        itemRender={(item) => itemRender(att.collectionName, item)}
                        valueExpr='_id'
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                    />
                </div>
            </div>
        )
    }

    renderTagBox(attr) {
        const { collections } = this.state
        //console.log('  selectbox value ' + JSON.stringify(this.getFieldMultipleCollectionValue(attr)))
        if ( collections[attr.collectionName] )
            return (
                <div className="dx-field" key={attr.name}>
                    <div className="dx-field-label">{attr.label}</div>
                    <div className="dx-field-value">
                        <TagBox 
                            defaultValue={this.getFieldMultipleCollectionValue(attr)} 
                            items={collections[attr.collectionName]}
                            //displayExpr={attr.name}
                            displayExpr={getDisplayFieldValue(attr.collectionName)}
                            itemRender={(item) => itemRender(attr.collectionName, item)}
                            valueExpr='_id'
                            applyValueMode="useButtons"
                            stylingMode="filled"
                            hideSelectedItems={true}
                        />
                    </div>
                </div>
            )
        else return []
    }

    renderHtmlEditor(attr) {
        const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
        const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
        const headerValues = [false, 1, 2, 3, 4, 5];

        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">
                <HtmlEditor
                    height="500px"
                    width="500"
                    defaultValue={this.getFieldValue(attr)}
                    //onValueChanged={(e) => this.valueChanged(attr.name, e)}
                >
                <MediaResizing enabled={true} />
                <Toolbar multiline={true}>
                    <Item formatName="undo" />
                    <Item formatName="redo" />
                    <Item formatName="separator" />
                    <Item
                    formatName="size"
                    formatValues={sizeValues}
                    />
                    <Item
                    formatName="font"
                    formatValues={fontValues}
                    />
                    <Item formatName="separator" />
                    <Item formatName="bold" />
                    <Item formatName="italic" />
                    <Item formatName="strike" />
                    <Item formatName="underline" />
                    <Item formatName="separator" />
                    <Item formatName="alignLeft" />
                    <Item formatName="alignCenter" />
                    <Item formatName="alignRight" />
                    <Item formatName="alignJustify" />
                    <Item formatName="separator" />
                    <Item formatName="orderedList" />
                    <Item formatName="bulletList" />
                    <Item formatName="separator" />
                    <Item
                    formatName="header"
                    formatValues={headerValues}
                    />
                    <Item formatName="separator" />
                    <Item formatName="color" />
                    <Item formatName="background" />
                    <Item formatName="separator" />
                    <Item formatName="link" />
                    <Item formatName="image" />
                    <Item formatName="separator" />
                    <Item formatName="clear" />
                    <Item formatName="codeBlock" />
                    <Item formatName="blockquote" />
                    <Item formatName="separator" />
                    <Item formatName="insertTable" />
                    <Item formatName="deleteTable" />
                    <Item formatName="insertRowAbove" />
                    <Item formatName="insertRowBelow" />
                    <Item formatName="deleteRow" />
                    <Item formatName="insertColumnLeft" />
                    <Item formatName="insertColumnRight" />
                    <Item formatName="deleteColumn" />
                </Toolbar>
                    <Validator>
                        <RequiredRule message="html талбарыг бөглөнө үү" />
                    </Validator>
                </HtmlEditor>
                </div>
            </div>
        )
        return ''
    }

    renderFileUpload(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-label">Зураг болон видео оруулах / Upload:</div>
                <div className="dx-field-value">
                <FileUploader name="assetFile" multiple={true} accept={"image/*, video/*"} uploadMode={"instantly"}
                    uploadUrl={`${process.env.REACT_APP_BACKEND_API}/api/asset/upload`} 
                    onValueChanged={this.onSelectedFilesChanged}
                    onUploaded={this.onFileUploaded}
                    disabled={!this.state.isAdded}
                        />
                <div className="content" style={{ display: this.state.selectedFiles.length > 0 ? 'block' : 'none' }}>
                    <div>
                    <strong>Upload хийгдсэн файлууд</strong>
                        {
                        <Gallery // TODO: replace with rennerAssets function 
                            id="gallery"
                            items={this.state.selectedFiles}
                            height={200}
                            slideshowDelay={2000}
                            loop={false}
                            showNavButtons={true}
                            showIndicator={true} 
                        />
                        // this.state.selectedFiles.map((file, i) => {
                        // return <div className="selected-item" key={i}>
                        //     <span><li><img height={60} src={`${process.env.REACT_APP_BACKEND_API}/uploads/asset/${file}`} /></li></span>
                        //     <span>{`Size ${file.size}`}<br /></span>
                        //     <span>{`Type ${file.size}`}<br /></span>
                        //     <span>{`Last Modified Date: ${file.lastModifiedDate}`}</span>
                        // </div>;
                        // })
                    }
                    </div>
                </div>

                </div>
            </div>
        )
    }

    valueChanged(target, e) {
        //console.log(' target: ' + target + ' e ' + JSON.stringify(e, getCircularReplacer()))
        //const previousValue = e.previousValue;
        const newValue = e.value;
        // Updating state
        const formVal = { ...this.state.formData, [target] : newValue };
        this.setState( { formData: formVal })
        // Event handling commands go here
        console.log('updated field: ' + JSON.stringify(this.state.formData))
        
        // Updating current editor
        const modelVal = { ...this.state.modelData, [target] : newValue };
        this.setState( { modelData: modelVal })
    }

    getFieldValue(field) {
        const { modelData, isLoading } = this.state
        if (modelData)
            if ( isNotEmpty(modelData[field.name]) )
                return modelData[field.name]
    
        return ''
    }

    getFieldCollectionValue(field) {
        const { modelData, isLoading } = this.state
        //console.log(' coll val ' + JSON.stringify(modelData))

        if (modelData && !isLoading)
            if (isNotEmpty(modelData[field.name]) )
                return modelData[field.name]._id
        return '{Empty}'
        //return ''
    }

    getFieldMultipleCollectionValue(field) {
        const { modelData, isLoading } = this.state
        let IDs = []
        if (modelData && !isLoading)
            if (isNotEmpty(modelData[field.name]) )
                modelData[field.name].map((curr) => {
                    IDs.push(curr._id)
                })
            return IDs
        return '{Empty}'
        //return ''
    }
}

async function getData(collectionName) {
    let store = backendStore({ entityName: collectionName });

    try {
        let result = await store.load()
        //console.log(' items load ' + JSON.stringify(result.data))
        return result.data
    }
    catch { return [] }
}

async function getFormData(collectionName) {
    let collectionValues = []

    try {
        const result = await apiService.findSearchBy('form', 'name=' + collectionName)
        if (result.status.success && result.items.length) {
            const formId = result.items[0]._id
            const result2 = await apiService.findSearchBy('formdata', 'form=' + formId)

            if (result2.status.success && result2.items.length) {
                result2.items.map(itemFormData => {
                    const formDataVal = JSON.parse(itemFormData.data)
                    collectionValues.push({ _id: itemFormData._id, name: formDataVal.name})
                })
            }
        }
        //console.log(' items load ' + JSON.stringify(result.data))
        return collectionValues
    }
    catch { return [] }
}

export default withRouter(PageRenderer)