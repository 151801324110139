import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import QRCode from 'react-qr-code'
import Button from 'devextreme-react/button'
import { Tooltip } from 'devextreme-react/tooltip'
import { Popover } from 'devextreme-react/popover'
import notify from 'devextreme/ui/notify'
import { getUser } from '../../api/auth'
import apiService from '../../api/apiService'

import IconQR from '../../data/icons/qr-code-outline.svg'
import IconCopy from '../../data/icons/copy-outline.svg'

class App extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        coinId: null,
        user: {},
        isLoading: true,
        coinName: [],
        localItems: [],
        walletAddress: '',
        walletTag: '104100523',
        addressCopyShow: false,
        tagCopyShow: false,
        addressQRShow: false,
        tagQRShow: false,
        tagPopoverShow: false,
        currencyFormat: '#.##',
        currencyValue: 0,
      }
  
    }

    // data from Parent comp props
    static getDerivedStateFromProps(nextProps) {
        return { coinId: nextProps.coinId }
    }

    async componentDidMount() {
        this.setState({ coinId: this.props.coinId })
        await this.fetchData('XRP')
    }
    
    async fetchData(coinTicker) {
        const userData = await getUser()
        //console.log('loading user ' + JSON.stringify(userData.data))
        this.setState({ user: userData.data })
    
        const result = await apiService.findSearchBy('wallet', 'owner=' + userData.data._id + ',coin=' + this.state.coinId)
        if (result.status.success && result.items.length) {
          result.items.map(selected => {
            if (selected.coin.ticker === coinTicker)
              console.log('selected coin ' + JSON.stringify(selected.coin))
              this.setState({ walletAddress: selected.address.toString() })
          })
        }
    
        this.setState({ isLoading: false })
    }

    render() {
        const { walletAddress, walletTag, addressQRShow, tagQRShow } = this.state

        return (
            <div className="card">
            <div className="card-body">
                {/* <h5 className="card-title">Зээлийн тооцоолуур</h5> */}
                <div className="settings-profile">
                    
                    <p>Please enter both Tag and Address data, which are required to deposit BTC to your Binance account successfully.</p>
                    <table>
                    <tr>
                    <td>
                        <b>Address</b>
                        <h5>{walletAddress}</h5>
                    </td>
                    <td>
                        <CopyToClipboard 
                            text={walletAddress}
                            onCopy={() => showNotify('Хаяг хуулагдлаа')}
                            >
                            {/* <img 
                                id="addressCopy"
                                src={IconCopy}
                                height={40}
                                onMouseEnter={() => this.setState({ addressCopyShow: true })}
                                onMouseLeave={() => this.setState({ addressCopyShow: false })}
                            /> */}
                            <Button 
                                id={"addressCopy"}
                                icon={IconCopy}
                                text={'Хаяг хуулах'} 
                                stylingMode="outlined"
                                type="default"
                                onMouseEnter={() => this.setState({ addressCopyShow: true })}
                                onMouseLeave={() => this.setState({ addressCopyShow: false })}
                            />
                        </CopyToClipboard>

                        <Tooltip
                            target="#addressCopy"
                            visible={this.state.addressCopyShow}
                            closeOnOutsideClick={false}
                        >
                            <div>Хаягийг хуулж авах</div>
                        </Tooltip>
                    </td>
                    <td>
                        <img 
                            id={"addressQR"}
                            src={IconQR}
                            height={50}
                            // onMouseEnter={() => this.setState({ addressQRShow: true })}
                            // onMouseLeave={() => this.setState({ addressQRShow: false })} 
                        />

                        <Tooltip
                            target="#addressQR"
                            position="right"
                            visible={addressQRShow}
                            closeOnOutsideClick={false}
                        >
                            <QRCode style={{ position: 'absolute' }} value={walletAddress} size={150} />
                        </Tooltip>
                    </td>
                    <td rowSpan={2}>
                        <QRCode style={{ position: 'absolute' }} value={walletTag} size={150} />
                    </td>
                    </tr>
                    </table>
                    <p>
                        Send only BTC to this deposit address.<br/>
                        Sending coin or token other than BTC to this address may result in the loss of your deposit.
                    </p>
                </div>
            </div>
        </div>
        )
    }
}

function showNotify(notificationTxt) {
    notify({
        message: notificationTxt,
        position: {
        my: 'center middle',
        at: 'center middle'
        }
    }, 'info', 2000);
}

export default App