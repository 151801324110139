import React from 'react'
import ValidationSummary from 'devextreme-react/validation-summary'
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule
  } from 'devextreme-react/validator'
import { Lookup, DropDownOptions } from 'devextreme-react/lookup'
import RadioGroup from 'devextreme-react/radio-group'
import notify from 'devextreme/ui/notify'
// import { Form } from 'devextreme-react/form'
import DateBox from 'devextreme-react/date-box'
import Button from 'devextreme-react/button'
import TextBox from 'devextreme-react/text-box'
import TagBox from 'devextreme-react/tag-box'
import CheckBox from 'devextreme-react/check-box'
import SelectBox from 'devextreme-react/select-box'
import TextArea from 'devextreme-react/text-area'
import NumberBox from 'devextreme-react/number-box'
import Gallery from 'devextreme-react/gallery'
import '../deposit/index.scss';

export function renderTextBox(att) {
    return (
        <div className="dx-field" key={att.name}>
            <div className="dx-field-value">
                {att.label}
                <TextBox 
                    //defaultValue={this.getFieldValue(att)} 
                    showClearButton={true} 
                    stylingMode="filled" 
                    //onValueChanged={(e) => this.valueChanged(att.name, e)}
                    width={250}
                    >
                    {
                        att.required ?
                        <Validator>
                            <RequiredRule message={`${att.label} талбарыг бөглөнө үү`} />
                        </Validator>
                        : ''
                    }
                </TextBox>
            </div>
        </div>
    )
}

export function renderNumberBox(att) {
    return (
        <div className="dx-field" key={att.name}>
            <div className="dx-field-value">
            {att.label}
                <NumberBox 
                    //defaultValue={this.getFieldValue(att)} 
                    stylingMode="filled" 
                    width={250}
                    >
                    {
                        att.required ?
                        <Validator>
                            <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                        </Validator>
                        : ''
                    }
                </NumberBox>
            </div>
        </div>
    )
}

export function ItemRender({ name, ticker, logo }) {
    const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + logo
    return (
        <div className="custom-item">
          <img height={15} src={picUrl} />
          <div><b>{`${ticker}`}</b> {`${name }`}</div>
        </div>
    );
}
  
export function FieldRender(field) {
    if (field) {
      const { name, ticker, logo } = field
      const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + logo
      return (
          <div className="custom-field">
            <img height={20} src={picUrl} />
            <div><b>{`${ticker}`}</b> {`${name }`}</div>
          </div>
      )
    }
    else return ''
}

export function showNotify(notificationTxt, type = 'success') {
    return notify({
        message: notificationTxt,
        position: {
        my: 'top middle',
        at: 'top middle'
        }
    }, type, 2000);
}