import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

import { FilterList } from '@material-ui/icons';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { datetimeFormat } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const Trainings = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    const [isLoading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([]);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [sorted, setSorted] = useState(false);
    const [sortType, setSortType] = useState('asc');

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.find('wallet')
            setTrainings(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    const sortTrainings = (event) => {
        if(event.target.value === 'desc') {
            let trainingList = trainings;
            const sortedList = trainingList.map(obj => { return { ...obj, createdAt: new Date(obj.createdAt) } })
            .sort((a, b) => b.createdAt - a.createdAt)
            setSorted(true);
            setSortType(event.target.value);
            setTrainings(sortedList)
        } else {
            getData();
            setSortType(event.target.value);
            setSorted(false)
        }
    }

    console.log('trainings:: ', trainings)
    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Хэтэвчүүд'} content={false}>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ justifyContent: 'flex-start'}}>
                    <Button
                        onClick={() => history.push('/admin/wallet/add')}  
                        variant="contained" color="primary"
                        sx={{marginRight: '1rem'}}>
                        Шинэ Хэтэвч
                    </Button>
                    {/* <Button
                        onClick={() => history.push('/admin/trainingExamQuestion/list')}  
                        variant="contained" color="primary">
                        Шалгалтын асуулт
                    </Button>
                    <Button
                        onClick={() => history.push('/admin/trainingExamAnswer/list')}  
                        variant="contained" color="primary">
                        Шалгалтын хариулт
                    </Button> */}
                    <Button
                        onClick={() => history.push('/walletadmin/addToUser')}  
                        variant="contained" color="primary">
                        Хэтэвчүүд үүсгэх
                    </Button>
                </Box>
                <IconButton color="primary" aria-label="filter" component="label" onClick={() => setToggleFilter(!toggleFilter)}>
                    <FilterList/>
                </IconButton>
            </CardActions>
            {toggleFilter && <>
                <Divider />
                <Box sx={{display: 'flex', justifyContent: 'flex-start', padding: '1rem'}}>
                    <FormControl sx={{padding:0, minWidth: 200 }} size="small">
                        <InputLabel id="demo-select-small-label">Эрэмбэлэх</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={sortType}
                            label="Баталгаажуулаагүй"
                            onChange={sortTrainings}
                        >
                            <MenuItem value={'asc'}>Бүртгүүлсэн огноо өсөхөөр</MenuItem>
                            <MenuItem value={'desc'}>Бүртгүүлсэн огноо буурахаар</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
            </>}
            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>Эзэмшигч</TableCell>
                                <TableCell>Койн</TableCell>
                                <TableCell>Үлдэгдэл</TableCell>
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Төлөв
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainings && trainings.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ pl: 3 }}>{row.owner ? row.owner.username : 'N/A'}</TableCell>
                                    <TableCell>{row.coin.ticker}</TableCell>
                                    <TableCell>{row.balance}</TableCell>
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        <Chip
                                            onClick={() => history.push('/admin/wallet/edit/' + row._id)}
                                            variant="outlined"
                                            color={'default'}
                                            label={'засах'}
                                            size="small"
                                        />
                                    
                                        <Chip
                                            onClick={() => history.push('/admin/wallet/view/' + row._id)}
                                            variant="outlined" 
                                            color={'default'}
                                            label={'цааш'}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default Trainings;
