import React, { useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles } from '@material-ui/core';

import UploadService from "utils/fileUpload";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const UploadFiles = ({ onFileUploaded, uploadUrl }) => {
  
    const [selectedFiles, setSelectedFiles] = useState(undefined)
    const [currentFile, setCurrentFile] = useState(undefined)
    const [progress, setProgress] = useState(0)
    const [message, setMessage] = useState("")
    const [isError, setIsError] = useState(false)
    const [fileInfos, setFileInfos] = useState(null) //[]

    const selectFile = (event) => {
        setSelectedFiles(event.target.files)
        console.log('select', JSON.stringify(event.target.files))
    }

    const upload = () => {
        let currentFile = selectedFiles[0];

        setProgress(0)
        setCurrentFile(currentFile)

        UploadService.upload(currentFile, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total))
        }, uploadUrl)
        .then((response) => {
            setMessage(response.data.message)
            setIsError(false)
            //return UploadService.getFiles();
            return response.data
        })
        .then((files) => {
            console.log(' file ' + JSON.stringify(files.data))
            //let newFile = []
            //newFile = this.state.fileInfos
            // newFile.push(files.data)
            //   this.setState({
            //       fileInfos: newFile,
            //   });
            setFileInfos(files.data.name)
            //console.log(' fileInfos ' + JSON.stringify(this.state.fileInfos))
            //if (typeof onFileUploaded === 'function')
            onFileUploaded && onFileUploaded(files.data)
        })
        .catch(() => {
            setProgress(0)
            setMessage('Файлыг хуулахад алдаа гарлаа')
            setCurrentFile(undefined)
            setIsError(true)
        });

        // this.setState({
        //     selectedFiles: undefined,
        // });
    }
    
    return (
        <div className="mg20">
        {currentFile && (
            <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
            </Box>
            </Box>)
        }

        <label htmlFor="btn-upload">
            <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: 'none' }}
                type="file"
                inputProps={{ multiple: false }}
                onChange={e => selectFile(e)} />
            <Button
                className="btn-choose"
                variant="outlined"
                component="span" >
                Файл сонгох
            </Button>

            <Button
                className="btn-upload"
                color="primary"
                variant="contained"
                component="span"
                disabled={!selectedFiles}
                onClick={e => upload(e)}>
                Хуулах (Upload)
            </Button>
        </label>

        <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
            {message}
        </Typography>

        <Typography variant="h6" className="list-header">
            Хуулагдсан файл: <b>{fileInfos}</b>
        </Typography>
        </div >
    )
}

export default UploadFiles