import React from 'react';
import { withRouter } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Tooltip } from 'devextreme-react/tooltip';
import notify from 'devextreme/ui/notify';
import 'devextreme/ui/select_box';
import './toolbar.scss'

function renderLabel(title) {
  return <div className="toolbar-label">{title}</div>;
}

class ToolbarComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { modelName, title } = this.props || ''

    return (
        <Toolbar>
            <Item location="before"
                widget="dxButton"
                options={this.renderBackButton()} />
            <Item location="before"
                widget="dxButton"
                options={this.renderRefreshButton()} />
            <Item location="center"
                locateInMenu="never"
                render={() => renderLabel(title)} />
            <Item location="after"
                locateInMenu="auto"
                widget="dxButton"
                options={this.renderAddButton('department', 'people-sharp')} />
            <Item location="after"
                locateInMenu="auto"
                widget="dxButton"
                options={this.renderAddButton('user', 'person-add-sharp')} />
            <Item location="after"
                locateInMenu="auto"
                widget="dxButton"
                options={this.renderAddButton('project', 'bag-add-sharp')} />
        </Toolbar>
    );
  }

  renderAddButton(modelName, iconName) {
    const addButtonOptions = {
      icon: 'images/icons/' + iconName + '.svg',
      onClick: () => {
        this.changeRoute('/page/' +modelName + '/add')
      }
    }

    return addButtonOptions
  }

  renderBackButton() {
    const backButtonOptions = {
      type: 'back',
      onClick: () => this.props.history.goBack()
    };

    return backButtonOptions
  }

  renderRefreshButton() {
    const refreshButtonOptions = {
      icon: 'refresh',
      onClick: () => {
        notify('Refresh button has been clicked!');
      }
    };

    return refreshButtonOptions
  }

  changeRoute(url) {
    this.props.history.push(url)
  }
}

export default withRouter(ToolbarComponent)