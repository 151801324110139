import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMenu, IconBoxMultiple, IconCircleOff, IconCircle, IconBrandGravatar, IconShape } from '@tabler/icons';

// constant
const icons = {
    IconMenu: IconMenu,
    IconBoxMultiple: IconBoxMultiple,
    IconCircleOff: IconCircleOff,
    IconCircle: IconCircle,
    IconBrandGravatar: IconBrandGravatar,
    IconShape: IconShape
};

const menuItems = [
    'user',
]

let menuSubs = []
menuItems.map(item => {
    menuSubs.push({
        id: 'menu-level-1.1',
        title: item,
        type: 'item',
        url: '/admin/' + item + '/list'
    })
})

//-----------------------|| ADMIN MENU ITEMS ||-----------------------//

export const admin = {
    id: 'admin',
    title: 'Систем', //<FormattedMessage id="admin" />,
    type: 'group',
    children: [
        {
            id: 'menu-level',
            title: 'тохиргоо',
            type: 'collapse',
            icon: icons['IconMenu'],
            children: menuSubs
        }
    ]
};
