import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom'
//import List from 'devextreme-react/list';
//import Button from 'devextreme-react/button';
import DataGrid, { Column, Paging, Pager, Editing, Lookup, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
//import './home.scss';
//import ToolbarListPage from '../../components/toolbar/toolbarListPage'
import { Row, Col, Nav } from 'react-bootstrap'
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
//import backendStore from '../../api/backendStore'
import { getUser } from '../../api/auth'

import IconDeposit from '../../data/icons/arrow-down-outline.svg'
import IconWithdraw from '../../data/icons/arrow-up-outline.svg'
import IconLoan from '../../data/icons/cash-outline.svg'
import IconExchange from '../../data/icons/repeat-outline.svg'

import { Grid, Box, Tab, Tabs, Typography } from '@material-ui/core';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

import { EyeOff as IconEyeOff, Eye as IconEye } from 'react-ionicons'
import Reports from './Reports';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accountTab: {
      marginBottom: '24px',
      '& button': {
          minWidth: '100px'
      },
      '& a': {
          minHeight: 'auto',
          minWidth: '10px',
          padding: '12px 8px',
          marginRight: '18px',
          color: theme.palette.grey[600]
      },
      '& a.Mui-selected': {
          color: theme.palette.primary.main
      }
  }
}));

// tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
          {value === index && <Box p={0}>{children}</Box>}
      </div>
  );
}

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
  };
}

const App = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return (
    <MainCard title="Тайлангууд">
      <div className={classes.root}>
        <Tabs
          value={value}
          indicatorColor="primary"
          onChange={handleChange}
          className={classes.accountTab}
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab component={RouterLink} to="#" label="Захиалгууд: Сар" {...a11yProps(0)} />
          <Tab component={RouterLink} to="#" label="Захиалгууд: Хэрэглэгчид" {...a11yProps(1)} />
          <Tab component={RouterLink} to="#" label="Цэнэглэлт" {...a11yProps(2)} />
          <Tab component={RouterLink} to="#" label="Банкны данс" {...a11yProps(3)} />
        </Tabs>
        
        <TabPanel value={value} index={0}>
          <Reports question={3} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Reports question={1} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Reports question={5} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Reports question={4} />
        </TabPanel>
      </div>
    </MainCard>
  )
}

export default withRouter(App)