import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import axios from 'utils/axios';
import {
    SET_STEP
} from 'store/actions';
import { gridSpacing } from 'store/constant';

// assets
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';

import { Link, withRouter } from 'react-router-dom'

import apiService from 'api/apiService'
import { getUser } from 'api/auth'
import Profile from 'views/application/users/account-profile/Profile/Profile';
import Confirmation from 'views/application/users/account-profile/Profile/Confirmation';
import { EyeOff as IconEyeOff, Eye as IconEye } from 'react-ionicons'

const StyledTab = styled((props) => <Tab {...props} />)(({ theme, customization, value }) => ({
  color: theme.palette.success.dark,
  minHeight: 'auto',
  minWidth: '250px',
  padding: 16,
  borderRadius: `${customization.borderRadius}px`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  textAlign: 'left',
  justifyContent: 'flex-start',
  '&.Mui-selected': {
      color: theme.palette.primary.main,
      background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
  },
  '& > svg': {
      marginBottom: '0px !important',
      marginRight: 10,
      marginTop: 2,
      height: '20px',
      width: '20px'
  },
  [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
  }
}));

// tabs option
const tabsOption = [
  {
      label: 'Хувийн мэдээлэл',
      icon: <ShoppingCartTwoToneIcon />,
      caption: 'нэвтрэх мэдээлэл, профайл'
  },
  {
      label: 'Баталгаажуулалт',
      icon: <ApartmentIcon />,
      caption: 'орлого, зарлага хийх эрх үүснэ'
  },
  // {
  //     label: 'Банкны данс холбох',
  //     icon: <CreditCardTwoToneIcon />,
  //     caption: 'орлого, зарлага хийх дансууд'
  // }
];

// tabs
function TabPanel({ children, value, index, ...other }) {
  return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
          {value === index && <div>{children}</div>}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  accountTab: {
      marginBottom: '24px',
      '& button': {
          minWidth: '100px'
      },
      '& a': {
          minHeight: 'auto',
          minWidth: '10px',
          padding: '12px 8px',
          marginRight: '18px',
          color: theme.palette.grey[600]
      },
      '& a.Mui-selected': {
          color: theme.palette.primary.main
      }
  }
}));

const ConfirmPage = () => {
  const classes = useStyles();
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(3);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch({ type: SET_STEP, step: newValue });
  };

  return (
    <MainCard>
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="icon label tabs example"
                    variant="scrollable"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            borderBottom: 'none'
                        },
                        '& .MuiTabs-indicator': {
                            display: 'none'
                        },
                        '& .MuiButtonBase-root + .MuiButtonBase-root': {
                            position: 'relative',
                            overflow: 'visible',
                            ml: '16px',
                            '&:after': {
                                content: '""',
                                bgcolor: '#ccc',
                                width: '1px',
                                height: 'calc(100% - 16px)',
                                position: 'absolute',
                                top: '8px',
                                left: '-8px'
                            }
                        }
                    }}
                >
                    {
                      tabsOption.map((tab, index) => (
                        <StyledTab
                            customization={customization}
                            value={index}
                            key={index}
                            icon={tab.icon}
                            label={
                                <Grid container direction="column">
                                    <Typography variant="subtitle1" color="inherit">
                                        {tab.label}
                                    </Typography>
                                    <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                        {tab.caption}
                                    </Typography>
                                </Grid>
                            }
                        />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.root}>
                <TabPanel value={value} index={0}>
                  <Profile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Confirmation />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                  <CartEmpty />
                </TabPanel> */}
              </div>
            </Grid>
        </Grid>
    </MainCard>
  );
}

export default withRouter(ConfirmPage)