import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'
import { datetimeFormat } from 'utils/helperFunction'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    },
    ScrollHeight: {
        height: '1000px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const Users = ({ isAdmin }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    let [isLoading, setLoading] = useState(true)
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.findSearchBy('user', 'idValidate=pending')
            setUsers(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Хэрэглэгчид'} content={false}>
            <PerfectScrollbar className={classes.ScrollHeight}>
                <CardContent className={classes.projectTableCard}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell>Овог нэр</TableCell>                                    
                                    <TableCell>Имэйл / Утас / ID</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Бүртгүүлсэн огноо</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Төлөв
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users && users.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/avatar/' +
                                        (row.avatarUrl ? row.avatarUrl.fileName : 'defaultAvatar.png')

                                    return <TableRow hover key={index}>
                                        <TableCell>{index}. <Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.lastname} {row.firstname}
                                        </TableCell>
                                        <TableCell>
                                            {row.email}<br/>
                                            {row.phoneNumber}<br/>
                                            {row.registerNumber}<br/>
                                            phone: {row.phoneValidate === 'validated' ? 'Ok' : ''}<br/>
                                            email: {row.emailValidate === 'validated' ? 'Ok' : ''}<br/>
                                            2FA: {row.otp ? 'Ok' : ''}<br/>
                                            ID: {row.idValidate === 'validated' ? 'Ok' : ''}
                                        </TableCell>
                                        <TableCell>{row.doc1 ? 'uploaded' : ''}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <><Chip 
                                                onClick={() => history.push('/admin/user/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />
                                            <Chip 
                                                onClick={() => history.push('/useradmin/userview/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'харах'} 
                                                size="small"
                                            /></>}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </PerfectScrollbar>
        </MainCard>
    );
};

Users.propTypes = {
    isAdmin: PropTypes.bool
};

export default Users;
