import { getBearerToken } from "api/apiService";
import http from "./http-common";

class UploadFilesService {
  upload(file, onUploadProgress, url = "/apiv2/asset/upload") {
    let formData = new FormData();

    formData.append("assetFile", file);

    const token = getBearerToken();

    return http.post(process.env.REACT_APP_BACKEND_API + url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + token
      },
      onUploadProgress,
    });
  }

  // getFiles() {
  //   return http.get("/asset");
  // }
}

export default new UploadFilesService();
