import React, { useState } from 'react';
import './profile.scss';
import { Form, SimpleItem, TabbedItem, Tab, ButtonItem, ButtonOptions, Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  CustomRule,
  StringLengthRule,
  AsyncRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import 'devextreme-react/autocomplete';

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Нууц үг', mode: 'password' };

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      user: null
    }

    this.formFieldDataChanged = this.formFieldDataChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this)
  }

  render() {

    return (
      <>
        
          <Form
            id={'formchangepass'}
            formData={this.state.user}
            onFieldDataChanged={this.formFieldDataChanged}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            showValidationSummary={true}
            validationGroup="formChangePassword"
          >
                  <SimpleItem dataField="password" editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                    <Label location="top" alignment="left" text="Одоо ашиглаж буй нууц үг" />
                    <RequiredRule message="Одоо ашиглаж буй нууц үгийг оруулна уу" />
                  </SimpleItem>
                  <SimpleItem dataField="newPassword" editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                    <Label location="top" alignment="left" text="Шинэ нууц үг" />
                    <RequiredRule message="Шинэ нууц үгийг оруулна уу" />
                  </SimpleItem>
                  <SimpleItem dataField="newPasswordRepeat" editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                    <Label location="top" alignment="left" text="Шинэ нууц үгийг давтах" />
                    <RequiredRule message="Шинэ нууц үгийг давтан оруулна уу" />
                    <CustomRule
                      message={'Шинэ нууц үг, давтан оруулсан үг зөрж байна. Шалгана уу'}
                      validationCallback={this.confirmPassword}
                    />
                  </SimpleItem>

                  <ButtonItem>
                  <ButtonOptions
                      id="changePasswords"
                      text="Нууц үг шинэчлэх"
                      icon="save"
                      type="default"
                      useSubmitBehavior={true}
                      onClick={this.handleSubmit}
                  />
                </ButtonItem>
            
          </Form>
      </>
    )
  }

  formFieldDataChanged(e) {
    const updatedField = e.dataField;
    const newValue = e.value;
    // Event handling commands go here
    //this.profile = e.component.option("formData");
    
    const newFormData = { ...this.state.user, [updatedField]: newValue }
    this.setState({ user: newFormData })

    //console.log(' state NewPass ' + newValue + ' : ' + JSON.stringify(this.state))
  }

  handleSubmit(e) {
    let result = e.validationGroup.validate();
    if (result.isValid) {
        notify({
            message: 'You have submitted the form',
            position: {
              my: 'center top',
              at: 'center top'
            }
          }, 'success', 3000);
    }
    //e.preventDefault();
  }

  confirmPassword(field) {
    const { value } = field
    //console.log(' state ' + JSON.stringify(value))
    return (value === this.state.user.newPassword)
  }
}

const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1
  };

export default App