import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule
  } from 'devextreme-react/validator'
import { Lookup, DropDownOptions } from 'devextreme-react/lookup'
import Button from 'devextreme-react/button'
import { Tooltip } from 'devextreme-react/tooltip'
import { Popover } from 'devextreme-react/popover'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import ValidationSummary from 'devextreme-react/validation-summary'
import { renderTextBox, renderNumberBox, ItemRender, FieldRender } from '../withdraw/render'
import { getUser } from '../../api/auth'
import apiService from '../../api/apiService'
import './index.scss';

const loanTermList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

class App extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        isLoading: true,
        coinTicker: [],
        coinList: [],
        selectedCoin: {},
        user: null
      }

    //   this.valueChanged = this.valueChanged.bind(this)
    //   this.renderDeposit = this.renderDeposit.bind(this)
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.coin !== this.props.match.params.coin) {
          this.setState({ isLoading: true })
          const { coin } = this.props.match.params
          await this.resetData()
          await this.fetchData(coin)
        }
      }
    
      async componentDidMount() {
        const { coin } = this.props.match.params
        await this.fetchData(coin)
      }
    
      async resetData() {
        this.setState({ coinTicker: null })
      }
    
      async fetchData(coinTicker) {
        const userData = await getUser()
        //console.log('loading user ' + JSON.stringify(userData.data))
        this.setState({ user: userData.data })
    
        //const result = await apiService.findSearchBy('wallet', 'owner=' + userData.data._id + '&coin=' + coinTicker)
        const result = await apiService.find('coin')
        if (result.status.success && result.items.length) {
          this.setState({ coinList: result.items })
          // 
          result.items.map(selected => {
            if (selected.ticker === coinTicker)
              //console.log('selected coin ' + JSON.stringify(selected))
              this.setState({ selectedCoin: selected })
          })
        }
    
        this.setState({ isLoading: false, coinTicker: coinTicker })
      }

    render() {
        const { isLoading, coinTicker, coinList, selectedCoin } = this.state
    
        return ( 
            isLoading ? 
                <LoadIndicator visible={isLoading} />
                :
            <div className="settings mtb15">
            <div className="container-fluid">

                <div className="title-field">
                    <div className="inactive">
                        <Link onClick={() => this.props.history.push('/wallet')} >
                            <h2 className={'content-block'}>Үлдэгдэл</h2>
                        </Link>
                    </div>
                    <div className="inactive">
                        <Link onClick={() => this.props.history.push('/deposit/coin/' + coinTicker)} >
                            <h2 className={'content-block'}>Орлого</h2>
                        </Link>
                    </div>
                    <div className="inactive">
                        <Link onClick={() => this.props.history.push('/withdraw/coin/' + coinTicker)} >
                        <h2 className={'content-block'}>Зарлага</h2>
                        </Link>
                    </div>
                    <div><h2 className={'content-block'}>Зээл</h2></div>
                </div>


                <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Зээлийн тооцоолуур</h5>
                    <div className="settings-profile">

                <div className="loanDescription">HODL койнуудаа зарах шаардлагагүй, түр барьцаалаад шуурхай зээл авах боломжтой</div>
                
                <div className="dx-fieldset">

                    {renderNumberBox({ name: 'amount', label: 'Зээлийн дүн /MNT/', required: true })}

                    <div className="dx-field" key={'coin'}>
                        <div className="dx-field-value">
                            Барьцаалах койн:
                            <Lookup
                                defaultValue={selectedCoin._id} 
                                items={coinList}
                                //dataSource={coinList}
                                searchEnabled={true}
                                searchExpr={['name']}
                                displayExpr="name"
                                itemRender={ItemRender}
                                fieldRender={FieldRender}
                                valueExpr="_id"
                                stylingMode="filled"
                                onValueChanged={this.valueChanged}
                            >
                            <Validator>
                                <RequiredRule message={`Барьцаалах койноо сонгоно уу`} />
                            </Validator>
                            </Lookup>
                        </div>
                    </div>

                    {renderNumberBox({ name: 'collateral', label: 'Барьцааны дүн', required: true })}

                    <div className="dx-field" key={'loanTerm'}>
                        <div className="dx-field-value">
                            Зээл авах хугацаа /сар/:
                            <Lookup
                                defaultValue={'12'} 
                                items={loanTermList}
                                //dataSource={coinList}
                                //searchEnabled={true}
                                //searchExpr={['name']}
                                //displayExpr="name"
                                //itemRender={ItemRender}
                                //fieldRender={FieldRender}
                                //valueExpr="_id"
                                stylingMode="filled"
                                onValueChanged={this.valueChanged}
                            >
                            <Validator>
                                <RequiredRule message={`Зээл авах хугацаагаа сонгоно уу`} />
                            </Validator>
                            </Lookup>
                        </div>
                    </div>

                    <div className="dx-field" key={'loanTerm'}>
                        <div className="dx-field-value">
                            <p>LTV: <b>60%</b></p>
                            <p>Liquidation LTV: <b>95%</b></p>
                        </div>
                    </div>
                
                    <div className="dx-field" key={'loanTerm'}>
                        <div className="dx-field-value">
                
                            <ValidationSummary id="summary"></ValidationSummary>
                            <Button
                                id="submit"
                                text={'Зээл авах'}
                                icon="save"
                                type="default"
                                useSubmitBehavior={true}
                                stylingMode="contained"
                                onClick={this.onFormSubmit} />
                        </div>
                    </div>

                </div>

                <div className="card">
                <div className="card-body">
                    <div className="settings-profile">
                        <div className="loanDetail">
                            Буцаан төлөх нийт дүн:
                            <div className="label">554,000.00 ₮</div>

                            Хүүгийн төлбөр:
                            <div className="label">54,000.00 ₮</div>

                            Сарын хүү:
                            <div className="label">2.5%</div>

                            <p>Үйлчилгээний шимтгэл: <b>10,000 ₮</b></p>
                            <p>Зээл дуусах хугацаа: <b>2022-04-20</b></p>
                        </div>
                    </div>
                </div>
                </div>

                </div>
                </div>
                </div>
            
            </div>
            </div>
        )
    }
}

export default withRouter(App)
