import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from 'devextreme-react/button'
import DataGrid, { Column, Paging, Pager, Editing, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
import './listAccount.scss';
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
import { getUser } from '../../api/auth'
import AccountAdd from './add'
import IconRemove from '../../data/icons/remove-circle-outline.svg'

class App extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { accountList } = this.props

        return (
            <>

                <DataGrid id="gridContainer"
                    dataSource={accountList}
                    keyExpr="_id"
                    showBorders={true}
                    rowRender={rowRender}
                >
                    <FilterRow visible={false} />
                    <HeaderFilter visible={true} />
                    <Column dataField="coin.ticker"
                        caption="Валют"
                        width={100}
                        //cellRender={cellRender}
                    />
                    <Column dataField="coin"
                        caption={" "}
                        width={50}
                        allowFiltering={false} allowSorting={false}
                    />
                    <Column dataField="bank.name"
                        caption={"Банк"}
                        width={150}
                    />
                    <Column dataField="address"
                        caption={"Данс"}
                        width={150}
                        allowFiltering={false} allowSorting={false}
                    />
                    <Column dataField="address"
                        caption={" "}
                        allowFiltering={false} allowSorting={false}
                    />
                </DataGrid>
            </>
        )
    }
}

function rowRender(rowInfo) {
    const { coin, bank, address, _id, isConfirmed } = rowInfo.data
    const picUrl = process.env.REACT_APP_BACKEND_URL + '/images/' + bank.swift + '.svg'

    return (
    <tbody className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}>
        <tr className="main-row">
            <td>
                <b>{coin.ticker}</b><br/>
                {coin.name}
            </td>
            <td>
                <img src={picUrl} />
            </td>
            <td>
                {bank.name}
            </td>
            <td>
                <b>{address}</b>
            </td>
            <td>
                <div className={isConfirmed ? 'verified' : 'unverified'}>
                    {isConfirmed ? 'Баталгаажсан' : 'Баталгаажаагүй'}
                </div>
            </td>
            <td>
                <table>
                    <tr>
                        <td>{renderLink(IconRemove, 'Устгах', '/account/remove/' + _id)}</td>
                    </tr>
                </table>
            </td>
        </tr>
    </tbody>
    )
}

function renderLink(icon, label, route) {
    return (
        <div className="custom-field">
            <img src={icon} />
            <div><Link to={route}>{label}</Link></div>
        </div>
    )
}

export default withRouter(App)