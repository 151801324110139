import backendStore from './backendStore'
import Cookies from '../utils/Cookies'
import { getUser } from './auth';

const CONFIG = {
    url: process.env.REACT_APP_BACKEND_API,
    appName: 'puzzle',
    clientId: 'puzzle:default',
    clientSecret: '5gz9t2a82kdgw839ifdhuhgyf7e1rqn9qyr5u2pvjisx',
};

export function getBearerToken() {
    //const user = JSON.parse(localStorage.getItem('user'))
    const user = JSON.parse(Cookies.getCookie('user'))
    return user.token;
}

function setBearerToken(token) {
    localStorage.setItem('token', token);
    Cookies.setCookie('token', token)
}

function clearBearerToken() {
    localStorage.removeItem('token');
    Cookies.deleteCookie('token')
}

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default class apiService {

    static async findOne(entity, id) {
        return await getContent(`apiv2/${entity}/${id}`); 
    }
    
    static async find(entity, filter) {
        return await getContent(`apiv2/${entity}?${filter}`);
    }

    static async findSearchBy(entity, searchBy) {
        return await getContent(`apiv2/${entity}?searchBy=${searchBy}`);
    }

    static async findOneWithPath(entity, path, entityId) {
        //const companyId = await getCompanyId()
        return await getContent(`apiv2/${entity}/${path}/${entityId}`);
    }

    static async update(entity, Id, postData) {
        const store = backendStore({entityName: entity})
        const result = store.update(Id, postData)
        return result
    }

    static async post(entity, postData, path = '') {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entity}${path}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async patch(entity, Id, postData, path = '') {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entity}${path}/${encodeURIComponent(Id)}/patch`, {
            method: 'PATCH',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async patch1 (entity, operation, id, postData){
        const token = getBearerToken()
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entity}/${operation}/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then( (response) => response.json())
        .then(res => {
            return res
        })
        .catch(error => {
            return { error }
        })

        return result;
    }

    static async insert(entity, postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entity}/create`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async attach(entity, key, postData) {
        const store = backendStore({entityName: entity})
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entity}/${encodeURIComponent(key)}/attach`, {
            method: 'PATCH',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async insertFormData(formId, postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api/formdata/${formId}/create`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async delete(entityName, entityId, path = 'delete', postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entityName}/${entityId}/${path}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async remove(entityName, entityId) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv2/${entityName}/${entityId}/delete`, {
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }
}

export async function getUserData(id) {
    const json = await getContent(`api/user/${id}`);

    return parseUser(json);
}

async function getCompanyId() {
    // getting userId
    const userData = await getUser()
    if (userData.data) {
        const { _id, company } = userData.data
        //console.log(' getUser' + JSON.stringify(company._id))
        return company._id ? company._id : null
    }
    else 
        return null
}

function parseUser(response) {
    return {
        _id: response._id,
        username: response.username,
        lastName: response.lastName,
        firstName: response.firstName,
        department: response.department,
        email: response.email,
        created: response.createdAt,
    };
}

export async function fetchBearerToken() {
    // Check if we have already a bearer token in local store.
    let token = getBearerToken();

    if (token) {
        return token;
    }

    const body = `grant_type=client_credentials&scope=squidex-api&client_id=${CONFIG.clientId}&client_secret=${CONFIG.clientSecret}`;

    // Get the bearer token. Ensure that we use a client id with readonly permissions.
    const response = await fetch(buildUrl('connect/token'), { 
        method: 'POST', 
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body 
    });

    if (!response.ok) {
        throw new Error(`Failed to retrieve token, got ${response.statusText}`);
    }

    const json = await response.json();

    token = json.access_token;

    // Cache the bearer token in the local store.
    setBearerToken(token);

    return token;
}

function getContent(url) {
    return getContentInternal(url, true);
}

async function getContentInternal(url, retry) {
    // Fetch the bearer token.
    //const token = await fetchBearerToken(); // TODO: Token
    const token = getBearerToken()
    //console.log(' api token', token)

    const response = await fetch(buildUrl(url), {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    if (!response.ok) {
        if (response.status === 403 || response.status === 401) {
            // If we get an error we clear the bearer token and retry the request.
            clearBearerToken();

            if (retry) {
                return getContentInternal(url);
            }
        }

        throw new Error(`Failed to retrieve content, got ${response.statusText}`);
    }

    return await response.json();
}

function buildUrl(url) {
    if (url.length > 0 && url.startsWith('/')) {
        url = url.substr(1);
    }

    const result = `${CONFIG.url}/${url}`;

    return result;
}