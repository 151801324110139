import { 
  HomePage, ProfilePage, UserViewPage, 
  WalletHomePage, DepositPage, WithdrawPage, 
  LoanPage, BuyCoinPage, 
  TradePage, 
  AccountPage, 
  AdminViewPage, AdminListPage, AdminCrudPage, 

  ViewPage, ListPage, CrudPage,
  Markets, Profile, Wallet, Settings, Login, Logout, Signup, Reset, OtpVerify, OtpNumber, 
  Lock, TermsAndConditions, NewsDetails, Notfound, Exchange
} from './pages';

import AccountProfilePage from 'views/application/users/account-profile/Profile'
import HomeConfirmPage from 'pages/home/confirm'
import AdminWalletPage from 'views/application/wallet'
import AddWalletsToUserPage from 'views/application/wallet/AddWalletsToUser'
import AddFiatWalletsToUserPage from 'views/application/wallet/AddFiatWalletsToUser'

import AdminUserPage from 'views/application/user'
import AdminUserViewPage from 'views/application/user/UserView'

import AdminFinancePage from 'views/application/finance'
import AdminIEOPage from 'views/application/ieo'
import AdminIEOAlbumPage from 'views/application/ieo/Album'
import AdminIEOAlbumPanoPage from 'views/application/ieo/AlbumPano'
import AdminIEOAlbumVideoPage from 'views/application/ieo/AlbumVideo'
import AdminOrderPage from 'views/application/trade'
import OrderedIEODetails from 'views/application/ieo/OrderedIEODetails';
import IEOGraph from 'views/application/ieo/IEOGraph';
import IEOGraphAdd from 'views/application/ieo/IEOGraphAdd';
import IEOGraphEdit from 'views/application/ieo/IEOGraphEdit';
import ResetPassword from 'views/application/user/resetPassword';
import VersionList from 'views/application/versions/VersionList';
import CompanyList from 'views/application/company/CompanyList';

import AirDropPage from 'views/application/airdrop'
import AddAirDropToUserPage from 'views/application/airdrop/AddToUser'

import AdminReferralPage from 'views/application/referral'

const routes = [

  { path: '/profile', component: AccountProfilePage },
  { path: '/confirm', component: HomeConfirmPage },

  { path: '/myprofile', component: ProfilePage },
  { path: '/home', component: HomePage },
  { path: '/accounts', component: AccountPage },
  { path: '/user/:userId', component: UserViewPage },
  { path: '/user/resetpassword/:userId', component: ResetPassword },

  // Admin Pages
  { path: '/admin/:modelName/view/:Id', component: AdminViewPage },
  { path: '/admin/:modelName/list', component: AdminListPage },
  { path: '/admin/:modelName/edit/:Id', component: AdminCrudPage },
  { path: '/admin/:modelName/add', component: AdminCrudPage },

  { path: '/walletadmin', component: AdminWalletPage },
  { path: '/walletadmin/addToUser', component: AddWalletsToUserPage },
  { path: '/fiatwalletadmin/addToUser', component: AddFiatWalletsToUserPage },
  
  { path: '/useradmin', component: AdminUserPage },
  { path: '/useradmin/userview/:userId', component: AdminUserViewPage },

  { path: '/financeadmin', component: AdminFinancePage },

  { path: '/referraladmin', component: AdminReferralPage },

  { path: '/ieoadmin', component: AdminIEOPage },
  { path: '/ieo/pictures/:ieoId', component: AdminIEOAlbumPage },
  { path: '/ieo/panoPictures/:ieoId', component: AdminIEOAlbumPanoPage },
  { path: '/ieo/videos/:ieoId', component: AdminIEOAlbumVideoPage },
  { path: '/ieo/orders/:ieoId', component: OrderedIEODetails },
  { path: '/ieo/graph/:ieoId', component: IEOGraph },
  { path: '/ieo/graph/add/:ieoId', component: IEOGraphAdd },
  { path: '/ieo/graph/edit/:id', component: IEOGraphEdit },
  { path: '/orderadmin', component: AdminOrderPage },

  { path: '/airdrop', component: AirDropPage },
  { path: '/airdrop/addToUser', component: AddAirDropToUserPage },

  { path: '/page/:formId/view/:formDataId', component: ViewPage },
  { path: '/page/:formId/list', component: ListPage },
  { path: '/page/:formId/edit/:formDataId', component: CrudPage },
  { path: '/page/:formId/add', component: CrudPage },

  { path: '/trade/:pair', component: TradePage },
  { path: '/loan/:coin', component: LoanPage },
  { path: '/wallet', component: WalletHomePage },
  { path: '/deposit/:type/:coin', component: DepositPage },
  { path: '/withdraw/:type/:coin', component: WithdrawPage },
  { path: '/buyCoin/:coin', component: BuyCoinPage },

  { path: '/exchange', component: Exchange },
  { path: '/markets', component: Markets },
  //{ path: '/profile', component: Profile },
  { path: '/wallets', component: Wallet },
  { path: '/settings', component: Settings },
  { path: '/signin', component: Login },
  { path: '/logout', component: Logout },
  { path: '/signup', component: Signup },
  { path: '/reset', component: Reset },
  { path: '/otp-verify', component: OtpVerify },
  { path: '/otp-number', component: OtpNumber },
  { path: '/lock', component: Lock },
  { path: '/terms-and-conditions', component: TermsAndConditions },
  { path: '/news-details', component: NewsDetails },
  { path: '/notfound', component: Notfound },
  { path: '/versions', component: VersionList },
  { path: '/companies', component: CompanyList }
];

export default routes.map(route => {
  return {
    ...route,
    component: route.component
  };
});
