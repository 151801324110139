import dateFormat from 'dateformat'
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

export function datetimeFormat(datetime) {
    return dateFormat(datetime, "yyyy-mm-dd HH:MM" )
}

export function formatCurrency(curr) {
    return (curr).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function sortBalance(a, b){
    var balanceA = parseFloat(a.balance) + parseFloat(a.balanceInOrder);
    var balanceB = parseFloat(b.balance) + parseFloat(b.balanceInOrder);
    return balanceA < balanceB ? 1 : -1; // TODO: sort order
}

export function numberWithCommas(x) {
    return x ? x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}

export function compareDate( a, b ) {
    if ( new Date(a.createdAt) < new Date(b.createdAt)){
        return -1;
    }
    if ( new Date(a.createdAt) > new Date(b.createdAt)){
        return 1;
    }
    return 0;
}

export function dayjsFormat(datetime) {
    dayjs.extend(utc)
    return dayjs.utc(datetime).format('YYYY-MM-DD HH:mm')
}

export function getPictureUploadUrl() {
    return process.env.REACT_APP_API_URL + '/uploads/asset/'
}