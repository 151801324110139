import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonLoading } from '@ionic/react'
import MainCard from 'ui-component/cards/MainCard'
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core'

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar'

import MyToast from 'components/toast'
import apiService from 'api/apiService'
import { checkRole } from 'api/auth'
import { useAuth } from 'contexts/auth'
import { datetimeFormat, formatCurrency } from 'utils/helperFunction'
import MyModal from 'components/modal'
import AddFundWallet from './addFundWallet'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    },
    ScrollHeight: {
        height: '1200px'
    },
    green: {
        color: "green"
    },
    red: {
        color: "red"
    },
    blue: {
        color: "blue"
    },
    orange: {
        color: "orange"
    },
}));

const UserView = ({ match }) => {
    const { userId } = match.params
    const classes = useStyles()
    const history = useHistory()
    const { user } = useAuth()

    const [isAdmin, setAdmin] = React.useState(false)
    const [walletUser, setWalletUser] = useState({})
    const [wallets, setWallets] = useState([])
    const [fiatWallets, setFiatWallets] = useState([])
    const [bankAccounts, setBankAccounts] = useState([])
    const [userTrades, setUserTrades] = useState([])
    const [userOrders, setUserOrders] = useState([])
    const [userDepositTxs, setUserDepositTxs] = useState([])
    const [userFiatDepositTxs, setUserFiatDepositTxs] = useState([])
    const [userWithdrawTxs, setUserWithdrawTxs] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [showToast, setShowToast] = useState('')
    const [showAddFundWallet, setShowAddFundWallet] = React.useState('')

    const kycUrl = process.env.REACT_APP_API_URL + '/uploads/asset/kyc/'

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const tmp = await checkRole(user, 'ADMIN')
            tmp !== {} && tmp ? setAdmin(true) : setAdmin(false)

            if (userId) {
                let res = await apiService.findOne('user', userId)
                //console.log('user', JSON.stringify(res.data))
                setWalletUser(res.data)

                res = await apiService.findSearchBy('fiatWallet', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setFiatWallets(res.items)

                res = await apiService.findSearchBy('wallet', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setWallets(res.items)

                res = await apiService.findSearchBy('bankAccount', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setBankAccounts(res.items)

                res = await apiService.findSearchBy('trade', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setUserTrades(res.items)

                res = await apiService.findSearchBy('order', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setUserOrders(res.items)

                res = await apiService.findSearchBy('fiatDepositTx', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setUserFiatDepositTxs(res.items)

                res = await apiService.findSearchBy('depositTx', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setUserDepositTxs(res.items)

                res = await apiService.findSearchBy('fiatWithdrawTx', 'owner=' + userId)
                //console.log('wallets', JSON.stringify(res.items))
                setUserWithdrawTxs(res.items)
            }
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    }

    const handleIDVerify = async () => {
        // 
        let res = await apiService.patch('user', walletUser._id, { idValidate: 'validated', isIDValidated: true })
        if (res && res.status && res.status.success) {
            setShowToast(walletUser.email + ' хэрэглэгчийн ID-г амжилттай баталгаажууллаа')
            res = await apiService.findOneWithPath('user', 'sendIDdone', walletUser._id)
            if (res && res.status && res.status.success) {
                setShowToast(res.status.message)
                history.goBack()
            }
        }
    }

    const handleSend2FA = async () => {
        // 
        let res = await apiService.findOneWithPath('user', 'sendOTP', walletUser._id)
        if (res && res.status && res.status.success) {
            setShowToast(res.status.message || 'Амжилттай')
            //history.goBack()
        }
        else 
            setShowToast(res.message)
    }

    const handleSendEmailValidation = async () => {
        // 
        let res = await apiService.findOneWithPath('user', 'sendEmailValidation', walletUser._id)
        if (res && res.status && res.status.success) {
            setShowToast(res.status.message || 'Амжилттай')
            //history.goBack()
        }
        else 
            setShowToast(res.message)
    }

    const handleSendPhoneValidation = async () => {
        // 
        let res = await apiService.findOneWithPath('user', 'sendPhoneValidation', walletUser._id)
        if (res && res.status && res.status.success) {
            setShowToast(res.status.message || 'Амжилттай')
            //history.goBack()
        }
        else 
            setShowToast(res.message)
    }

    const handleOrderCancel = async (orderId, userId) => {
        // 
        let res = await apiService.patch('order/cancel', orderId, { userId } )
        if (res && res.status && res.status.success) {
            setShowToast(' захиалгыг амжилттай цуцаллаа ')
        }
        else if (res && res.error)
            setShowToast(' захиалгыг цуцлахад алдаа гарлаа: ' + res.error)
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={"Хэрэглэгчийн мэдээлэл: " + (walletUser ? walletUser.username : 'null')}>
            <MyToast 
                isOpen={ showToast !== '' ? true : false } 
                message={showToast}
                onDidDismiss={() => setShowToast()}
            />

            <MyModal 
                isOpen={showAddFundWallet !== '' ? true : false}
                onClose={() => setShowAddFundWallet('')}
                title={'Хэтэвч цэнэглэх'}
                >
                <AddFundWallet user={walletUser} wallet={showAddFundWallet} />
            </MyModal>

            <CardActions sx={{ justifyContent: 'flex-start' }}>
                <Button
                    onClick={() => handleIDVerify()}  
                    variant="contained" color="primary">
                    ID баталгаажуулах
                </Button>

                <Button
                    onClick={() => handleSend2FA()}  
                    variant="contained" color="primary">
                    2-FA имэйлээр илгээх
                </Button>

                <Button
                    onClick={() => handleSendEmailValidation()}  
                    variant="contained" color="primary">
                    Имэйл баталгаажуулах код илгээх
                </Button>

                <Button
                    onClick={() => handleSendPhoneValidation()}  
                    variant="contained" color="primary">
                    Утас баталгаажуулах код илгээх
                </Button>
            </CardActions>
            <Divider />
            {/* <PerfectScrollbar className={classes.ScrollHeight}> */}
                <CardContent className={classes.projectTableCard}>
                    <table>
                        <tr>
                            <td>Бүртгүүлсэн:</td>
                            <td><b>{datetimeFormat(walletUser.createdAt)}</b></td>
                        </tr>
                    {
                        walletUser.type === 'individual' ? 
                        <>
                        <tr>
                            <td>Овог:</td>
                            <td><b>{walletUser.lastName}</b></td>
                        </tr>
                        <tr>
                            <td>Нэр:</td>
                            <td><b>{walletUser.firstName}</b></td>
                        </tr>
                        <tr>
                            <td>Регистр:</td>
                            <td><b>{walletUser.registerNumber}</b></td>
                        </tr>
                        </>
                        :
                        <>
                        <tr>
                            <td>Байгуулагын нэр:</td>
                            <td><b>{walletUser.companyName}</b></td>
                        </tr>
                        <tr>
                            <td>Байгууллагын Регистр:</td>
                            <td><b>{walletUser.companyRegisterNumber}</b></td>
                        </tr>
                        </>
                    }
                        <tr>
                            <td>Имэйл:</td>
                            <td><b>{walletUser.email}</b></td>
                        </tr>
                        <tr>
                            <td>Утасны дугаар:</td>
                            <td><b>{walletUser.phoneNumber}</b></td>
                        </tr>
                        <tr>
                            <td>Имэйл баталгаажсан эсэх:</td>
                            <td><b>{walletUser.emailValidate}</b></td>
                        </tr>
                        <tr>
                            <td>Утас баталгаажсан эсэх:</td>
                            <td><b>{walletUser.phoneValidate}</b></td>
                        </tr>
                        <tr>
                            <td>ID баталгаажсан эсэх:</td>
                            <td><b>{walletUser.idValidate}</b></td>
                        </tr>
                        <tr>
                            <td>2-FA нээсэн эсэх:</td>
                            <td><b>{walletUser.otp ? 'тийм' : 'үгүй'}</b></td>
                        </tr>
                        <tr>
                            <td>Иргэний үнэмлэх зураг 1:</td>
                            <td><b>{walletUser.doc1 ? <img width={300} src={ kycUrl + walletUser.doc1} /> : 'N/A'}</b></td>
                        </tr>
                        <tr>
                            <td>Иргэний үнэмлэх зураг 2:</td>
                            <td><b>{walletUser.doc2 ? <img width={300} src={ kycUrl + walletUser.doc2} /> : 'N/A'}</b></td>
                        </tr>
                        <tr>
                            <td>Selfie:</td>
                            <td><b>{walletUser.doc3 ? <img width={300} src={ kycUrl + walletUser.doc3} /> : 'N/A'}</b></td>
                        </tr>
                        
                    </table>

                    <h4>Дансны мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Банк</TableCell>
                                    <TableCell sx={{ pl: 3 }}>Дансны нэр</TableCell>
                                    <TableCell>Данс дугаар</TableCell>
                                    <TableCell>Баталгаажсан эсэх</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Төлөв
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bankAccounts && bankAccounts.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/bank/' +
                                        (row.bank ? row.bank.logo : 'default.png')

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.bank.name}
                                        </TableCell>
                                        <TableCell>
                                            {row.addressName}
                                        </TableCell>
                                        <TableCell>{row.address}</TableCell>
                                        <TableCell>{row.isConfirmed ? 'баталгаажсан' : 'баталгаажаагүй'}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/bankAccount/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h4>Хэтэвч мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Койн / Валют</TableCell>
                                    <TableCell>Үлдэгдэл</TableCell>
                                    <TableCell>Захиалгад байгаа / Түгжигдсэн</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Төлөв
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fiatWallets && fiatWallets.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/fiat/' +
                                        (row.fiat ? row.fiat.logo : 'default.png')

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.fiat.name}
                                        </TableCell>
                                        <TableCell>{row.balance}</TableCell>
                                        <TableCell>{row.balanceInOrder}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/fiatWallet/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                                {wallets && wallets.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/coin/' +
                                        (row.coin ? row.coin.logo : 'default.png')

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.coin.name}
                                        </TableCell>
                                        <TableCell>{row.balance}</TableCell>
                                        <TableCell>{row.balanceInOrder}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/wallet/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                            { isAdmin ? <Chip 
                                                onClick={() => setShowAddFundWallet(row)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'цэнэглэх'} 
                                                size="small"
                                            /> : '' }
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h4>Мөнгөн орлогын мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Валют</TableCell>
                                    <TableCell>Тоо ширхэг</TableCell>
                                    <TableCell>Шилжүүлсэн</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell>Төлөв</TableCell>
                                    <TableCell>Зөвшөөрөгдсөн эсэх</TableCell>
                                    <TableCell>Тайлбар</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Үйлдэл
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userFiatDepositTxs && userFiatDepositTxs.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/fiat/mnt.png'

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.fiat ? row.fiat.ticker : 'N/A'}
                                        </TableCell>
                                        <TableCell>{formatCurrency(row.amount)}</TableCell>
                                        <TableCell>{datetimeFormat(row.txDate)}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.isConfirmed ? 'Зөвшөөрөгдсөн' : 'Зөвшөөрөгдөөгүй'}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/fiatDepositTx/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h4>Койн орлогын мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Валют</TableCell>
                                    <TableCell>Тоо ширхэг</TableCell>
                                    <TableCell>Шилжүүлсэн</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell>Төлөв</TableCell>
                                    <TableCell>Зөвшөөрөгдсөн эсэх</TableCell>
                                    <TableCell>Тайлбар</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Үйлдэл
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userDepositTxs && userDepositTxs.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/coin/' + row.coin.logo

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.coin ? row.coin.ticker : 'N/A'}
                                        </TableCell>
                                        <TableCell>{formatCurrency(row.amount)}</TableCell>
                                        <TableCell>{datetimeFormat(row.txDate)}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.isConfirmed ? 'Зөвшөөрөгдсөн' : 'Зөвшөөрөгдөөгүй'}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/depositTx/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h4>Мөнгөн зарлагын мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Валют</TableCell>
                                    <TableCell>Тоо ширхэг</TableCell>
                                    <TableCell>Шилжүүлсэн</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell>Төлөв</TableCell>
                                    <TableCell>Зөвшөөрөгдсөн эсэх</TableCell>
                                    <TableCell>Шимтгэл</TableCell>
                                    <TableCell>Тайлбар</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Үйлдэл
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userWithdrawTxs && userWithdrawTxs.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/fiat/mnt.png'

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.fiat ? row.fiat.ticker : 'N/A'}
                                        </TableCell>
                                        <TableCell>{formatCurrency(row.amount)}</TableCell>
                                        <TableCell>{row.txDate}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.isConfirmed ? 'Зөвшөөрөгдсөн' : 'Зөвшөөрөгдөөгүй'}</TableCell>
                                        <TableCell>{row.fee}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/fiatWithdrawTx/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h4>Арилжааны мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Койн / Валют</TableCell>
                                    <TableCell>Арилжааны төрөл</TableCell>
                                    <TableCell>Үнэ</TableCell>
                                    <TableCell>Тоо ширхэг</TableCell>
                                    <TableCell>type</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Үйлдэл
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userTrades && userTrades.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/coin/' +
                                        (row.pair.coin1 ? row.pair.coin1.logo : 'default.png')

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.pair.coin1.ticker}
                                        </TableCell>
                                        <TableCell classes={{
                                            root: row.side === 'buy' ? 
                                                classes.green : 
                                                classes.red
                                            }}>{row.side}</TableCell>
                                        <TableCell>{formatCurrency(row.price)}</TableCell>
                                        <TableCell>{formatCurrency(row.qty)}</TableCell>
                                        <TableCell classes={{
                                            root: row.tradeType === 'maker' ? 
                                                classes.blue : 
                                                classes.orange
                                            }}>{row.tradeType}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => history.push('/admin/trade/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h4>Захиалгын мэдээлэл</h4>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell sx={{ pl: 3 }}>Койн / Валют</TableCell>
                                    <TableCell>Захиалгын төрөл</TableCell>
                                    <TableCell>Үнэ</TableCell>
                                    <TableCell>Анхны тоо ширхэг</TableCell>
                                    <TableCell>Биелсэн тоо ширхэг</TableCell>
                                    <TableCell>Үлдэгдэл тоо ширхэг</TableCell>
                                    <TableCell>Төлөв</TableCell>
                                    <TableCell>Үүсгэсэн</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Үйлдэл
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userOrders && userOrders.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/coin/' +
                                        (row.pair.coin1 ? row.pair.coin1.logo : 'default.png')

                                    return <TableRow hover key={index}>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.pair.coin1.ticker}
                                        </TableCell>
                                        <TableCell classes={{
                                            root: row.side === 'buy' ? 
                                                classes.green : 
                                                classes.red
                                            }}>{row.side}</TableCell>
                                        <TableCell>{formatCurrency(row.price)}</TableCell>
                                        <TableCell>{formatCurrency(row.qty)}</TableCell>
                                        <TableCell>{formatCurrency(row.filledQty)}</TableCell>
                                        <TableCell>{formatCurrency(row.leavesQty)}</TableCell>
                                        <TableCell classes={{
                                            root: row.state === 'done' ? 
                                                classes.green : 
                                                classes.red
                                            }}>{row.state}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && row.state === 'wait' && <Chip 
                                                onClick={() => handleOrderCancel(row._id, row.owner._id) } 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'цуцлах'} 
                                                size="small"
                                            />}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            {/* </PerfectScrollbar> */}
        </MainCard>
    )
}

export default UserView