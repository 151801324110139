import React from 'react';
import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from './app-routes';
import MainLayout from './layout/MainLayout';

import PrivateRoute from './utils/PrivateRoute'

export default function ContentComponent() {

  return (
    <MainLayout>
      <Switch>
        {routes.map(({ path, component }) => (
          // <Route
          //   exact
          //   key={path}
          //   path={path}
          //   component={component}
          // />
          <PrivateRoute
            exact
            key={path}
            path={path}
            component={withRouter(component)}
          />
        ))}
        <Redirect to={'/home'} />
      </Switch>
    </MainLayout>
  );
}
