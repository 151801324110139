import React from 'react'
import { Link, withRouter } from 'react-router-dom'
//import List from 'devextreme-react/list';
//import Button from 'devextreme-react/button';
import DataGrid, { Column, Paging, Pager, Editing, Lookup, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
import './index.scss';
//import ToolbarListPage from '../../components/toolbar/toolbarListPage'
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
//import backendStore from '../../api/backendStore'
import { getUser } from '../../api/auth'

import IconDeposit from '../../data/icons/arrow-down-outline.svg'
import IconWithdraw from '../../data/icons/arrow-up-outline.svg'
import IconLoan from '../../data/icons/cash-outline.svg'
import IconExchange from '../../data/icons/repeat-outline.svg'

//import WalletTabs from './tabs'
//const coinInStore = backendStore({entityName: 'wallet'})

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      isLoading: true,
      arrays: [],
      walletList: []
    }
  }

  async componentDidMount() {
    const userData = await getUser()
    //console.log('loading user ' + JSON.stringify(userData.data))
    this.setState({ user: userData.data })

    const result = await apiService.findSearchBy('wallet', 'owner=' + userData.data._id);
    if (result.status.success && result.items.length) {
      //console.log('loading wallet ' + JSON.stringify(result.items))
      this.setState({ walletList: result.items })
    }

    this.setState({ isLoading: false })
  }

  render() {
    const { isLoading, walletList } = this.state

    return (
        isLoading ? 
            <LoadIndicator visible={isLoading} />
            :
        <div className="settings mtb15">
        <div className="container-fluid">

            {/* <ToolbarListPage modelName={'workflow'} title={this.props.title} /> */}
            {/* <WalletTabs /> */}
            <div className="title-field">
              <div>
                <h2 className={'content-block'}>Үлдэгдэл</h2>
              </div>
              <div className="inactive">
              <Link onClick={() => this.props.history.push('/deposit/coin/XRP')} >
                    <h2 className={'content-block'}>Орлого</h2>
                </Link></div>
              <div className="inactive">
                <Link onClick={() => this.props.history.push('/withdraw/coin/XRP')} >
                  <h2 className={'content-block'}>Зарлага</h2>
                </Link>
              </div>
              <div className="inactive">
                <Link onClick={() => this.props.history.push('/loan/XRP')} >
                    <h2 className={'content-block'}>Зээл</h2>
                </Link>
              </div>
            </div>

            <div className="card">
            <div className="card-body">
            {/* <h5 className="card-title">Your API Keys</h5> */}
            <DataGrid id="gridContainer"
                dataSource={walletList}
                keyExpr="_id"
                showBorders={true}
                rowRender={rowRender}
            >
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <Column dataField="coin"
                    caption=" "
                    width={50}
                    allowFiltering={false} allowSorting={false}
                    //cellRender={cellRender}
                />
                <Column dataField="coin"
                    caption={"Нэр"}
                    width={150}
                />
                <Column dataField="balance"
                    caption={"Үлдэгдэл"}
                    width={150}
                />
                <Column dataField="balanceInOrder"
                    caption={"Захиалгад байгаа"}
                    width={150}
                />
                <Column dataField="address"
                    caption={"Үйлдэл"}
                    allowFiltering={false} allowSorting={false}
                />
            </DataGrid>
            </div>
            </div>
        
        </div>
        </div>
    )
  }
}

function cellRender(data) {
    const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + data.value
    return <img src={picUrl} />
}

function rowRender(rowInfo) {
    const { coin, balance, balanceInOrder } = rowInfo.data
    const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + coin.logo

    return (
    <tbody className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}>
        <tr className="main-row">
            <td>
                <img src={picUrl} />
            </td>
            <td>
                <b>{coin.ticker}</b><br/>
                {coin.name}
            </td>
            <td>
            {balance}
            </td>
            <td>
            {balanceInOrder}
            </td>
            <td>
                <table>
                    <tr>
                        {/* <td>{renderLink('Худалдаж авах', '/wallet/buy/' + coin.ticker)}</td> */}
                        <td>{renderLink(IconDeposit, 'Орлого', '/deposit/coin/' + coin.ticker)}</td>
                        <td>{renderLink(IconWithdraw, 'Зарлага', '/withdraw/coin/' + coin.ticker)}</td>
                        <td>{renderLink(IconExchange, 'Арилжаа', '/trade')}</td>
                        <td>{renderLink(IconLoan, 'Зээл', '/loan')}</td>
                    </tr>
                </table>
            </td>
        </tr>
    </tbody>
    )
}

function renderLink(icon, label, route) {
    return (
        <div className="custom-field">
            <img src={icon} />
            <div><Link to={route}>{label}</Link></div>
        </div>
    )
}

export default withRouter(App)