import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import ToolbarListPage from 'components/toolbar/toolbarListPage'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth'
import { checkRole, hasRole } from 'api/auth'
import { datetimeFormat } from 'utils/helperFunction'
import { objectRender } from './renderFunctions'
import { getModel, getColumnsInList } from 'pages/admin/render/helpers'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    },
    ScrollHeight: {
        height: '500px'
    },
    backgroundColor: theme.palette.background.paper,
}));

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const DataGrid = ({ items, columns, modelName, onRowClick, onRowEdit }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()
    const isAdmin = hasRole('ADMIN')

    let [isLoading, setLoading] = useState(false)
    //const [isAdmin, setAdmin] = React.useState(false)
    // const [columns, setColumns] = useState([])

    useEffect(() => {
        //getData();
    }, []);

    const getData = async () => {
        try {
            // const tmp = await checkRole(user, 'ADMIN')
            // tmp !== {} && tmp ? setAdmin(true) : setAdmin(false)
            //console.log(' MyDataGrid items ', JSON.stringify(items))
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :

        <>
        {/* <ToolbarListPage routeUrl={'admin/' + modelName + '/add'} /> */}

        <MainCard title={modelName || ''} content={false}>
        <div className={classes.root}>
            {/* <PerfectScrollbar className={classes.ScrollHeight}> */}
                <CardContent className={classes.projectTableCard}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 3 }}></TableCell>
                                    {
                                        columns && columns.length && columns.map(col => (
                                            <TableCell sx={{ pl: 3 }}>{col.label || ''}</TableCell>
                                        ))
                                    }
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Үйлдэл
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && columns && columns.length && items.map((row, index) => {
                                    return <TableRow hover key={index}>
                                        <TableCell>{(index + 1)}.</TableCell>
                                        <RowItem row={row} columns={columns} />
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Chip 
                                                onClick={() => { onRowEdit && onRowEdit(row)} } 
                                                variant="outlined"
                                                color={'default'}
                                                label={'засах'}
                                                size="small"
                                            />}
                                            <Chip 
                                                onClick={() => { onRowClick && onRowClick(row) } } 
                                                variant="outlined"
                                                color={'default'}
                                                label={'цааш'} 
                                                size="small"
                                            />
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            {/* </PerfectScrollbar> */}
            </div>
        </MainCard>
        </>
    );
};

DataGrid.propTypes = {
    items: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    modelName: PropTypes.string.isRequired,
    onRowClick: PropTypes.func,
    onRowEdit: PropTypes.func
};

const RowItem = ({ row, columns }) => {
    //console.log(' row', JSON.stringify(row))

    if (columns && columns.length)
        return columns.map((col, i) => {
            const cellData = row[col.name]
            let ret = ''

            if (cellData && cellData._id)
                ret = objectRender(col.name, cellData)
            else if (col.name === 'createdAt')
                ret = datetimeFormat(cellData)
            else if (col.name === 'txDate')
                ret = datetimeFormat(cellData)
            else if (cellData && Array.isArray(cellData))
                ret = cellData.map(item => {
                    //console.log(' row is array', col.name, 'data', JSON.stringify(objectRender(col.name, item)))
                    return (objectRender(col.name, item) + ' ')
                })
            else ret = cellData
                
            return (
                <TableCell key={i}>
                    {ret}
                </TableCell>
                )
    })
    else 
        return <TableCell>Empty</TableCell>
}

export default DataGrid;
