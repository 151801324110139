import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { IonAlert, IonLoading } from '@ionic/react';

import { getModel, getColumnsInList, getColumns } from './helpers'
import apiService from 'api/apiService'
import ErrorComponent from 'components/error'
import MyDataGrid from './DataGrid'
import { useAuth } from 'contexts/auth';
import { hasRole } from 'api/auth'

const PageRendererList = ({ match }) => {
    const history = useHistory()
    const { user } = useAuth();
    const isAdmin = hasRole('ADMIN')
    const isSuperAdmin = hasRole('SUPERADMIN')

    let [isLoading, setLoading] = useState(true)
    const [datas, setDatas] = useState([])
    const [columns, setColumns] = useState([])
    const [modelName, setModelName] = useState(match.params.modelName)

    useEffect(() => {
        setModelName(match.params.modelName)
        getData()
    }, [match.params.modelName])

    // useEffect(() => {
        
    // }, [])

    const getData = async () => {
        try {
            console.log('modelName', modelName, 'user', JSON.stringify(user._id))
            const propsModel = getModel(modelName)
            const column = getColumnsInList(propsModel)
            setColumns(column)
            // const columns = ['name']
            // console.log('cols ', JSON.stringify(column))
            //setColumns(columns)

            let res = null
            if (isAdmin) {
                res = await apiService.find(modelName)
                console.log('isadmin', JSON.stringify(isAdmin))
            }
            else if (isSuperAdmin) {
                res = await apiService.find(modelName, 'limit=100')
                console.log('isSuperadmin', isSuperAdmin)
            }

            if (res && res.items) {
                //console.log('loading data ' + JSON.stringify(res.items.length))
                setDatas(res.items)
            }
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MyDataGrid
            items={datas}
            columns={columns}
            modelName={modelName}
            onRowClick={(row) => history.push('/admin/' + modelName + '/view/' + row._id )}
            onRowEdit={(row) => history.push('/admin/' + modelName + '/edit/' + row._id )}
        />
    )
}

PageRendererList.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
          modelName: PropTypes.string,
      }),
    })
}

export default withRouter(PageRendererList)