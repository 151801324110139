import React, { useEffect, useState } from 'react'
import MainCard from 'ui-component/cards/MainCard'
import {
    Autocomplete,
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'
import MyToast from 'components/toast'
import apiService from 'api/apiService'
import { IonLoading } from '@ionic/react'

const AddOrder = ({}) => {
    
    let [isLoading, setLoading] = useState(true)
    const [qty, setQty] = useState(0)
    const [showToast, setShowToast] = useState('')
    const [users, setUsers] = useState([])
    const [ieos, setIeos] = useState([])
    const [ieo, setIeo] = useState({})
    const [user, setUser] = useState({})

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            let res = await apiService.find('user')
            //console.log('user', JSON.stringify(res.data))
            if (res && res.items) {
                setUsers(res.items)
                setUser(res.items[0])
            }

            let res2 = await apiService.findSearchBy('ieo', 'isPublic=true')

            if (res2 && res2.items) {
                setIeos(res2.items)
                setIeo(res2.items[0])
            }
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    }

    const addFund = async () => {
        if (parseFloat(qty) > 0 && user && ieo) {
            let res = await apiService.post('ieoOrder/addOrder', { userId: user._id, ieoId: ieo._id, coinAmount: qty  })
            if (res && res.status && res.status.success)
                setShowToast(' Амжилттай: ' + qty)
            else
                setShowToast(' Алдаа гарлаа: ' + res.error)
        }
        else
            setShowToast(' Тоо ширхэг алдаатай байна: ' + qty)
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Шинээр захиалга хийх'}>
            <Typography variant="subtitle2" align="center">
                <strong>{showToast}</strong>
            </Typography>
            <CardContent>
                <table>
                    <tr>
                        <td>Хэрэглэгч:</td>
                    </tr>
                    <tr>
                        <td>
                            <Autocomplete
                                id="user"
                                name="user"
                                disablePortal
                                options={users}
                                defaultValue={users[0]}
                                renderInput={(params) => <TextField variant="outlined" {...params} label={'Хэрэглэгчид'} fullWidth />}
                                getOptionLabel={(option) => option['email'] + ' ' + option['phoneNumber'] + ' ' + option['firstName']}
                                onChange={(e, value) => setUser(value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Захиалгын байр:</td>
                    </tr>
                    <tr>
                        <td>
                            <Autocomplete
                                id="ieo"
                                name="ieo"
                                disablePortal
                                options={ieos}
                                defaultValue={ieos[0]}
                                renderInput={(params) => <TextField variant="outlined" {...params} label={'Байрууд'} fullWidth />}
                                getOptionLabel={(option) => option['name'] || 'сонгох'}
                                onChange={(e, value) => setIeo(value)}
                            />
                        </td>
                    </tr>
                    <tr><td>Нийт Puzzle: {ieo ? ieo.totalSupply : ''} ш</td></tr>
                    <tr>
                        <td>Цэнэглэх Puzzle тоо ширхэг:</td>
                    </tr>
                    <tr>
                        <td>
                            <TextField
                                id="qty"
                                fullWidth
                                label="Тоо ширхэг"
                                variant="outlined"
                                defaultValue={'0'}
                                onChange={(e) => setQty(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <Button
                            onClick={() => addFund()}
                            variant="contained" color="primary">
                            Хадгалах
                        </Button>
                        </td>
                    </tr>
                </table>
            </CardContent>
        </MainCard>
    )
}

export default AddOrder