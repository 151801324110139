import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Pagination,
    Stack
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard'
import MyToast from 'components/toast'
import { datetimeFormat, numberWithCommas } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'
import MyModal from 'components/modal'
import AddOrder from './addOrder'
import { getPictureUploadUrl } from 'utils/helperFunction';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const IEOOrders = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory();

    let [isLoading, setLoading] = useState(true);
    const [trainings, setTrainings] = useState([]);
    const [showToast, setShowToast] = useState('');
    const [showAddOrder, setShowAddOrder] = useState(false);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [showCertModal, setShowCertModal] = useState('')

    let startIndex = (page - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.find('ieoOrder')
            setTrainings(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    const handleTransfer = async (fiatWithdrawTxId) => {
        //
        const res = await apiService.post('fiatWithdrawTx', { fiatWithdrawTxId }, '/send')
        if (res && res.status && res.status.success) {
            setShowToast(res.status.message)
        }
        else {
            setShowToast(res.message)
        }
    }

    const handlePagination = (event, value) => {
        setPage(value);
    }

    let totalMeterSquares = 0
    let totalPuzzles = 0
    let totalPrices = 0

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'IEO зарлагын түүх'} content={false}>
            <MyToast 
                isOpen={ showToast !== '' ? true : false } 
                message={showToast}
                onDidDismiss={() => setShowToast()}
            />

            <MyModal
                isOpen={showAddOrder}
                onClose={() => setShowAddOrder(false)}
                title={'Захиалга оруулах'}
                >
                <AddOrder />
            </MyModal>

            <CardActions sx={{ justifyContent: 'flex-start' }}>
                <Button
                    onClick={() => setShowAddOrder(true)}  
                    variant="contained" color="primary">
                    Захиалга оруулах
                </Button>
            </CardActions>

            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Байр</TableCell>
                                <TableCell sx={{ pl: 3 }}>Эзэмшигч</TableCell>
                                <TableCell>Авсан Puzzle дүн</TableCell>
                                <TableCell>Авсан мөнгөн дүн</TableCell>
                                <TableCell>Зөвшөөрөгдсөн эсэх?</TableCell>
                                <TableCell>Төлөв</TableCell>
                                {/* <TableCell>Тайлбар</TableCell> */}
                                {/* <TableCell>Шилжсэн эсэх?</TableCell> */}
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Үйлдэл
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainings && trainings.map((row, index) => {
                                totalMeterSquares += row.ieo.roomMeterSquare
                                totalPuzzles += row.orderedCoinAmount
                                totalPrices += (row.orderedFiatAmount)
                                if(index >= startIndex && index < endIndex){
                                    return (
                                    <TableRow hover key={index}>
                                        <TableCell>{index+1}</TableCell>
                                        <TableCell>{row.ieo ? row.ieo.name : 'N/A'}</TableCell>
                                        <TableCell sx={{ pl: 3 }}>
                                            {row.user ? row.user.email : 'N/A'}<br/>
                                            {row.user ? row.user.lastName : 'N/A'} -н {row.user ? row.user.firstName : 'N/A'}<br/>
                                            {row.user ? row.user.phoneNumber : 'N/A'}
                                        </TableCell>
                                        <TableCell>{row.orderedCoinAmount}</TableCell>
                                        <TableCell>{numberWithCommas(row.orderedFiatAmount)}₮</TableCell>
                                        <TableCell>{row.isConfirmed ? 'Тийм' : 'Үгүй'}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        {/* <TableCell>{row.description}</TableCell> */}
                                        {/* <TableCell>{row.isDone ? 'Тийм' : 'Үгүй'}</TableCell> */}
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            <Chip
                                                onClick={() => history.push('/admin/ieoOrder/edit/' + row._id)}
                                                variant="outlined"
                                                color={'default'}
                                                label={'засах'}
                                                size="small"
                                            />
                                            <Chip
                                                onClick={() => setShowCertModal(row._id)}
                                                variant="outlined"
                                                color={'default'}
                                                label={'сертификат'}
                                                size="small"
                                            />
                                            {/* { !row.isConfirmed && <Chip
                                                onClick={() => handleTransfer(row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'шилжүүлэх'}
                                                size="small"
                                            /> } */}
                                        </TableCell>
                                    </TableRow>
                                )}})}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{numberWithCommas(totalMeterSquares)} м.кв</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{numberWithCommas(totalPuzzles)} ш</TableCell>
                                <TableCell>{numberWithCommas(totalPrices)} ₮</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{display: 'flex', justifyContent: 'center', paddingY: '1rem'}}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(trainings.length / Number(itemsPerPage))} page={page} variant="outlined" shape="rounded" onChange={handlePagination} />
                    </Stack>
                </Box>
                <MyModal
                    isOpen={showCertModal !== ''}
                    onClose={() => setShowCertModal('')}
                    title={'Гэрчилгээ'}
                    >
                    <img
                        style={{ width: 'auto', height: 'auto' }} 
                        src={getPictureUploadUrl() + 'puzzlecert/' + showCertModal + '.png'} alt='' />
                </MyModal>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default IEOOrders;