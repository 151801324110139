import React from 'react'
import { Link, withRouter } from 'react-router-dom'
//import List from 'devextreme-react/list';
//import Button from 'devextreme-react/button';
import DataGrid, { Column, Paging, Pager, Editing, Lookup, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
import './history.scss';
//import ToolbarListPage from '../../components/toolbar/toolbarListPage'
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
//import backendStore from '../../api/backendStore'
import { getUser } from '../../api/auth'

import IconDeposit from '../../data/icons/arrow-down-outline.svg'
import IconWithdraw from '../../data/icons/arrow-up-outline.svg'
import IconLoan from '../../data/icons/cash-outline.svg'
import IconExchange from '../../data/icons/repeat-outline.svg'

//import WalletTabs from './tabs'
//const coinInStore = backendStore({entityName: 'wallet'})

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      isLoading: true,
      arrays: [],
      historyList: []
    }
  }

  async componentDidMount() {
    const userData = await getUser()
    //console.log('loading user ' + JSON.stringify(userData.data))
    this.setState({ user: userData.data })
    //
    const coinFilter = this.props.coin ? ',coin=' + this.props.coin._id : ''

    const result = await apiService.findSearchBy('depositTx', 'owner=' + userData.data._id + coinFilter)
    if (result.status.success && result.items.length) {
      //console.log('loading wallet ' + JSON.stringify(result.items))
      this.setState({ historyList: result.items })
    }

    this.setState({ isLoading: false })
  }

  render() {
    const { isLoading, historyList } = this.state

    return (
        isLoading ? 
            <LoadIndicator visible={isLoading} />
            :
        <>
            <DataGrid id="gridContainer"
                dataSource={historyList}
                keyExpr="_id"
                showBorders={true}
                //rowRender={rowRender}
            >
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <Column dataField="createdAt"
                    caption={"Огноо"}
                    width={200}
                    //allowFiltering={false} allowSorting={false}
                    //cellRender={cellRender}
                    dataType="datetime"
                    sortIndex={1}
                    sortOrder="desc"
                />
                <Column dataField="coin.ticker"
                    caption={"Валют"}
                    width={100}
                />
                <Column dataField="amount"
                    caption={"Хэмжээ"}
                    width={200}
                    cellRender={(c) => { return (<b>{c.value}</b>) }}
                />
                <Column dataField="status"
                    caption={"Төлөв"}
                    width={200}
                    cellRender={renderCell}
                />
                <Column dataField="description"
                    caption={"Тайлбар"}
                    allowFiltering={false} allowSorting={false}
                />
                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
            </DataGrid>
        </>
    )
  }
}

function renderCell(cell) {
    return (
        <div className={cell.value == 'Орлого баталгаажсан' ? 'verified' : 'unverified'}>
            {cell.value}
        </div>
    )
}

function rowRender(rowInfo) {
    const { createdAt, coin, amount, status, description } = rowInfo
    return (
    <tbody className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}>
        <tr className="main-row">
            <td>
                {createdAt}
            </td>
            <td>
                <b>{coin ? coin.ticker : ''}</b><br/>
                {coin ? coin.name : ''}
            </td>
            <td>
                {amount}
            </td>
            <td>
                {status}
            </td>
            <td>
                {description}
            </td>
        </tr>
    </tbody>
    )
}

export default withRouter(App)