import { useAuth } from '../../contexts/auth';
import Button from 'devextreme-react/button';

export default function () {
  const { signOut } = useAuth();

    return (
        <Button
            id="submit"
            text={'Системээс гарах'}
            type="default"
            onClick={() => signOut()} />
    )
}