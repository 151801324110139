import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';

import Loadable from 'ui-component/Loadable';
import { useSelector } from 'react-redux';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/application/users/account-profile/Profile3')));
const ComingSoon2 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon2')));

export default function NotAuthenticatedContentComp() {

  return (
    <MinimalLayout>
      <Switch>
        <Route exact path="/" component={PagesLanding} />
        {/* <Route path="/register" component={AuthRegister} /> */}
        <Route path="/login" component={AuthLogin} />
        {/* <Route path="/reset-password" component={AuthResetPassword} />
        <Route path="/forgot-password" component={AuthForgotPassword} /> */}
        <Route path='/coming-soon' component={ComingSoon2} />
        <Redirect to={'/'} />
      </Switch>
    </MinimalLayout>
  );
}
