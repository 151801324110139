import React from 'react'
import { withRouter } from 'react-router-dom'

import TagBox from 'devextreme-react/tag-box'
import CheckBox from 'devextreme-react/check-box'
import backendStore from '../api/backendStore'
import apiService from '../api/apiService'
import LoadIndicator from 'devextreme-react/load-indicator'
import ErrorComponent from '../components/error'

import { accordionRender, objectRender, renderAssets } from './renderFunctions'
import { getModel, getColumns, getCircularReplacer, getDisplayFieldValue, isNotEmpty } from './helpers'

import './view.scss'
//import '../components/form/form.scss';

class PageRenderer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            model: null,
            submitButtonText: 'Хадгалах',
            isAdded: false,
            _id: null,
            title: null,
            formFields: null,
            formData: {},
            selectedFiles: [],
            hasAttachFile: false,
            modelStore: null
        }

        this.formId = this.props.match.params.formId || this.props.formId
        this.formDataId = this.props.match.params.formDataId || this.props.formDataId
        
        this.renderComponent = this.renderComponent.bind(this)
        this.renderTextBox = this.renderTextBox.bind(this)
        this.renderTextArea = this.renderTextArea.bind(this)
        this.renderRadioGroup = this.renderRadioGroup.bind(this)
        this.renderCheckBox = this.renderCheckBox.bind(this)
        this.renderDateBox = this.renderDateBox.bind(this)
        this.renderNumberBox = this.renderNumberBox.bind(this)
        this.renderLookup = this.renderLookup.bind(this)
        this.renderFileUpload = this.renderFileUpload.bind(this)
        this.renderTitle = this.renderTitle.bind(this)
        this.getFieldValue = this.getFieldValue.bind(this)
        this.getFieldCollectionValue = this.getFieldCollectionValue.bind(this)

        //console.log(' model :::: ' + JSON.stringify(this.model))
    }

    // data from Parent comp props
    static getDerivedStateFromProps(nextProps) {
        return { formId: nextProps.formId, formDataId: nextProps.formDataId }

        // const store = backendStore({entityName: nextProps.modelName.toLowerCase()});
        // const propsModel = getModel(nextProps.modelName)

        // if (nextProps.dataSource) {
        //     console.log('dataSource from parentComponent ' + nextProps.modelName)

        //     return {
        //         model: propsModel, formData: nextProps.dataSource, modelStore: store
        //     };
        // }
    }

    async componentDidMount() {
        await this.fetchData(this.formId, this.formDataId)
    }

    async fetchData(formId, formDataId) {
        // loading Data from API service
        const propsModel = getModel(formId)
        
        const result = await apiService.findOne('formdata', formDataId)
        ///const store = backendStore({entityName: this.props.modelName.toLowerCase()});
        console.log('data from API ' + JSON.stringify(result.data))
        let title = ''

        let tempData = []
        if (result.data) {
            title = result.data.form.name
            tempData = JSON.parse(result.data.data)
            //console.log('data from API ' + JSON.stringify(tempData))
            this.setState({
                ...this.state, title, formData: tempData, formFields: result.fields //model: propsModel, modelStore: store
            })
        }

        this.setState({isLoading: false})
    }

    render() {
        try {
            const { isLoading, formFields } = this.state

        return (
            <>
            {
                isLoading ?
                    <LoadIndicator visible={isLoading} />
                :
                <div className={'dx-widget'}>
                    {this.renderTitle()}
                    {/* {collectionName && collectionName} <br/>
                    {info.name && info.name} <br/> */}
                    
                    <div className="dx-fieldset">
                    {formFields && formFields.map(attr => {
                        return this.renderComponent(attr)
                    })}
                        {/* <div className="dx-field">
                            <div className="dx-field-value">
                                <Button
                                    id="back"
                                    text="Буцах"
                                    type="default"
                                    stylingMode="text"
                                    useSubmitBehavior={false}
                                    onClick={()=>this.props.history.goBack()} />
                            </div>
                        </div> */}
                    </div>
                </div>
            }
            </>
        )
        }
        catch(e) {
            return ErrorComponent(e)
        }
    }

    async onFormSubmit(e) {

    }

    renderTitle() {
        const title = this.state.title
        return (<h4>{title}</h4>)
    }

    renderComponent(attr) {
        if (attr.private)
            return ''
        
        switch(attr.editor) {
            case 'TextBox':
            default:
                return this.renderTextBox(attr)
            case 'TagBox':
                return this.renderTagBox(attr)
            case 'TextArea': 
                return this.renderTextArea(attr)
            case 'CheckBox': 
                return this.renderCheckBox(attr)
            case 'RadioGroup':
                return this.renderRadioGroup(attr)
            case 'NumberBox': 
                return this.renderNumberBox(attr)
            case 'FileUpload': 
                return this.renderFileUpload(attr)
            case 'Lookup':
                return this.renderLookup(attr)
            case 'DateBox':
                return this.renderDateBox(attr)
            case 'HtmlEditor': 
                return this.renderHtmlEditor(attr)
            case 'Accordion': 
                return this.renderAccordion(attr)
        }
    }
    
    renderTextBox(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">{this.getFieldValue(attr)}</div>
            </div>
        )
    }
    
    renderRadioGroup(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">{this.getFieldValue(attr)}</div>
            </div>
        )
    }

    renderTextArea(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">{this.getFieldValue(attr)}</div>
            </div>
        )
    }

    renderCheckBox(attr) {
        console.log(' checkbox value : ' + this.getFieldValue(attr))
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">
                    <CheckBox 
                        value={this.getFieldValue(attr)}
                        text={this.getFieldValue(attr) === '{Empty}' ? "Үгүй" : "Тийм"}>
                    </CheckBox></div>
            </div>
        )
    }

    renderNumberBox(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">{this.getFieldValue(attr)}</div>
            </div>
        )
    }

    renderLookup(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">{this.getFieldCollectionValue(attr)}</div>
            </div>
        )
    }
    
    renderDateBox(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">{this.getFieldValue(attr)}</div>
            </div>
        )
    }

    renderTagBox(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">
                    {
                        this.getFieldMultipleCollectionValue(attr).map((obj, i) => {
                            return (
                                <li key={obj._id}>{objectRender(attr.name, obj)}</li> //TODO : replace with displayFieldName
                            )
                        })
                    } 
                </div>
            </div>
        )
        return ''
    }

    renderHtmlEditor(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.getFieldValue(attr)
                        }}>
                    </div>
                </div>
            </div>
        )
    }

    renderFileUpload(attr) {
        //console.log(' Media ' + JSON.stringify(this.getFieldMultipleCollectionValue(attr)))
        return (
            <div className="dx-field" key={attr.name}>
                <div className="dx-field-label">{attr.label}</div>
                <div className="dx-field-value">
                    {renderAssets(this.getFieldMultipleCollectionValue(attr))}
                </div>
            </div>
        )
    }

    renderAccordion(attr) {
        return (
            <div className="dx-field" key={attr.name}>
                {accordionRender(this.getFieldCollectionValue(attr))}
            </div>
        )
    }

    getFieldValue(field) {
        const { formData, isLoading } = this.state
        if (formData)
            if ( isNotEmpty(formData[field.name]) )
                return formData[field.name]
    
        return '{Empty}'
    }

    getFieldCollectionValue(field) {
        const { formData, isLoading } = this.state
        if (formData && isNotEmpty(formData[field.name]) )
            return objectRender(field.name, formData[field.name])
        else return '{хоосон}'
        //return ''
    }

    // used for TagBox, SelectBox, Lookup
    getFieldMultipleCollectionValue(field) {
        const { formData, isLoading } = this.state
        const IDs = []
        if (formData && !isLoading)
            if (isNotEmpty(formData[field.name]) )
                formData[field.name].map((curr) => {
                    IDs.push(curr._id)
                })
            return IDs
        return '{Empty}'
        //return ''
    }

    // used for Photo, Videos
    getFieldMultipleCollectionValue(field) {
        const { formData, isLoading } = this.state
        const IDs = []
        if (formData && !isLoading)
            if (isNotEmpty(formData[field.name]) )
                formData[field.name].map((curr) => {
                    IDs.push(curr)
                })
            return IDs
        return '{Empty}'
        //return ''
    }
}

export default withRouter(PageRenderer)