import React from 'react'
import { withRouter } from 'react-router-dom'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup'
import TextBox from 'devextreme-react/text-box'
import NumberBox from 'devextreme-react/number-box'
import Lookup from 'devextreme-react/lookup'
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
import { getUser } from '../../api/auth'
import notify from 'devextreme/ui/notify'

class App extends React.Component {
    addButtonOptions = null
    closeButtonOptions = null

    constructor(props) {
        super(props)

        this.state = {
            user: null,
            isLoading: true,
            positionOf: '',
            bankList: [],
            coinList: [],
            selectedAddress: null,
            selectedBank: null,
            selectedCoin: null 
        }

        this.showPopup = this.showPopup.bind(this)
        this.addAccount = this.addAccount.bind(this)

        this.addButtonOptions = {
            icon: 'save',
            text: 'Нэмэх',
            onClick: (e) => this.addAccount(e),
            stylingMode: 'contained',
            type: 'default'
        }

        this.closeButtonOptions = {
            text: 'Хаах',
            onClick: () => this.showPopup(false),
            stylingMode: 'outlined',
            type: 'default'
        }
    }

    async componentDidMount() {
        const userData = await getUser()
        //console.log('loading user ' + JSON.stringify(userData.data))
        this.setState({ user: userData.data })

        await this.fetchData()

        this.setState({ isLoading: false })
    }

    async fetchData() {
        const result = await apiService.find('bank');
        if (result.status.success && result.items.length) {
            //console.log('loading wallet ' + JSON.stringify(result.items))
            this.setState({ bankList: result.items, selectedBank: result.items[0]._id })
        }

        const result2 = await apiService.findSearchBy('coin', 'ticker=MNT');
        if (result2.status.success && result2.items.length) {
            //console.log('loading wallet ' + JSON.stringify(result.items))
            this.setState({ coinList: result2.items, selectedCoin: result2.items[0]._id })
        }
    }

    render() {
        const { isLoading, bankList, coinList, user } = this.state

        return (
            isLoading ? 
                <LoadIndicator visible={isLoading} />
                :
            <>
                <Popup
                    visible={this.props.visible}
                    onHiding={() => this.showPopup(false)}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    title="Данс нэмэх"
                    container=".dx-viewport"
                    width={500}
                    //height={280}
                >
                    <Position
                        at="middle"
                        my="center"
                        of={this.state.positionOf}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={this.addButtonOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={this.closeButtonOptions}
                    />
                    <p>Валют:
                    <Lookup 
                        value={coinList[0]._id}
                        stylingMode="filled" 
                        width={200}
                        items={coinList}
                        //dataSource={coinList}
                        displayExpr="name"
                        //itemRender={ItemRender}
                        //fieldRender={FieldRender}
                        valueExpr="_id"
                        onValueChanged={(e) => this.setState( { selectedCoin: e.value }) }
                    >
                    </Lookup></p>
                    <p>Банк:
                    <Lookup 
                        defaultValue={bankList[0]._id} 
                        stylingMode="filled" 
                        width={200}
                        items={bankList}
                        //dataSource={coinList}
                        displayExpr="name"
                        itemRender={bankItemRender}
                        fieldRender={bankFieldRender}
                        valueExpr="_id"
                        onValueChanged={(e) => this.setState( { selectedBank: e.value }) }
                    >
                    </Lookup></p>
                    <p>Данс эзэмшигчийн нэр:
                    <TextBox 
                        defaultValue={user.fistname} 
                        stylingMode="filled" 
                        disabled={true}
                        width={200}
                        //onValueChanged={(e) => this.setState( { pinCode: e.value }) }
                    >
                    </TextBox></p>
                    <p>Дансны дугаар:
                    <NumberBox 
                        //defaultValue={this.getFieldValue(att)} 
                        stylingMode="filled" 
                        width={200}
                        onValueChanged={(e) => this.setState( { selectedAddress: e.value }) }
                    >
                    </NumberBox></p>
                </Popup>
            </>
        )
    }

    showPopup(visible) {
        this.props.showPopup(visible)
    }

    async addAccount(e) {
        const { selectedBank, selectedCoin, selectedAddress, user } = this.state
        const postBody = { 
            address: selectedAddress, 
            bank: selectedBank,
            coin: selectedCoin,
            owner: user._id,
            addressName: user.firstname,
            accountType: 'bank'
        }
        console.log(' account added: ' + JSON.stringify(postBody))

        if (selectedBank && selectedCoin && selectedAddress && user ) {
            const res = await apiService.insert('account', postBody)
            
            if (res.status.success) {
                notify({
                    message: 'Данс амжилттай нэмэгдлээ',
                    position: {
                    my: 'top middle',
                    at: 'top middle'
                    }
                }, 'success', 2000)
            }
            else
                notify({
                    message: 'Уучлаарай, алдаа гарлаа',
                    position: {
                    my: 'top middle',
                    at: 'top middle'
                    }
                }, 'error', 2000)

            this.props.showPopup(false)
        }
        else 
            notify({
                message: 'Дансны мэдээлэл алдаатай байна',
                position: {
                my: 'top middle',
                at: 'top middle'
                }
            }, 'error', 2000)
    }
}

function bankItemRender({ name, swift }) {
    const picUrl = process.env.REACT_APP_BACKEND_URL + '/images/' + swift + '.svg'
    return (
        <div className="custom-item">
          <img height={15} src={picUrl} />
          <div><b>{`${name}`}</b></div>
        </div>
    );
}
  
function bankFieldRender(field) {
    if (field) {
      const { name, swift } = field
      const picUrl = process.env.REACT_APP_BACKEND_URL + '/images/' + swift + '.svg'
      return (
          <div className="custom-field">
            <img height={20} src={picUrl} />
            <div><b>{`${name}`}</b></div>
          </div>
      )
    }
    else return ''
}

export default withRouter(App)