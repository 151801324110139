import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { datetimeFormat } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const Trainings = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    let [isLoading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.find('order')
            setTrainings(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Захиалгиуд'} content={false}>
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Маркет</TableCell>
                                <TableCell>Төрөл</TableCell>
                                <TableCell>Төлөв</TableCell>
                                <TableCell>Үнэ</TableCell>
                                <TableCell>Тоо ширхэг</TableCell>
                                <TableCell>Биелсэн тоо</TableCell>
                                <TableCell>Үлдэг тоо</TableCell>
                                <TableCell sx={{ pl: 3 }}>Эзэмшигч</TableCell>
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Төлөв
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainings && trainings.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>{row.pair.name}</TableCell>
                                    <TableCell>{row.side}</TableCell>
                                    <TableCell>{row.state}</TableCell>
                                    <TableCell>{row.price}</TableCell>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.filledQty}</TableCell>
                                    <TableCell>{row.leavesQty}</TableCell>
                                    <TableCell sx={{ pl: 3 }}>{row.owner ? 
                                        <Chip
                                            onClick={() => history.push('/useradmin/userview/' + row.owner._id)}
                                            variant="outlined"
                                            color={'default'}
                                            label={row.owner.username}
                                            size="small"
                                        />
                                     : 'N/A'}</TableCell>
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        <Chip
                                            onClick={() => history.push('/admin/order/edit/' + row._id)}
                                            variant="outlined"
                                            color={'default'}
                                            label={'засах'}
                                            size="small"
                                        />
                                    
                                        <Chip
                                            onClick={() => history.push('/admin/order/view/' + row._id)}
                                            variant="outlined" 
                                            color={'default'}
                                            label={'цааш'}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default Trainings;
