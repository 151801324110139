import Cookies from '../utils/Cookies'
//import defaultUser from '../utils/default-user';

export async function signIn(email, password, otp, captcha) {
  try {
    //const [cookies, setCookie] = useCookies(["gsafety"]);
    // Send request
    let token, message = ''
    const postData = { email, password, token: otp, captcha  }
    const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv1/auth/login`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }
    })
      .then( (response) => response.json())
      .then(res => {
          if (res.user && res.token) {
            token = res.token
            message = 'Logged in'
            //setCookie("gsafety", token, { path: "/" });
            const { _id, username, firstname, lastname, email, avatarUrl, roles, department, company } = res.user

            // const avatarFullUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + avatarUrl.fileName
            const avatarFullUrl = 'avatarurl'

            const user = { _id, username, firstname, lastname, email, roles, department, company, avatarUrl: avatarFullUrl, token }
            const userCookie = { _id, username, firstname, lastname, email, roles, company, avatarUrl: avatarFullUrl, token }
            Cookies.setCookie('token', token, 1);
            Cookies.setCookie('user', JSON.stringify(userCookie))
            localStorage.setItem('token', token);
            return { data: user, token: token, isOk: true }
          }
          else
            //return res.error
            return res
      })
      .catch(error => {
          console.log(' Error: ' + JSON.stringify(error))
          message = error.message
          return { message: error, isOk: false }
      })

    //console.log(' login ' + JSON.stringify(result));

    return result
  }
  catch(error) {
    return {
      isOk: false,
      message: "Authentication failed " + error
    };
  }
}

export async function getUser() {
  try {
    //const [cookies, getCookie] = useCookies(["gsafety"])
    let token = null
    // Send request
    //getCookie("gsafety", token, {
    //  path: "/"
    //});

    //console.log('cookie ' + JSON.stringify(cookies.gsafety))
    const userStr = Cookies.getCookie('user')
    //console.log(" getUSER : " + userStr)
    if (userStr)
      return {
        isOk: true,
        data: JSON.parse(userStr)
      }
    else
      return {
        isOk: false
      };
  }
  catch {
    return {
      isOk: false,
    };
  }
}

export const getToken = () => {
  //return sessionStorage.getItem('token') || null;
  return Cookies.getCookie('token') || null;
}

export async function checkRole(user, role) {
  let isRole = false
  try {
    user && user.roles && user.roles.map((userRole) => {
      if (userRole.code && userRole.code === role) {
        isRole = true
      }
    })

    return isRole
  }
  catch {
    return isRole
  }
}

export async function hasRole(role) {
  let isRole = false
  let data = null
  try {
    const userStr = await Cookies.getCookie('user')
    if (userStr)
      data = JSON.parse(userStr)
      data.roles.map((userRole) => {
        if (userRole.code && userRole.code === role) {
          isRole = true
        }
    })

    return isRole
  }
  catch {
    return isRole
  }
}

export async function createAccount(postData) {
  try {
    let message = ''
    //const postData = {username: username, email: email, password: password  }
    const result = fetch(`${process.env.REACT_APP_BACKEND_API}/apiv1/auth/register`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers:{
          'Content-Type': 'application/json'
      }
    })
      .then( (response) => response.json())
      .then(res => {
          if (res.user) {
            //console.log(res.data)
            const { _id, username, email } = res.user
            const user = { _id, username, email }
            return { data: user, isOk: true }
          }
          else
            //return res.error
            return res
          })
          .catch(error => {
              message = error.message
              return { message: error, isOk: false }
          })
    return result
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    //console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  };
}

export async function resetPassword(email) {
  try {
    // Send request
    //console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
