import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IonLoading } from '@ionic/react'
import { datetimeFormat, numberWithCommas } from 'utils/helperFunction'
import MainCard from 'ui-component/cards/MainCard';
import apiService from 'api/apiService'

import { makeStyles } from '@material-ui/core/styles';
import {
    CardContent,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Box,
    Pagination
} from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

const OrderedIEODetails = () => {
    const classes = useStyles();
    const history = useHistory()
    const routeParams = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    let startIndex = (page - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.findSearchBy('ieoOrder', 'ieo=' + routeParams.ieoId);
            setOrders(response.items);
        } catch(e) {
            console.log('failed to fetch: ', e);
            return;
        } finally {
            setIsLoading(false);
        }
    }

    const handlePagination = (event, value) => {
        setPage(value);
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={`IEO зарлагын түүх (${orders.length})`} content={false}>
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Байр</TableCell>
                                <TableCell sx={{ pl: 3 }}>Эзэмшигч</TableCell>
                                <TableCell>Авсан Puzzle дүн</TableCell>
                                <TableCell>Авсан мөнгөн дүн</TableCell>
                                <TableCell>Зөвшөөрөгдсөн эсэх?</TableCell>
                                <TableCell>Төлөв</TableCell>
                                {/* <TableCell>Тайлбар</TableCell> */}
                                {/* <TableCell>Шилжсэн эсэх?</TableCell> */}
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Үйлдэл
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders && orders.map((row, index) => {
                                if(index >= startIndex && index < endIndex){
                                    return (
                                    <TableRow hover key={index}>
                                        <TableCell>{index+1}</TableCell>
                                        <TableCell>{row.ieo ? row.ieo.name : 'N/A'}</TableCell>
                                        <TableCell sx={{ pl: 3 }}>
                                            {row.user ? row.user.email : 'N/A'}<br/>
                                            {row.user ? row.user.lastName : 'N/A'} -н {row.user ? row.user.firstName : 'N/A'}<br/>
                                            {row.user ? row.user.phoneNumber : 'N/A'}
                                        </TableCell>
                                        <TableCell>{row.orderedCoinAmount}</TableCell>
                                        <TableCell>{numberWithCommas(row.orderedFiatAmount)}₮</TableCell>
                                        <TableCell>{row.isConfirmed ? 'Тийм' : 'Үгүй'}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        {/* <TableCell>{row.description}</TableCell> */}
                                        {/* <TableCell>{row.isDone ? 'Тийм' : 'Үгүй'}</TableCell> */}
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            <Chip
                                                onClick={() => history.push('/admin/ieoOrder/edit/' + row._id)}
                                                variant="outlined"
                                                color={'default'}
                                                label={'засах'}
                                                size="small"
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}})}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{display: 'flex', justifyContent: 'center', paddingY: '1rem'}}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(orders.length / Number(itemsPerPage))} page={page} variant="outlined" shape="rounded" onChange={handlePagination} />
                    </Stack>
                </Box>
            </CardContent>
        </MainCard>
    )
}

export default OrderedIEODetails