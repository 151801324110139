import React, { useState } from 'react';

import './userView.scss';

import { IonActionSheet, IonChip, IonIcon, IonHeader, IonLabel, IonToolbar, IonButtons, IonContent, IonButton, IonBackButton, IonPage,
    IonLoading } from '@ionic/react'
import { callOutline, callSharp, logoTwitter, logoGithub, logoInstagram, shareOutline, shareSharp } from 'ionicons/icons';

import apiService from '../../api/apiService'

class UserViewPage extends React.Component {
    constructor(props) {
        super(props)

        this.userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.userId

        this.state = {
            isLoading: true,
            userData: []
        }
    }

    async componentDidMount() {
        const result = await apiService.findOne('user', this.userId);
        if (result.status.success) {
            //console.log('loading' + ' user view ' + JSON.stringify(result))
            this.setState({ userData: result.data })
        }

        this.setState({ isLoading: false })
    }
  
    render() {
        const { isLoading, userData } = this.state

        return (
            <IonPage id="speaker-detail">
            {
                isLoading ?
                    <IonLoading isOpen={isLoading} />
                :
                <>
                    <div className="speaker-background" style={{ backgroundImage: "url(/images/speaker-background.png)" }}>
                        <img src={`${process.env.REACT_APP_BACKEND_API}/uploads/asset/${userData.avatarUrl.fileName}`} alt={userData.username}/>
                        <h2>{userData.lastname.substring(0,1)}. {userData.firstname}</h2>
                    </div>

                    <div className="ion-padding speaker-detail">
                        <p><b>{userData.company.name}</b></p>

                        <p><b>{userData.department && userData.department.name}</b></p>

                        <hr/>

                        <IonChip color="twitter" >
                            <IonIcon icon={logoTwitter}></IonIcon>
                            <IonLabel>Twitter</IonLabel>
                        </IonChip>

                        <IonChip color="dark" >
                            <IonIcon icon={logoGithub}></IonIcon>
                            <IonLabel>GitHub</IonLabel>
                        </IonChip>

                        <IonChip color="instagram" >
                            <IonIcon icon={logoInstagram}></IonIcon>
                            <IonLabel>Instagram</IonLabel>
                        </IonChip>
                    </div>
                </>
            }
            </IonPage>
        )
    }
}

export default React.memo(UserViewPage)
