import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard'
import MyToast from 'components/toast'
import { datetimeFormat } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const Trainings = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    let [isLoading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([])
    const [showToast, setShowToast] = useState('')

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.findSearchBy('fiatWithdrawTx',  'isDone=true,type=fiatwithdraw')
            setTrainings(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    const handleTransfer = async (fiatWithdrawTxId) => {
        //
        const res = await apiService.post('fiatWithdrawTx', { fiatWithdrawTxId }, '/send')
        if (res && res.status && res.status.success) {
            setShowToast(res.status.message)
        }
        else {
            setShowToast(res.message)
        }
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Мөнгөн зарлагын түүх'} content={false}>
            <MyToast 
                isOpen={ showToast !== '' ? true : false } 
                message={showToast}
                onDidDismiss={() => setShowToast()}
            />
            <CardActions sx={{ justifyContent: 'flex-start' }}>
                
            </CardActions>
            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>Эзэмшигч</TableCell>
                                <TableCell>Дүн</TableCell>
                                <TableCell>Зөвшөөрөгдсөн эсэх?</TableCell>
                                <TableCell>Төлөв</TableCell>
                                <TableCell>Тайлбар</TableCell>
                                <TableCell>Шилжсэн эсэх?</TableCell>
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Үйлдэл
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainings && trainings.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ pl: 3 }}>
                                        {row.owner ? row.owner.email : 'N/A'}<br/>
                                        {row.owner ? row.owner.lastname : 'N/A'} -н {row.owner ? row.owner.firstname : 'N/A'}<br/>
                                        {row.owner ? row.owner.phoneNumber : 'N/A'}
                                    </TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.isConfirmed ? 'Тийм' : 'Үгүй'}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.isDone ? 'Тийм' : 'Үгүй'}</TableCell>
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        { !row.isDone && <Chip
                                            onClick={() => history.push('/admin/fiatWithdrawTx/edit/' + row._id)}
                                            variant="outlined"
                                            color={'default'}
                                            label={'засах'}
                                            size="small"
                                        /> }
                                    
                                        { !row.isDone && <Chip
                                            onClick={() => handleTransfer(row._id)}
                                            variant="outlined" 
                                            color={'default'}
                                            label={'шилжүүлэх'}
                                            size="small"
                                        /> }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default Trainings;
