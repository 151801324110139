import jwt from 'jsonwebtoken'

const Reports = ({ question }) => {
    var METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL;
    var METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY;

    console.log(' process.env.METABASE_SECRET_KEY ', process.env.REACT_APP_METABASE_SECRET_KEY)

    var payload = {
        resource: { question },
        params: {},
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
    var token = jwt.sign(payload, METABASE_SECRET_KEY);

    var iframeUrl = METABASE_SITE_URL + "/embed/question/" + token + "#theme=transparent&bordered=false&titled=true";

    return (
    <>
        <iframe
            src={iframeUrl}
            width={800}
            height={600}
            allowTransparency
            frameBorder={0}
        />
    </>
)}

export default Reports