import { User, Role, Coin, Wallet, Pair, BuyOrder, SellOrder, DepositTx, WithdrawTx, Bank, BankAccount, Company,
    Fiat, FiatDepositTx, FiatWithdrawTx, FiatWallet, IEO, IEOOrder, Nft, NftCollection, NftCoupon, Sale,
    Referral, IEOReferralTx, IeoGraph, Versions } from '../../../models/system'

export function getModel(modelName) {
    let model = null
    switch(modelName) {
        // System Models
        case 'user': model = User; break
        //case 'department': model = Department; break
        case 'role': model = Role; break
        case 'coin': model = Coin; break
        case 'wallet': model = Wallet; break
        case 'pair': model = Pair; break
        case 'buyOrder': model = BuyOrder; break
        case 'sellOrder': model = SellOrder; break
        case 'depositTx': model = DepositTx; break
        case 'withdrawTx': model = WithdrawTx; break
        case 'bank': model = Bank; break
        case 'bankAccount': model = BankAccount; break
        case 'fiat': model = Fiat; break
        case 'fiatWallet': model = FiatWallet; break
        case 'fiatDepositTx': model = FiatDepositTx; break
        case 'fiatWithdrawTx': model = FiatWithdrawTx; break
        case 'ieo': model = IEO; break
        case 'ieoOrder': model = IEOOrder; break
        case 'nft': model = Nft; break
        case 'nftCollection': model = NftCollection; break
        case 'nftCoupon': model = NftCoupon; break
        case 'sale': model = Sale; break
        case 'company': model = Company; break
        case 'referral': model = Referral; break
        case 'ieoReferralTx': model = IEOReferralTx; break
        case 'ieoGraph': model = IeoGraph; break
        case 'versions': model = Versions; break
    }

    return model
}

export function getColumns(model) {
    let columns = []

    if (model.attributes.length)
        columns = model.attributes.map(attr => {
        return attr.name
    })

    return columns
}

export function getColumnsInList(model) {
    let columns = []

    if (model.attributes.length) {
        model.attributes.map(attr => {
            attr.availableInLists && columns.push(attr)
        })
    }

    return columns
}

export function getDisplayFieldValue(collectionName) {
    let displayFieldName = 'name'
    switch(collectionName) {
        case 'user':
        case 'owner':
            displayFieldName = 'firstname'; break
        case 'fiat':
            displayFieldName = 'name'; break
    }

    return displayFieldName
}

export function getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}