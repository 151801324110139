import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule
  } from 'devextreme-react/validator'
//import List from 'devextreme-react/list';
//import Button from 'devextreme-react/button';
import DataGrid, { Column, Paging, Pager, Editing, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
import { Lookup, DropDownOptions } from 'devextreme-react/lookup'
//import './index.scss';
//import ToolbarListPage from '../../components/toolbar/toolbarListPage'
import LoadIndicator from 'devextreme-react/load-indicator'
import { getUser } from '../../api/auth'
import apiService from '../../api/apiService'
import XrpWithdraw from './xrp'
import BtcWithdraw from './btc'
import IconBulb from '../../data/icons/bulb-outline.svg'
import WithdrawHistory from './history'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      coinTicker: [],
      coinList: [],
      selectedCoin: {},
      selectedWallet: {},
      type: 'coin',
    }

    this.valueChanged = this.valueChanged.bind(this)
    this.renderWithdraw = this.renderWithdraw.bind(this)
  }

  async componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.match.params.coin !== this.props.match.params.coin) {
      const { coin, type } = this.props.match.params
      this.setState({ type: type, isLoading: true })
      await this.resetData()
      await this.fetchData(coin)
    }
  }

  async componentDidMount() {
    const { coin } = this.props.match.params
    await this.fetchData(coin)
  }

  async resetData() {
    this.setState({ coinTicker: null })
  }

  async fetchData(coinTicker) {
    const userData = await getUser()
    //console.log('loading user ' + JSON.stringify(userData.data))
    this.setState({ user: userData.data })

    let coins = []
    const result = await apiService.findSearchBy('wallet', 'owner=' + userData.data._id)
    //const result = await apiService.find('coin')
    if (result.status.success && result.items.length) {
      // 
      result.items.map(selected => {
        coins.push(selected.coin)
        if (selected.coin.ticker === coinTicker)
          //console.log('selected coin ' + JSON.stringify(selected))
          this.setState({ selectedCoin: selected.coin, selectedWallet: selected })
      })

      this.setState({ coinList: coins })
    }

    this.setState({ isLoading: false, coinTicker: coinTicker })
  }

  render() {
    const { isLoading, coinTicker, selectedCoin, coinList, selectedWallet } = this.state

    return (
        isLoading ? 
            <LoadIndicator visible={isLoading} />
            :
          <div className="settings mtb15">
          <div className="container-fluid">

            {/* <ToolbarListPage modelName={'workflow'} title={this.props.title} /> */}
            <div className="title-field">
              <div className="inactive">
                <Link onClick={() => this.props.history.push('/wallet')} >
                    <h2 className={'content-block'}>Үлдэгдэл</h2>
                </Link>
              </div>
              <div className="inactive">
                <Link onClick={() => this.props.history.push('/deposit/coin/' + coinTicker)} >
                    <h2 className={'content-block'}>Орлого</h2>
                </Link>
              </div>
              <div>
                <h2 className={'content-block'}>Зарлага</h2>
              </div>
              <div className="inactive">
                <Link onClick={() => this.props.history.push('/loan/' + coinTicker)} >
                    <h2 className={'content-block'}>Зээл</h2>
                </Link>
              </div>
            </div>

            <div className="card">
            <div className="card-body">
              {/* <h5 className="card-title">Your API Keys</h5> */}
              <div className="settings-profile">
            <table>
              <tr>
                <td width="30%" valign="top">
                      
                  <b>1. Зарлага хийх валют:</b><br/>
                  <Lookup
                      defaultValue={selectedCoin._id} 
                      items={coinList}
                      //dataSource={coinList}
                      searchEnabled={true}
                      searchExpr={['name']}
                      displayExpr="name"
                      itemRender={ItemRender}
                      fieldRender={FieldRender}
                      valueExpr="_id"
                      stylingMode="filled"
                      onValueChanged={this.valueChanged}
                  >
                  <Validator>
                      <RequiredRule message={`Орлого хийх валютаа сонгоно уу`} />
                  </Validator>
                  </Lookup>
                  
                  <p>Нийт үлдэгдэл: <b>{selectedWallet ? selectedWallet.balance : '0.00000000'}</b></p>

                  <div>
                    <img width={'25'} src={IconBulb} color={'green'} />
                    <p><b>Анхааруулга:</b> <br/></p>
                    <p>1. If you have deposited, please pay attention to the text messages, site letters and emails we send to you.</p>
                    <p>2. Coins will be deposited after 1 network confirmations.</p>
                    <p>3. Until 2 confirmations are made, an equivalent amount of your assets will be temporarily unavailable for withdrawals.
                    </p>
                  </div>
                </td>
                <td width="1.5%"></td>
                <td width="68.5%" valign="top">
                  {selectedCoin && this.renderWithdraw(selectedCoin)}
                </td>
              </tr>
            </table>
              </div>
            </div>
            </div>

            <div className="card">
            <div className="card-body">
              <h5 className="card-title">Зарлагын түүх</h5>
              <div className="settings-profile">
                <WithdrawHistory coin={selectedCoin} />
              </div>
            </div>
            </div>
        
        </div>
        </div>
    )
  }

  renderWithdraw(selectedCoin) {
    switch(selectedCoin.ticker) {
        case 'XRP':
        default:
             return <XrpWithdraw wallet={this.state.selectedWallet} coinId={this.state.selectedCoin._id} />;
        case 'BTC': 
             return <BtcWithdraw wallet={this.state.selectedWallet} coinId={this.state.selectedCoin._id} />;
        case 'DOGE': 
          return 'DOGE'
        case 'ETH': 
          return 'ETH'
        case 'BNB': 
          return 'BNB'
    }
  }

  valueChanged(e) {
    let ticker = this.state.coinTicker
    
    this.state.coinList.map(coin => {
      if (coin._id == e.value)
        ticker = coin.ticker
        this.setState({ selectedCoin: coin })
    })

    this.props.history.push('/withdraw/coin/' + ticker)
    //console.log(' selected coin ' + ticker)
    this.setState({ isLoading: true })
    this.fetchData( ticker )
  }
}

function ItemRender({ name, ticker, logo }) {
    const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + logo
    return (
        <div className="custom-item">
          <img height={40} src={picUrl} />
          <div><b>{`${ticker}`}</b> {`${name }`}</div>
        </div>
    );
}

function FieldRender(field) {
    if (field) {
      const { name, ticker, logo } = field
      const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + logo
      return (
          <div className="custom-field">
            <img height={20} src={picUrl} />
            <div><b>{`${ticker}`}</b> {`${name }`}</div>
          </div>
      )
    }
    else return ''
}

export default withRouter(App)