import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard'
import MyToast from 'components/toast'
import { datetimeFormat } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const Trainings = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    let [isLoading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([])
    const [showToast, setShowToast] = useState('')

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.find('feeTx')
            setTrainings(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Шимтгэлийн орлогын түүх'} content={false}>
            <MyToast 
                isOpen={ showToast !== '' ? true : false } 
                message={showToast}
                onDidDismiss={() => setShowToast()}
            />
            <CardActions sx={{ justifyContent: 'flex-start' }}>
                
            </CardActions>
            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 2 }}>Харилцагч</TableCell>
                                <TableCell>Валют</TableCell>
                                <TableCell>Шимтгэл</TableCell>
                                <TableCell>Шимтгэлийн хувь</TableCell>
                                <TableCell>Тоо ширхэг</TableCell>
                                <TableCell>Үнэ</TableCell>
                                
                                <TableCell>Side</TableCell>
                                
                                
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainings && trainings.map((row, index) => {

                                const owner = row.trade ? row.trade.owner : 
                                    (row.fiatWithdrawTx ? row.fiatWithdrawTx.owner : null)
                                return (
                                <TableRow hover key={index}>
                                    <TableCell>
                                        {owner ? owner.email : 'N/A'
                                        }<br/>
                                        {owner ? owner.lastname : 'N/A'} -н {owner ? owner.firstname : 'N/A'}
                                    </TableCell>
                                    <TableCell>{row.currencyName}</TableCell>
                                    <TableCell>{row.totalAmount}</TableCell>
                                    <TableCell>{row.feePercent}</TableCell>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell>{row.price}</TableCell>
                                    <TableCell>{row.trade ? row.trade.side : 
                                        row.fiatWithdrawTx ? 'fiatwithdraw' : ''
                                    }</TableCell>
                                    
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default Trainings;
