import React from 'react';
import Button from 'devextreme-react/button';
import { Link, useHistory } from 'react-router-dom';

export function rowRender(columns, row) {
    console.log(' roww ' + JSON.stringify(row.data) )
    return (
      <tbody className={`employee dx-row ${row.rowIndex % 2 ? 'dx-row-alt' : ''}`}>
        <tr className="main-row">
            {
                row.data && columns && columns.map(r => {
                    return <td>{row.data[r]}</td>
                })
            }
        </tr>
      </tbody>
    );
}

export function accordionRender(row) {
  console.log(' roww ' + JSON.stringify(row) )
  return (
    <div>
        <p>
          <b>{row.title} </b>
        </p>
        <p>
          <span>{row.body} </span>
        </p>
    </div>
  );
}

export function objectFindById(id, objectArray) {
  let result = null
  
  objectArray.map(current => {
     if (current._id === id)
      result = current
  })

   return result
}

export function objectRender(collectionName, modelData) {
  //console.log(' render object ' + collectionName + JSON.stringify(modelData))
  switch (collectionName) {
      case 'assignee':
        return renderAssignee(modelData)
      case 'instructionsign':
      case 'activity':
        return renderActivity(modelData)
      case 'user':
      case 'assignedUser':
      case 'assignedUsers':
      case 'owner':
        return renderUserInfo(modelData)
      case 'department':
      case 'form':
      case 'model':
      case 'field':
      case 'shownForm':
      case 'role':
      case 'roles':
      case 'coin':
      case 'bank':
      case 'fiat':
      case 'ieo':
      case 'company':
        return modelData.name
      case 'nftCollection':
      case 'nft':
        return modelData.title
      default:
        return modelData._id
  }
}

function renderAssignee(instObj) {
  const activityName = instObj.kind.toLowerCase()
  return (
    <div className="employee">
      <div>
      {/* <img height={50} src={item.avatarPicture.fileName} /> */}
      </div>
      <div>{instObj.kind}</div>
      <div><Link to={`/page/${activityName}/view/${instObj.item._id}`} >Харах</Link></div>
    </div>
  );
}

function renderActivity(instObj) {
  const assigneeName = instObj.assignee.kind.toLowerCase()
  const assigneeId = instObj.assignee.item._id
  return (
    <div className="employee">
      <div>
      {/* <img height={50} src={item.avatarPicture.fileName} /> */}
      </div>
      <div>{assigneeName}</div>
      <div><Link to={`/page/${assigneeName}/view/${assigneeId}`} >Харах</Link></div>
    </div>
  );
}

function renderUserInfo(userObj) {
  var photoUrl = `${process.env.REACT_APP_BACKEND_API}/images/employees/oraclelinux.png`;
  console.log('renderUserInfo', JSON.stringify(userObj.firstname))

  return (
    <>
    {userObj.firstname} / {userObj.lastname} / {userObj.phoneNumber}
    </>
    // <div className="employee">
    //   {/* <div>
    //   <img height={50} src={photoUrl}/>
    //   <img height={50} src={item.avatarPicture.fileName} />
    //   </div> */}
    //   <div>{userObj.firstname} {userObj.lastname}</div>
    // </div>
  );
}

export function renderAssets(assets) {
  // check If video
  // <div className="content" style={{ display: 1 ? 'block' : 'none' }}>
  return (
    assets.map((file, i) => {
      const { fileType, fileName } = file || ''
      switch (fileType) {
        case 'video':
          return (
              <div className="selected-item" key={i}>
                  <span><li>
                    <a href={process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + fileName} target="_bank">{fileName}</a>
                    <Button icon="video" text="Үзэх" />
                    </li></span>
                  {/* <span><li>{file.fileName} <Button text="Видео үзэх" onClick={} /></li></span> */}
              </div>)
        case 'image':
          return (
              <div className="selected-item" key={i}>
                  <span><li>
                    <img height={60} src={`${process.env.REACT_APP_BACKEND_API}/uploads/asset/${fileName}`} /> 
                    <Button icon="image" text="Үзэх" /></li></span>
              </div>)
        case 'doc':
          return (
            <div className="selected-item" key={i}>
                <span><li>
                <a href={process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + fileName} target="_bank">{fileName}</a>
                  <Button icon="exportpdf" text="Үзэх" /></li></span>
            </div>)
        default: 
            return (<span>{fileType}<li>{fileName}</li></span>)
      }
    })
  )

  // <PictureGallery items={this.getFieldCollectionValue(attr)} />
  // <Gallery
  //     id="gallery"
  //     items={this.getFieldCollectionValue(attr)}
  //     height={200}
  //     slideshowDelay={2000}
  //     loop={false}
  //     showNavButtons={true}
  //     showIndicator={true} 
  // />
}