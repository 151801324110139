import React from 'react';
import { withRouter } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Tooltip } from 'devextreme-react/tooltip';
import notify from 'devextreme/ui/notify';
import 'devextreme/ui/select_box';
import './toolbar.scss'

function renderLabel(title) {
  return <div className="toolbar-label">{title}</div>;
}

class ToolbarComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { modelName, title, routeUrl } = this.props || ''

    return (
        <Toolbar>
          <Item location="before"
            widget="dxButton"
            options={this.renderBackButton()} />
          <Item location="before"
            widget="dxButton"
            options={this.renderRefreshButton()} />
          <Item location="center"
            locateInMenu="never"
            render={() => renderLabel(title)} />
          <Item location="after"
            locateInMenu="auto"
            widget="dxButton"
            options={this.renderAddButton(routeUrl)} />
        </Toolbar>
    );
  }

  renderAddButton(routeUrl) {
    console.log(' URL ' + '/' + routeUrl + '/' + this.props.modelName + '/add')
    const addButtonOptions = {
      icon: 'plus',
      onClick: () => {
        this.changeRoute('/' + routeUrl + '/' + this.props.modelName + '/add')
      }
    }

    return addButtonOptions
  }

  renderBackButton() {
    const backButtonOptions = {
      type: 'back',
      onClick: () => this.props.history.goBack()
    };

    return backButtonOptions
  }

  renderRefreshButton() {
    const refreshButtonOptions = {
      icon: 'refresh',
      onClick: () => {
        notify('Refresh button has been clicked!');
      }
    };

    return refreshButtonOptions
  }

  changeRoute(url) {
    this.props.history.push(url)
  }
}

export default withRouter(ToolbarComponent)