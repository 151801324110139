import React, { useState } from 'react';
import './profile.scss';
import { Form, SimpleItem, TabbedItem, Tab, ButtonItem, ButtonOptions, Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  CustomRule,
  StringLengthRule,
  AsyncRule } from 'devextreme-react/form';
import { Validator } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import 'devextreme-react/autocomplete';
import apiService from '../../api/apiService'
import { getUser } from '../../api/auth';

const inputEditorOptions = { stylingMode: 'filled' };

class App extends React.Component {
  constructor() {
    super();
    this.profile = null

    this.state = {
      user: null
    }

    this.formFieldDataChanged = this.formFieldDataChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
      return ({ user: nextProps.dataSource })
  }

  componentDidMount() {
  }

  render() {

    return (
      <>
          <Form
            id={'formEditProfile'}
            formData={this.state.user}
            onFieldDataChanged={this.formFieldDataChanged}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
            showValidationSummary={true}
            validationGroup="formEditProfile"
          >
                <SimpleItem dataField="_id" visible={false} />
                <SimpleItem dataField="username" editorOptions={inputEditorOptions}
                  
                  //render={renderPicture}
                  //isRequired={true}
                  // customizeItem={this.customizeItem}
                  // visibleIndex="1"
                >
                  <Label location="top" alignment="left" text="Хэрэглэгчийн нэр" />
                  <RequiredRule message="Username is required" />
                </SimpleItem>
                <SimpleItem dataField="firstname"  editorOptions={inputEditorOptions}>
                  <Label location="top" alignment="left" text="Өөрийн нэр" />
                  <RequiredRule message="Firstname is required" />
                </SimpleItem>
                <SimpleItem dataField="lastname"  editorOptions={inputEditorOptions}>
                  <Label location="top" alignment="left" text="Овог нэр" />
                  <RequiredRule message="Lastname is required" />
                </SimpleItem>
                <SimpleItem dataField="email"  editorOptions={inputEditorOptions}
                   helpText="Жишээ нь: name@gmail.com гм " 
                   //visibleIndex="0"
                >
                  <Label location="top" alignment="left" text="Имэйл" />
                  <RequiredRule message="Email is required" />
                  <EmailRule message="Email is invalid" />
                </SimpleItem>

                <ButtonItem>
                  <ButtonOptions
                      id="editProfile"
                      text="Шинэчлэх"
                      icon="save"
                      type="default"
                      useSubmitBehavior={true}
                      onClick={this.handleSubmit}
                  />
                </ButtonItem>

          </Form>
      </>
    )
  }

  formFieldDataChanged(e) {
    const updatedField = e.dataField;
    const newValue = e.value;
    // Event handling commands go here
    this.profile = e.component.option("formData");
    
    const newFormData = { ...this.state.user, [updatedField]: newValue }
    this.setState({ user: newFormData })

    console.log(' state Value ' + newValue + ' : ' + JSON.stringify(this.state.user))
  }

  async handleSubmit(e) {
    let res = e.validationGroup.validate();
    if (res.isValid) {
        // updating profile post to API 
        const postData = this.state.user
        const { _id } = this.state.user
        const result = await apiService.update('user', _id, postData)
        if (result.status.success)  {
            notify({
                message: 'Мэдээллийг амжилттай шинэчлэлээ',
                position: {
                  my: 'center top',
                  at: 'center top'
                }
              }, 'success', 3000);
        }
        else {
            notify({
                message: 'Мэдээллийг шинэчлэхэд алдаа гарлаа',
                position: {
                  my: 'center top',
                  at: 'center top'
                }
              }, 'error', 3000);
        }
    }
    
    //e.preventDefault();
  }  
}

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1
};

export default App