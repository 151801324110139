import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { datetimeFormat } from 'utils/helperFunction'
import MyModal from 'components/modal'
import MyToast from 'components/toast'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'
import UploadFiles from 'components/file-uploader';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const IEOPics = ({ match }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()
    const [ieo, setIEO] = useState();
    const [showToast, setShowToast] = useState('')

    let [isLoading, setLoading] = useState(true)
    const { ieoId } = match.params

    const picUrl = process.env.REACT_APP_API_URL + '/uploads/asset/ieo/'

    useEffect(() => {
        getData();
    }, []);

    // Similar to componentDidMount and componentDidUpdate:  
    useEffect(() => {
        if (isLoading)
            getData();
    });

    const getData = async () => {
        try {
            const response = await apiService.findOne('ieo', ieoId)
            setIEO(response.data)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
    };

    const onUploaded = async (e) => {
        // adding Uploaded item to selectedFiles
        const { name, mimetype } = e
        console.log(' name ', name)

        try {
            // Patch new Asset file into Entity
            let postData = { picture: name }

            const result = await apiService.attach('ieo', ieoId, postData )
            if (result.items) {
                setLoading(true);
                // setIEO(result.items[0])
                setShowToast('Зурган файл амжилттай хуулагдлаа ')
            }
            else
                setShowToast('Зурган файл хуулагдахад алдаа гарлаа ')
        }
        catch (error) {
            console.log(' error ', error)
            setShowToast('Уучлаарай, файлыг сервер рүү хуулахад алдаа гарлаа: ', error)
        }
    }

    const handleDelete = async (picUrl) => {
        const postData = { pic: picUrl }
        const result = await apiService.delete('ieo', ieoId, 'deletePic', postData )
        if (result.success) {
            setLoading(true);
            // setIEO(result.items[0])
            setShowToast('deleted ')
        }
        else
            setShowToast('delete error')
    }

    return (
        isLoading || !ieo ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'IEO: ' + (ieo ? ieo.name : '')} content={false}>
            <MyToast 
                isOpen={ showToast !== '' ? true : false } 
                message={showToast}
                onDidDismiss={() => setShowToast()}
            />
            <CardActions sx={{ justifyContent: 'flex-start' }}>
                <UploadFiles onFileUploaded={e => onUploaded(e)} uploadUrl="/apiv2/ieo/upload" />
            </CardActions>
            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Preview</TableCell>
                                <TableCell>Filename</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Төлөв
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* <Gallery // TODO: replace with rennerAssets function 
                                id="gallery"
                                items={selectedFiles}
                                height={200}
                                slideshowDelay={2000}
                                loop={false}
                                showNavButtons={true}
                                showIndicator={true} 
                            /> */}
                            {
                            ieo && ieo.pictures ? ieo.pictures.reverse().map((pic, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>
                                        <img width={300} src={ picUrl + pic} />
                                    </TableCell>
                                    <TableCell>{pic}</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        <Chip
                                            onClick={() => handleDelete(pic)}
                                            variant="outlined"
                                            color={'default'}
                                            label={'устгах'}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            )) : '...'
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default IEOPics;
