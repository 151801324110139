import React, { useEffect } from 'react';
import { IonToast } from '@ionic/react';

const CustomizedSnackbars = ({ isOpen, message, onDidDismiss }) => {
  return (
    <IonToast 
        isOpen={isOpen}
        message={message} 
        onDidDismiss={ () => onDidDismiss && onDidDismiss() }
        duration={3000}
        position="top"
    />
  );
}

export default CustomizedSnackbars