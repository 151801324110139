import React from 'react';
import { withRouter } from 'react-router-dom';
import { IonAlert, IonLoading } from '@ionic/react';

import backendStore from 'api/backendStore';
import apiService from 'api/apiService'
import { getUser, hasRole } from 'api/auth'

import ErrorComponent from 'components/error'

import './view.scss'

import { getModel, getDisplayFieldValue, isNotEmpty } from './helpers'

// material-ui
import { Button, Checkbox, Grid, Radio, RadioGroup, 
    Autocomplete, InputAdornment, TextField, FormControl, 
    Stack, FormGroup, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateTimePicker from '@material-ui/lab/DateTimePicker';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
//import { gridSpacing } from 'store/constant';
import { objectRender } from './renderFunctions'
import UploadFiles from 'components/file-uploader';
import MyToast from 'components/toast'

const Description = ({ att }) => {
    return (
        att.description ? <small>{att.description}</small> : <></>
    )
}

//import '../components/form/form.scss';
class PageRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            model: null,
            isAdded: false,
            submitButtonText: 'Шинээр нэмэх',
            _id: null,
            modelData: {},
            formData: {},
            refFormId: null,
            selectedFiles: [],
            hasAttachFile: false,
            modelStore: null,
            collections: [], // for Lookup, SelectBox data's
            isAdmin: false,
            currentUser: null,
            showAlertDelete: false,
            showToast: ''
        }

        this.fetchData = this.fetchData.bind(this)
        // getting Model settings name
        this.model = getModel(this.props.modelName)
        //this.model = Training
        this.renderComponent = this.renderComponent.bind(this)
        this.renderTextBox = this.renderTextBox.bind(this)
        this.renderTextArea = this.renderTextArea.bind(this)
        this.renderRadioGroup = this.renderRadioGroup.bind(this)
        this.renderCheckBox = this.renderCheckBox.bind(this)
        this.renderDateBox = this.renderDateBox.bind(this)
        this.renderNumberBox = this.renderNumberBox.bind(this)
        this.renderSelectBox = this.renderSelectBox.bind(this)
        this.renderLookup = this.renderLookup.bind(this)
        this.renderFileUpload = this.renderFileUpload.bind(this)
        this.renderTitle = this.renderTitle.bind(this)
        this.renderTagBox = this.renderTagBox.bind(this)
        this.renderHtmlEditor = this.renderHtmlEditor.bind(this)

        this.getFieldValue = this.getFieldValue.bind(this)
        this.getFieldCollectionValue = this.getFieldCollectionValue.bind(this)

        this.valueChanged = this.valueChanged.bind(this)
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onFormRemove = this.onFormRemove.bind(this)
        this.onSelectedFilesChanged = this.onSelectedFilesChanged.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);

        //console.log(' model :::: ' + JSON.stringify(this.model))
    }

    // data from Parent comp props
    static getDerivedStateFromProps(nextProps) {
        return { modelName: nextProps.modelName, Id: nextProps.Id }
    }
    // static getDerivedStateFromProps(nextProps) {
    //     const store = backendStore({entityName: nextProps.modelName.toLowerCase()});
    //     const propsModel = getModel(nextProps.modelName)
    //     console.log(' dataSource: ' + JSON.stringify(nextProps.dataSource))

    //     if (nextProps.dataSource) {   /// TODO: DataSource from parantComponents
    //         console.log('dataSource from parentComponent ' + nextProps.modelName)
    //         console.log(' data: ' + JSON.stringify(nextProps.dataSource))

    //         return {
    //             model: propsModel, modelData: nextProps.dataSource, modelStore: store
    //         };
    //     }
    // }

    async componentDidMount() {
        const { modelName, Id } = this.props.match.params
        await this.fetchData(modelName, Id || null)
    }

    async fetchData(modelName, Id) {
        let userData = await getUser()
        let admin = await hasRole('ADMIN')
        this.setState({ currentUser: userData.data || null })
        this.setState({ isAdmin: admin })
        //console.log(' isAdmin ', JSON.stringify(userData))

        let formName = null
        // loading Data
        
        // by ID
        if (Id) {
            const result = await apiService.findOne(modelName, Id)
            //console.log(' result: ' + JSON.stringify(result.data.data))
            if (result.data) {
                console.log(' Loaded Data from API ' + modelName)
                // save form name
                if (result.data['shownForm']) 
                    this.setState({ refFormId: result.data['shownForm']._id })
                
                    this.setState({ modelData: result.data, isAdded: true, _id: Id })
                this.setState({ ...this.state, model: this.model }) // modelStore: store
                console.log(' result username: ' + JSON.stringify(result.data['username']))
                console.log(' FFORM DATA ', JSON.stringify(result.data))

                if (result.data) {
                    let dataForm = {}
                    
                    Object.entries(result.data).map(([key, value]) => {
                        if ( typeof value !== 'object' && typeof value === 'boolean') {
                            // console.log(' key ', key, ' type', typeof value, ' value ', value )
                            dataForm[key] = value
                        }
                    })
        
                    this.setState({ formData: dataForm })
                }

                // Loading Assets files
                if (result.assets) {
                    // Show to user the SelectedFiles changes
                    let newFiles = []
                    result.assets.map(file => {
                        const newFile = process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + file.fileName
                        newFiles.push(newFile)
                    })
                    this.setState({ ...this.state, selectedFiles : newFiles })
                }
            }
            else console.log(' API loading error ')
        }

        await this.fetchCollectionData()

        this.setState({ submitButtonText: this.state.isAdded ? 'Хадгалах' : 'Шинээр нэмэх' })
        //console.log(' STATES ' + JSON.stringify(this.state.collections, getCircularReplacer()))
        this.setState({isLoading: false})
        //console.log(' after model :::: ' + JSON.stringify(this.state.formData))
    }

    async fetchCollectionData() {
        // Loading Collections Data
        // let collections = {}
        await Promise.all(
            this.model.attributes.map(async attrib => {
            if (attrib.type === 'ref') {
                //console.log(' REF ' + attr.collectionName)
                // Fetch Collection Data
                const newCollection = await getData(attrib.collectionName, this.state.currentUser)
                const newColl = { ...this.state.collections, [attrib.collectionName] : newCollection };
                this.setState({ collections: newColl }) // TODO: improve this
            }
            else if (attrib.type === 'refForm') {
                console.log(' refForm ' + this.state.refFormId)
                // Fetch Collection Data
                const newCollection = await getFormData(this.state.refFormId, this.state.currentUser)
                const newColl = { ...this.state.collections, [attrib.collectionName] : newCollection };
                this.setState({ collections: newColl }) // TODO: improve this
            }
        }))
    }

    render() {
        try {
            const { attributes } = this.model || null
            const { isLoading, isAdmin, showAlertDelete, showToast } = this.state
            return (
                <>
                {
                    isLoading ?
                        <IonLoading isOpen={isLoading} />
                    :
                        <form onSubmit={this.onFormSubmit} id="edit-forms">
                        <MyToast 
                            isOpen={ showToast !== '' ? true : false } 
                            message={showToast}
                            onDidDismiss={() => this.setState({ showToast: ''})}
                        />
                        <MainCard title={this.renderTitle()}>
                            <p>* тэмдэглээтэй талбаруудыг заавал бөглөнө.</p>
                            <Grid container spacing={2} alignItems="center">

                                {attributes && attributes.map(attr => {
                                    return this.renderComponent(attr)
                                })}
                            
                                <Grid item xs={12}>

                                </Grid>
                                
                                <Grid item xs={12}>
                                    {
                                        isAdmin && 
                                        <Button
                                            id="delete"
                                            sx={{ my: 3, ml: 1 }} 
                                            variant="outlined"
                                            onClick={()=> this.setState({ showAlertDelete: true }) } >
                                                Устгах
                                        </Button>
                                    }
                                    <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit">
                                        {this.state.submitButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </MainCard>

                        <IonAlert
                            isOpen={this.state.showAlertDelete}
                            header={"Мэдээллийг устгах уу?"}
                            buttons={[
                            'Болих',
                            {
                                text: 'Тийм, устгая',
                                handler: async (e) => {
                                    await this.onFormRemove(e)
                                }
                            }
                            ]}
                            onDidDismiss={() => this.setState({ showAlertDelete: false})}
                        />
                        </form>
                }
                </>
                )
            } catch(e) {
                return ErrorComponent(e)
            }
    }

    onSelectedFilesChanged(e) {
        //this.setState({ selectedFiles: e.value.name });
    }

    async onFormSubmit(e) {
        //let result = e.validationGroup.validate();
        e.preventDefault()
        //if (result.isValid)
            try {
                const { formData, currentUser, _id, isAdded } = this.state
                let res = null
                let postData = formData
                
                if ( !isAdded ) {
                    postData['createdUser'] = currentUser._id
                    // if (currentUser.company)
                    //     postData['tenantId'] = currentUser.company._id
                    res = await apiService.insert(this.props.modelName, postData)
                }
                else
                    res = await apiService.patch(this.props.modelName, _id, postData)
                
                if (res.status.success) {
                    //console.log('data : ' + JSON.stringify(res.items))
                    this.setState( { _id: res.items._id, submitButtonText: 'Засварлах', isAdded: true } )
                    this.setState({ showToast: 'Мэдээллийг амжилттай хадгаллаа... '})
                }
                else this.setState({ showToast: 'Уучлаарай, мэдээллийг хадгалахад алдаа гарлаа'})
            } catch (error) {
                ErrorComponent(error)
                this.setState({ showToast: 'Error: ' + error})
            }
        //e.preventDefault();
    }

    async onFormRemove(e) {
        try {
            let res = null
            if ( this.props.match.params.Id ) {
                res = await apiService.remove(this.props.modelName, this.props.match.params.Id)
            
                if (res.status.success) {
                    this.setState({ showToast: 'Мэдээллийг устгалаа ... '})

                    this.props.history.goBack() //('/admin/'+ this.props.modelName +'/list')
                }
                else this.setState({ showToast: 'Уучлаарай, мэдээллийг устгахад алдаа гарлаа'})
            }
        } catch (error) {
            this.setState({ showToast: 'Error'})
            ErrorComponent(error)
        }
    }

    async onFileUploaded(e) {
        // After file uploaded
        const { name, mimetype } = e
        const result = await apiService.insert('asset', { fileName: name, fileType: mimetype, mimeType: mimetype })
        console.log(' asset upload ' + JSON.stringify(result))
        if (result.items) {
            // Patch new Asset file into Entity
            const newAssetId = result.items._id
            console.log('asset post result : ' + newAssetId)
            let key = this.state._id
            let postData = { asset: newAssetId }

            const result2 = await apiService.attach(this.props.modelName, key, postData )
            if (result2.items) {
                // adding Uploaded item to selectedFiles
                const newFiles = this.state.selectedFiles
                newFiles.push(process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + name)
                this.setState({ ...this.state, selectedFiles : newFiles })
                console.log(' selectedFiles : ' + JSON.stringify(this.state.selectedFiles) )

                this.setState({ showToast: 'Зурган файл амжилттай хуулагдлаа '})
            }
            else this.setState({ showToast: 'Error'})
            
        }
        else {
            this.setState({ showToast: 'Уучлаарай, файлыг сервер рүү хуулахад алдаа гарлаа'})
        }
    }

    renderTitle() {
        const title = this.props.title ? this.props.title : this.props.modelName
        return (<h4>{title} {this.state.isAdded ? 'засах' : 'шинээр үүсгэх'}</h4>)
    }

    renderComponent(attr) {
        if (attr.private) // not render Private Fields
            return ''

        switch(attr.editor) {
            case 'TextBox':
            default:
                return this.renderTextBox(attr);
            case 'TextArea': 
                return this.renderTextArea(attr)
            case 'TagBox': 
                return this.renderTagBox(attr)
            case 'CheckBox': 
                return this.renderCheckBox(attr)
            case 'RadioGroup':
                return this.renderRadioGroup(attr)
            case 'NumberBox': 
                return this.renderNumberBox(attr)
            case 'FileUpload': 
                return this.renderFileUpload(attr)
            case 'Lookup':
                return this.renderLookup(attr)
            case 'DateBox':
                return this.renderDateBox(attr)
            case 'SelectBox': 
                return this.renderSelectBox(attr)
            case 'HtmlEditor': 
                return this.renderHtmlEditor(attr)
        }
    }
    
    renderTextBox(att) {
        return (
            <Grid item xs={12} sm={6} key={att.name}>
                <Description att={att} />
                {/* <InputLabel>{att.label}</InputLabel> */}
                    {/* <TextBox defaultValue={this.getFieldValue(att)}
                        showClearButton={true} 
                        stylingMode="filled" 
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг бөглөнө үү`} />
                            </Validator>
                            : ''
                        }
                    </TextBox> */}
                    <TextField
                        id={att.name}
                        name={att.name}
                        label={att.label + (att.required ? ' *' :'')}
                        defaultValue={this.getFieldValue(att)}
                        onChange={(e) => this.valueChanged(att.name, e)}
                        // error={formik.touched.clanName && Boolean(formik.errors.clanName)}
                        // helperText={formik.touched.clanName && formik.errors.clanName}
                        fullWidth
                        //autoComplete="clan-name"
                    />
            </Grid>
        )
    }
    
    renderRadioGroup(att) {
        return (
            <Grid item xs={12} sm={6} key={att.name}>
                <InputLabel>{att.label}</InputLabel>
                <Description att={att} />
                <FormControl component="fieldset">
                    <RadioGroup
                        id={att.name}
                        name={att.name}
                        row
                        aria-label={att.label + (att.required ? ' *' :'')}
                        defaultValue={this.getFieldValue(att)}
                        onChange={(e) => {
                            this.valueChanged(att.name, e);
                            // const { formData } = this.state;
                            // formData[att.name] = e.target.value;
                            // this.setState({ formData });
                            //console.log(' radiogroup ', e.target.value, JSON.stringify(formData[att.name]))
                        }}
                    >
                        <FormControlLabel value={"true"} control={<Radio />} label="Тийм" />
                        <FormControlLabel value={"false"} control={<Radio />} label="Үгүй" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        )
    }

    renderTextArea(att) {
        return (
            <Grid item xs={12} sm={6} key={att.name}>
                <Description att={att} />
                {/* <InputLabel>{att.label}</InputLabel>
                    <TextArea stylingMode="filled"
                        defaultValue={this.getFieldValue(att)}
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                    >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг бөглөнө үү`} />
                            </Validator>
                            : ''
                        }
                    </TextArea> */}
                    <TextField
                        id={att.name}
                        name={att.name}
                        label={att.label + (att.required ? ' *' :'')}
                        defaultValue={this.getFieldValue(att)}
                        onChange={(e) => this.valueChanged(att.name, e)}
                        // error={formik.touched.clanName && Boolean(formik.errors.clanName)}
                        // helperText={formik.touched.clanName && formik.errors.clanName}
                        fullWidth
                        multiline={true}
                        rows={3}
                        //autoComplete="clan-name"
                    />
            </Grid>
        )
    }

    renderCheckBox(att) {
        return (
            <Grid item xs={12} sm={6} key={att.name}>
                <InputLabel>{att.label} {att.required ? ' *' :''} {this.getFieldValue(att)}</InputLabel>
                <Description att={att} />
                <FormControlLabel
                    control={
                    <Checkbox 
                        color="secondary"
                        id={att.name} 
                        name={att.name}
                        checked={this.state.formData[att.name] ? true : false}
                        onChange={() => {
                            const { formData } = this.state;
                            if (formData[att.name])
                                formData[att.name] = !formData[att.name];
                            else
                                formData[att.name] = true;
                            this.setState({ formData });
                            console.log(' checkbox ', JSON.stringify(formData[att.name]))
                        }}
                        defaultValue={this.getFieldValue(att)}
                        //value="true" 
                    />
                    }
                    label="Тийм"
                />
            </Grid>
        )
    }

    renderNumberBox(att) {
        return (
            <Grid item xs={12} sm={6} key={att.name}>
                <Description att={att} />
                {/* <InputLabel>{att.label}</InputLabel>
                    <NumberBox defaultValue={this.getFieldValue(att)} 
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                    >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </NumberBox> */}
                <TextField
                    id={att.name}
                    name={att.name}
                    label={att.label + (att.required ? ' *' :'')}
                    defaultValue={this.getFieldValue(att)}
                    onChange={(e) => this.valueChanged(att.name, e, 'number')}
                    // error={formik.touched.clanName && Boolean(formik.errors.clanName)}
                    // helperText={formik.touched.clanName && formik.errors.clanName}
                    fullWidth
                    inputProps={{
                        maxLength: 13,
                        step: "1"
                    }}
                    //autoComplete="clan-name"
                    type='number'
                />
            </Grid>
        )
    }

    renderLookup(attr) {
        let { collections } = this.state
        //console.log(' lookup items ' + JSON.stringify(this.getFieldCollectionValue(attr)))

        return (
            <Grid item xs={12} sm={6} key={attr.name}>
                {/* <InputLabel>{attr.label}</InputLabel>
                    <Lookup
                        defaultValue={this.getFieldCollectionValue(attr)} 
                        items={collections[attr.collectionName]}
                        //dataSource={this.getLookupDataSource(att.collectionName)}
                        searchEnabled={true}
                        searchExpr={['name']}
                        displayExpr={getDisplayFieldValue(attr.collectionName)}
                        valueExpr="_id"
                        stylingMode="filled"
                        onValueChanged={(e) => this.valueChanged(attr.name, e)}
                    >
                        {
                            attr.required ?
                            <Validator>
                                <RequiredRule message={`${attr.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </Lookup>
                <Button icon="remove"
                        onClick={async () => {
                            await this.resetCollectionField(attr.name)
                        }} /> */}
                <Autocomplete
                    id={attr.name}
                    name={attr.name}
                    disablePortal
                    options={collections[attr.collectionName]}
                    defaultValue={this.getFieldCollectionValue(attr)}
                    renderInput={(params) => <TextField variant="outlined" {...params} label={attr.label} fullWidth />}
                    //renderOption={option => option.name}
                    //getOptionLabel={option => option[getDisplayFieldValue(attr.collectionName)]}
                    getOptionLabel={ option => objectRender(attr.collectionName, option) }
                    getOptionSelected={(option, value) => option._id === value._id}
                    onChange={(e, value) => {//this.valueChanged(attr.name, value)}
                    //     console.log('do the types match?', typeof value === typeof formik.values.occuption.id);
                    //     console.log('do the objects match?', value === formik.values.occuption.id);
                    //     console.log('the objects in question', value, formik.values.occuption.id);
                    //     console.log("                  ");
                    //    formik.setFieldValue("occuption", value);
                        const { formData } = this.state;
                        formData[attr.name] = value._id;
                        this.setState({ formData });

                        const { modelData } = this.state;
                        modelData[attr.name] = value;
                        this.setState({ modelData });

                        console.log(' updated lookup ' + JSON.stringify(this.state.formData))
                    }}
                />
            </Grid>
        )
    }
    
    renderDateBox(att) {
        const fieldVal = this.getFieldValue(att) || null
        const thisDatetime = new Date(fieldVal)

        return (
            <Grid item xs={12} sm={6} key={att.name}>
                {/* <InputLabel>{att.label}</InputLabel>
                    <DateBox
                        type="datetime"
                        displayFormat="yyyy-MMM-dd HH:mm"
                        showClearButton={true}
                        stylingMode="filled"
                        defaultValue={thisDatetime}
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </DateBox> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            indicatorColor="primary"
                            renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                            label={att.label + (att.required ? ' *' :'')}
                            value={thisDatetime}
                            onChange={(e) => {
                                //this.valueChanged(att.name, e)
                                const { formData } = this.state;
                                formData[att.name] = e;
                                this.setState({ formData });
                            }}
                            sx={{
                                '& .MuiTabs-flexContainer .MuiTabs-indicator': {
                                    bgcolor: 'primary.main'
                                }
                            }}
                        />
                    </LocalizationProvider>
            </Grid>
        )
    }

    renderSelectBox(attr) {
        const { collections } = this.state
        //console.log('  selectbox value ' + JSON.stringify(collections[attr.collectionName]))
        return (
            <Grid item xs={12} sm={6} key={attr.name}>
                <InputLabel>{attr.label + (attr.required ? ' *' :'')}</InputLabel>
                {/* <InputLabel>{attr.label}</InputLabel>
                    <SelectBox 
                        defaultValue={this.getFieldCollectionValue(attr)} 
                        items={collections[attr.collectionName]}
                        displayExpr={getDisplayFieldValue(attr.collectionName)}
                        valueExpr='_id'
                        onValueChanged={(e) => this.valueChanged(attr.name, e)}
                    />
                <Button icon="remove"
                        onClick={async () => {
                            await this.resetCollectionField(attr.name)
                        }} /> */}
                <Autocomplete
                    id={attr.name}
                    name={attr.name}
                    disablePortal
                    options={collections[attr.collectionName]}
                    defaultValue={this.getFieldCollectionValue(attr)}
                    renderInput={(params) => <TextField variant="outlined" {...params} label={attr.label} fullWidth />}
                    renderOption={option => <>{option[getDisplayFieldValue(attr.collectionName)]}</>}
                    //getOptionLabel={option => getDisplayFieldValue(attr.collectionName)}
                    getOptionLabel={ option => objectRender(attr.collectionName, option) }
                    //getOptionSelected={(option, value) => option._id === value._id}
                    // onChange={(e, value) => {
                    //     console.log('do the types match?', typeof value === typeof formik.values.occuption.id);
                    //     console.log('do the objects match?', value === formik.values.occuption.id);
                    //     console.log('the objects in question', value, formik.values.occuption.id);
                    //     console.log("                  ");
                    //     formik.setFieldValue("occuption", value);
                    // }}
                />
            </Grid>
        )
    }

    renderTagBox(attr) {
        const { collections } = this.state
        //console.log('  TagBox items ' + JSON.stringify(collections[attr.collectionName]))
        //console.log('  TagBox value ' + JSON.stringify(this.getFieldMultipleCollectionValue(attr)))
        if ( collections[attr.collectionName] )
            return (
                <Grid item xs={12} sm={6} key={attr.name}>
                {/* <InputLabel>{attr.label}</InputLabel>
                        <TagBox 
                            defaultValue={this.getFieldMultipleCollectionValue(attr)} 
                            items={collections[attr.collectionName]}
                            displayExpr={getDisplayFieldValue(attr.collectionName)} // "name"
                            valueExpr='_id'
                            applyValueMode="useButtons"
                            stylingMode="filled"
                            hideSelectedItems={true}
                            onValueChanged={(e) => this.valueChanged(attr.name, e)}
                        />
                    <Button icon="remove"
                        onClick={async () => {
                            await this.resetCollectionField(attr.name)
                        }} /> */}
                    <Autocomplete
                        multiple
                        id={attr.name}
                        name={attr.name}
                        disablePortal
                        options={collections[attr.collectionName]}
                        defaultValue={this.getFieldMultipleCollectionValue(attr)}
                        renderInput={(params) => <TextField variant="outlined" {...params} label={attr.label} fullWidth />}
                        //renderOption={option => option[getDisplayFieldValue(attr.collectionName)]}
                        getOptionLabel={ option => objectRender(attr.collectionName, option) }
                        //getOptionSelected={(option, value) => option._id === value._id}
                        onChange={(e, value) => {
                            //console.log('  seletected ' + JSON.stringify(value))
                            const { formData } = this.state;
                            formData[attr.name] = value.map(item => item._id);
                            this.setState({ formData });

                            const { modelData } = this.state;
                            modelData[attr.name] = value;
                            this.setState({ modelData });

                            console.log(' updated tagbox ' + JSON.stringify(this.state.formData))
                        }}
                    />
                </Grid>
            )
        else return []
    }

    renderHtmlEditor(attr) {
        return ''
    }

    renderFileUpload(att) {
        return (
            <Grid item xs={12} sm={6} key={att.name}>
                <InputLabel>Зураг болон видео оруулах / Upload:</InputLabel>
                <UploadFiles onFileUploaded={(e) => this.onFileUploaded(e)} />

                {/* <FileUploader name="assetFile" multiple={true} accept={"image/*, video/*"} uploadMode={"instantly"}
                    uploadUrl={`${process.env.REACT_APP_BACKEND_API}/api/asset/upload`} 
                    onValueChanged={this.onSelectedFilesChanged}
                    onUploaded={this.onFileUploaded}
                    disabled={!this.state.isAdded}
                        />
                <div className="content" style={{ display: this.state.selectedFiles.length > 0 ? 'block' : 'none' }}>
                    <div>
                    <strong>Upload хийгдсэн файлууд</strong>
                        {
                        <Gallery // TODO: replace with renderAssets function 
                            id="gallery"
                            items={this.state.selectedFiles}
                            height={200}
                            slideshowDelay={2000}
                            loop={false}
                            showNavButtons={true}
                            showIndicator={true} 
                        />
                        // this.state.selectedFiles.map((file, i) => {
                        // return <div className="selected-item" key={i}>
                        //     <span><li><img height={60} src={`${process.env.REACT_APP_BACKEND_API}/uploads/asset/${file}`} /></li></span>
                        //     <span>{`Size ${file.size}`}<br /></span>
                        //     <span>{`Type ${file.size}`}<br /></span>
                        //     <span>{`Last Modified Date: ${file.lastModifiedDate}`}</span>
                        // </div>;
                        // })
                    }
                    </div>
                </div> */}

            </Grid>
        )
    }

    // async valueChanged(target, e) {
    //     //console.log(' e ' + JSON.stringify(e, getCircularReplacer()))
    //     //const previousValue = e.previousValue;
    //     const newValue = e.target.value;
    //     // Updating state
    //     const formVal = { ...this.state.formData, [target] : newValue };
    //     this.setState( { formData: formVal })
    //     // Event handling commands go here
    //     console.log('updated field: ' + target + ' : ' + JSON.stringify(this.state.formData))
        
    //     // Updating current editor
    //     const modelVal = { ...this.state.modelData, [target] : newValue };
    //     this.setState( { modelData: modelVal })

    //     // linked Lookup for "form" field
    //     if (target === 'shownForm') {
    //         console.log('linked field: ' + newValue)
    //         this.setState({ refFormId: newValue })
    //         await this.fetchCollectionData()
    //     }
    // }

    async valueChanged(field, { target }, fieldType = 'string') {
        const { formData } = this.state;
        formData[target.name] = fieldType === 'number' ? parseFloat(target.value) : target.value;
        this.setState({ formData });
        //this.handleValidation(target);

        if (field === 'shownForm') {
            console.log('linked field: ' + target.value)
            this.setState({ refFormId: target.value })
            await this.fetchCollectionData()
        }

        console.log('updated field: ' + field + '|' + target.name + ' : ' + JSON.stringify(this.state.formData))
    }

    getFieldValue(field) {
        const { modelData, isLoading } = this.state
        if (modelData)
            if ( isNotEmpty(modelData[field.name]) )
                return modelData[field.name]
    
        return null
    }

    getFieldCollectionValue(field) {
        const { modelData, isLoading } = this.state
        if (modelData && !isLoading)
            if (isNotEmpty(modelData[field.name]) )
                //return modelData[field.name]._id
                return modelData[field.name]
        //return '{Empty}'
        return null
    }

    getFieldMultipleCollectionValue(field) {
        const { modelData, isLoading } = this.state
        let IDs = []
        if (modelData && !isLoading)
            if (isNotEmpty(modelData[field.name]) )
                modelData[field.name].map((curr) => {
                    IDs.push(curr)
                })
            return IDs
        //return '{Empty}'
        return null
    }

    // async resetCollectionField(fieldName) {
    //     const { modelName, Id } = this.props.match.params
    //     this.setState({ isLoading: true })
    //     let postData = {}
    //     postData[fieldName] = {}
        
    //     //notify('postData ' + JSON.stringify( postData))
    //     const result = await apiService.update(modelName, Id, postData)
    //     if (result.status.success)
    //         notify({
    //         message: 'Мэдээллийг устгалаа... ',
    //         position: {
    //         my: 'center middle',
    //         at: 'center middle'
    //         }
    //         }, 'success', 3000)
    //     else
    //         notify({
    //         message: 'Уучлаарай, мэдээллийг устгахад алдаа гарлаа',
    //         position: {
    //             my: 'center middle',
    //             at: 'center middle'
    //         }
    //         }, 'error2', 3000)
        
    //     await this.fetchData(modelName, Id)
    //     this.setState({ isLoading: true })
    // }
}

async function getData(collectionName, currentUser) {
    //let store = backendStore({ entityName: collectionName });

    try {
        //let result = await store.load()
        //let filter = 'tenantId=' + currentUser.company._id
        // let filter = ''
        // if (collectionName === 'role' || collectionName === 'tags' || collectionName === 'tag')
        //     filter = ''
        
        let result = await apiService.find(collectionName)
        //let result = await apiService.findSearchBy(collectionName)
        //console.log(' items load ' + JSON.stringify(result.items))
        return result.items
    }
    catch { return [] }
}

async function getFormData(shownFormId, currentUser) {
    let collectionValues = []

    try {
        //const result = await apiService.findSearchBy('form', 'name=' + collectionName)
        //if (result.status.success && result.items.length) {
            //const formId = result.items[0]._id
            const result2 = await apiService.findSearchBy('formdata', 'form=' + shownFormId )

            if (result2.status.success && result2.items.length) {
                result2.items.map(itemFormData => {
                    const formDataVal = JSON.parse(itemFormData.data)
                    collectionValues.push({ _id: itemFormData._id, name: formDataVal.name})
                })
            }
        //}
        //console.log(' items load ' + JSON.stringify(result.data))
        return collectionValues
    }
    catch { return [] }
}

export default withRouter(PageRenderer)