import { useRef, useState } from "react"
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MainCard from "ui-component/cards/MainCard"
import MyToast from 'components/toast'
import {
    CardContent,
    TextField,
    Box,
    Button,
    InputAdornment, 
    IconButton 
} from '@material-ui/core'
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IonLoading } from "@ionic/react"
import apiService from "api/apiService";

const ResetPassword = () => {
    const routeParams = useParams();
    const history = useHistory();
    const passwordRef = useRef();
    const rpasswordRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState();
    const [validError, setValidError] = useState();
    const [showPassword, setShowPassword] = useState(false);
    console.log('userId::: ', routeParams);

    const resetPassword = async () => {

        console.log('password: ', passwordRef.current.value);
        console.log('repeat password: ', rpasswordRef.current.value);

        if(passwordRef.current.value && rpasswordRef.current.value){
            if(passwordRef.current.value !== rpasswordRef.current.value){
                setValidError('Passwords do not match')
            } else {
                setIsLoading(true);
                const res = await apiService.patch1('user', 'changePassword', routeParams.userId, { newPassword: passwordRef.current.value })
                if (res && res.status && res.status.success) {
                    setShowToast(true)
                    setToastMessage('Хэрэглэгчийн нууц үг амжилттай солигдлоо.')
                    // history.goBack();
                    setIsLoading(false);
                    // setTimeout(function() { //Start the timer
                    //     history.push('/login')
                    // }, 500)
                } else {
                    setIsLoading(false);
                    setShowToast(true)
                    setToastMessage(`Нууц үг солиход алдаа гарлаа: ${res.error}`)
                }
            }    
        } else {
            setValidError('Нууц үг оруулна уу')
        }

        
    }

    console.log('valid error: ', validError)

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> : 
        <MainCard title={'Нууц үг солих'}>
            <MyToast 
                isOpen={ showToast } 
                message={toastMessage}
                onDidDismiss={() => setShowToast(false)}
            />

            <CardContent>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-password-input"
                        label="Шинэ нууц үг"
                        type={showPassword ? `text`: 'password'}
                        autoComplete="current-password"
                        fullWidth
                        inputRef={passwordRef}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-password-input"
                        label="Нууц үг давтах"
                        type={showPassword ? `text` : `password`}
                        autoComplete="repeat-password"
                        fullWidth
                        inputRef={rpasswordRef}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                    />
                </div>
                {validError && <div style={{marginLeft: '8px', fontSize: '12px', color: 'red'}}>
                    <span>{validError}</span>
                </div>}
                <div>
                    <Button
                        onClick={() => resetPassword()}
                        sx={{margin: '8px'}}
                        variant="contained" color="primary">
                        Хадгалах
                    </Button>
                </div>
            </Box>
            </CardContent>
        </MainCard>
    )
}

export default ResetPassword