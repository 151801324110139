import React from 'react';
import { withRouter } from 'react-router-dom';
import PageList from '../render/pageRender.list'
import ToolbarListPage from '../components/toolbar/toolbarListPage'

const ListPage = ({match}) => {
  const { modelName } = match.params || ''

  return (
    <>
      {match.params && modelName && (
      <>
        <ToolbarListPage modelName={modelName} />
        <PageList modelName={modelName} title={modelName + " List"} />
      </>
      )}
    </>
  );
}

export default withRouter(ListPage);
