import React from 'react';
import { useSelector } from 'react-redux';

// import RTLLayout from './ui-component/RTLLayout';
import Snackbar from './ui-component/extended/Snackbar';

// auth provider
import { FirebaseProvider } from './contexts/FirebaseContext';
// import {JWTProvider} from '../contexts/JWTContext';
// import {Auth0Provider} from '../contexts/Auth0Context';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
//import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';

// devextreme
import { HashRouter as Router } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import Content from './Content';
import NotAuthenticatedContent from './NotAuthenticatedContent';
import { createBrowserHistory } from 'history';

// import './dx-styles.scss';
// import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';

const browserHistory = createBrowserHistory();

//-----------------------|| APP ||-----------------------//

const App = () => {
    const { user, loading } = useAuth();
    
    if (loading) {
        return <LoadPanel visible={true} />;
    }
    
    if (user) {
        console.log(' user logged ')
        return <Content />;
    }
    
    return <NotAuthenticatedContent />; // <Routes />
};

export default function () {
    const customization = useSelector((state) => state.customization);
    //const screenSizeClass = useScreenSizeClass();
  
    return (
      <Router history={browserHistory}>
        <AuthProvider>
            <NavigationProvider>
            
            <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        {/* <NotAuthenticatedContent /> */}
                        
                        {/* <div className={`app ${screenSizeClass}`}> */}
                        <App />
                        {/* <Routes /> */}
                        {/* </div> */}

                        <Snackbar />
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
            </StyledEngineProvider>

            </NavigationProvider>
        </AuthProvider>
      </Router>
    );
}
