// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconCloudFog } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,
    IconCloudFog,
    // IconListDetails
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'rule',
            title: 'Үйлчилгээний нөхцөл',
            type: 'item',
            url: '/terms',
            icon: icons['IconDeviceAnalytics'],
            breadcrumbs: false
        },
        {
            id: 'company',
            title: 'Компаниуд',
            type: 'item',
            url: '/admin/company/list',
            icon: icons['IconCloudFog'],
            breadcrumbs: false
        },
        {
            id: 'versions',
            title: 'Апп хувилбар',
            type: 'item',
            url: '/versions',
            icon: icons['IconCloudFog'],
            breadcrumbs: false
        },
        // {
        //     id: 'forms',
        //     title: <FormattedMessage id="forms" />,
        //     type: 'item',
        //     url: '/forms',
        //     icon: icons['IconDeviceAnalytics'],
        //     breadcrumbs: false
        // }
    ]
};
