import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from 'devextreme-react/button'
import { Tooltip } from 'devextreme-react/tooltip'
import { Popover } from 'devextreme-react/popover'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup'
import TextBox from 'devextreme-react/text-box'
import NumberBox from 'devextreme-react/number-box'
import notify from 'devextreme/ui/notify'
import ValidationSummary from 'devextreme-react/validation-summary'
import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator'
import { getUser } from '../../api/auth'
import ErrorComponent from '../../components/error'
import { renderTextBox, renderNumberBox } from './render'
import apiService from '../../api/apiService'

class App extends React.Component {
    pinButtonOptions = null
    sendAgainButtonOptions = null
    closeButtonOptions = null
    
    constructor(props) {
        super(props)
  
        this.state = {
            isLoading: true,
            formData: {},
            user: null,
            walletAddress: "rEb8TK3gBgk5auZkwc6sHnwrGVJH8DuaLh",
            walletTag: "104100523",
            withdrawTxId: null,
            pinCode: '',
            popupVisible: false,
            positionOf: ''
        }
  
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.valueChanged = this.valueChanged.bind(this)
        this.renderTextBox = this.renderTextBox.bind(this)
        // this.showInfo = this.showInfo.bind(this)
        // this.hideInfo = this.hideInfo.bind(this)
        this.confirmPin = this.confirmPin.bind(this)

        this.pinButtonOptions = {
            icon: 'save',
            text: 'Баталгаажуулах',
            onClick: (e) => this.confirmPin(e)
        }

        this.sendAgainButtonOptions = {
            icon: 'email',
            text: 'Дахин илгээх',
            //onClick: () => {}
        }

        this.closeButtonOptions = {
            text: 'Хаах',
            onClick: () => this.setState({ popupVisible: false })
        }
    }

    async componentDidMount() {
        const userData = await getUser()
        //console.log(' form user' + JSON.stringify(userData))
        this.setState({ user: userData.data, isLoading: false })
    }

    render() {
        const { walletAddress, walletTag } = this.state

        return (
            <div className="card">
            <div className="card-body">
                {/* <h5 className="card-title">Зээлийн тооцоолуур</h5> */}
                <div className="settings-profile">

                    <div className="dx-fieldset">

                            {this.renderTextBox({ name: 'toAddress', label: 'Хүлээн авагчийн XRP хаяг', required: true })}
                            {this.renderTextBox({ name: 'tag', label: 'Тааг', required: false })}
                            {this.renderNumberBox({ name: 'amount', label: 'Шилжүүлэх дүн', required: true })}
                            
                            <div className="dx-field">
                                <div className="dx-field-value">
                                    <ValidationSummary id="summary"></ValidationSummary>
                                    <Button
                                        id="submit"
                                        text={'Шилжүүлэх'}
                                        icon="save"
                                        type="default"
                                        useSubmitBehavior={true}
                                        stylingMode="contained"
                                        onClick={this.onFormSubmit} />
                                </div>
                            </div>
                        </div>
                </div>
                <Popup
                    visible={this.state.popupVisible}
                    onHiding={() => this.setState({ popupVisible: false })}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Зарлагыг баталгаажуулах"
                    container=".dx-viewport"
                    width={500}
                    height={280}
                >
                <Position
                    at="middle"
                    my="center"
                    of={this.state.positionOf}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={this.pinButtonOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={this.sendAgainButtonOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={this.closeButtonOptions}
                />
                <p>
                    <span>Гар утас руу илгээсэн 6-н оронтой кодыг дараах талбарт оруулна уу</span>
                </p>
                Баталгаажуулах код:
                <NumberBox 
                    //defaultValue={this.getFieldValue(att)} 
                    stylingMode="filled" 
                    width={100}
                    onValueChanged={(e) => this.setState( { pinCode: e.value }) }
                >
                </NumberBox>
                </Popup>
            </div>
        </div>
        )
    }

    valueChanged(target, e) {
        const newValue = e.value;
        // Updating state
        const formVal = { ...this.state.formData, [target] : newValue };
        this.setState( { formData: formVal })
        // Event handling commands go here
        //console.log('updated field: ' + JSON.stringify(this.state.formData))
    }

    async confirmPin(e) {
        const { withdrawTxId, pinCode } = this.state
        if (withdrawTxId && pinCode) {
            const postBody = { pinCode }
            const res = await apiService.patch('withdrawTx', withdrawTxId, postBody, 'confirmPin')
                    
            if (res.status.success) {
                console.log('withdraw Confirmed: ' + JSON.stringify(res))
                this.setState({ popupVisible: false })
            }
        }
    }

    async onFormSubmit(e) {
        //console.log(' form ' + JSON.stringify(e))
        let result = e.validationGroup.validate();
        if (result.isValid)
            try {
                const { toAddress, tag, amount } = this.state.formData
                if ( amount > 0 ) { 
                    // TODO: validating minimum amount
                    const txBody = {
                        toAddress,
                        description: tag,
                        amount,
                        fromAddress: this.props.wallet.address,
                        owner: this.state.user._id,
                        coin: this.props.wallet.coin._id
                    }

                    console.log(' post data ' + JSON.stringify(txBody))
                    const res = await apiService.insert('withdrawTx', txBody)
                
                    if (res.status.success && res.result._id) {
                        this.setState({ withdrawTxId: res.result._id })
                        console.log('withdraw : ' + JSON.stringify(res))
                        // notify({
                        //     message: 'Шилжүүлэг хийгдлээ, баталгаажуулалт хийгдтэл түр хүлээнэ үү... ',
                        //     position: {
                        //     my: 'center middle',
                        //     at: 'center middle'
                        //     }
                        // }, 'success', 3000);
                        this.setState({ popupVisible: true })
                    }
                    else notify({
                        message: 'Уучлаарай, шилжүүлэхэд алдаа гарлаа',
                        position: {
                            my: 'center middle',
                            at: 'center middle'
                        }
                    }, 'error2', 3000)
                }
                else 
                    notify({
                        message: 'Шилжүүлэх дүн 0 -ээс их байх ёстой',
                        position: {
                            my: 'center middle',
                            at: 'center middle'
                        }
                    }, 'error', 3000)
            } catch (error) {
                ErrorComponent(error)
                notify('Error: ' + error, 'error', 1000)
            }
        //e.preventDefault();
    }

    renderTextBox(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-value">
                    {att.label}
                    <TextBox 
                        //defaultValue={this.getFieldValue(att)} 
                        showClearButton={true} 
                        stylingMode="filled" 
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        width={250}
                        >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг бөглөнө үү`} />
                            </Validator>
                            : ''
                        }
                    </TextBox>
                </div>
            </div>
        )
    }

    renderNumberBox(att) {
        return (
            <div className="dx-field" key={att.name}>
                <div className="dx-field-value">
                {att.label}
                    <NumberBox 
                        //defaultValue={this.getFieldValue(att)} 
                        stylingMode="filled" 
                        width={250}
                        onValueChanged={(e) => this.valueChanged(att.name, e)}
                        >
                        {
                            att.required ?
                            <Validator>
                                <RequiredRule message={`${att.label} талбарыг сонгоно уу`} />
                            </Validator>
                            : ''
                        }
                    </NumberBox>
                </div>
            </div>
        )
    }
}

function showNotify(notificationTxt) {
    notify({
        message: notificationTxt,
        position: {
        my: 'top middle',
        at: 'top middle'
        }
    }, 'success', 2000);
}

function generatePin() {  
    return Math.floor(
      Math.random() * (100000 - 999999) + 100000
    )
}

export default withRouter(App)