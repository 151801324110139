import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IonLoading } from '@ionic/react';
import { datetimeFormat } from 'utils/helperFunction'
import apiService from "api/apiService";
import MainCard from 'ui-component/cards/MainCard';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

import { makeStyles } from '@material-ui/core/styles';
import {
    CardContent,
    CardActions,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Button,
    Divider
} from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

const CompanyList = () => {
    const classes = useStyles();
    const history = useHistory();
    const routerParams = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const response = await apiService.findSearchBy('company');
            if(response.items && response.items.length){
                setData(response.items);
            }
        } catch(e) {
            console.log('failed to fetch : ', e);
            return;
        } finally {
            setLoading(false)
        }

    }

    console.log('data::: ', data)
    dayjs.extend(utc)


    return (
        loading ? <IonLoading isOpen={loading} /> :
        <MainCard title={`IEO Арилжааны үнэ`} content={false}>
            <CardActions sx={{ justifyContent: 'flex-start' }}>
                <Button
                    onClick={() => history.push(`/admin/company/add`)}  
                    variant="contained" color="primary">
                    Нэмэх
                </Button>
            </CardActions>
            <Divider />
            <CardContent className={classes.projectTableCard} content={false}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Компаний нэр</TableCell>
                                <TableCell>Товч танилцуулга</TableCell>    
                                <TableCell>Үүсгэсэн огноо</TableCell>    
                                <TableCell>Үйлдэл</TableCell>    
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.map((row, index) => {
                                return (
                                    <TableRow hover key={index} sx={{backgroundColor: `${index % 2 ===0 ? `#f7fcff` : `#FFFFFF`}`}}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell>
                                            <Stack direction={'row'} spacing={1}>
                                                <Chip
                                                    onClick={() => history.push('/admin/company/edit/' + row._id)}
                                                    variant="outlined"
                                                    color={'default'}
                                                    label={'засах'}
                                                    size="small"
                                                />
                                            
                                                <Chip
                                                    onClick={() => history.push('/admin/company/view/' + row._id)}
                                                    variant="outlined" 
                                                    color={'default'}
                                                    label={'цааш'}
                                                    size="small"
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    )
}

export default CompanyList