import React, { useState, useEffect }from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@material-ui/core';

import axios from './../../../../utils/axios';
import apiService from './../../../../api/apiService'
import { datetimeFormat } from 'utils/helperFunction'

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from './../../../../assets/images/users/user-round.svg';

// style constant
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '330px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px'
        }
    },
    listAction: {
        top: '22px'
    },
    actionColor: {
        color: theme.palette.grey[500]
    },

    listItem: {
        padding: 0
    },
    sendIcon: {
        marginLeft: '8px',
        marginTop: '-3px'
    },
    listDivider: {
        marginTop: 0,
        marginBottom: 0
    },
    listChipError: {
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        height: '24px',
        padding: '0 6px',
        marginRight: '5px'
    },
    listChipWarning: {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
        height: '24px',
        padding: '0 6px'
    },
    listChipSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: '24px',
        padding: '0 6px'
    },
    listAvatarSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.success.main
    },
    listAvatarPrimary: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.primary.main
    },
    listContainer: {
        paddingLeft: '56px'
    },
    uploadCard: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    itemAction: {
        cursor: 'pointer',
        padding: '16px',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
        }
    }
}));

//-----------------------|| NOTIFICATION LIST ITEM ||-----------------------//

const AlertsList = () => {
    const classes = useStyles();
    let [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        try {
            //const response = await axios.get( process.env.REACT_APP_BACKEND_API + '/api/alert')
            const response = await apiService.find('alert')
            setData(response.items)
            //console.log(' dt ' + process.env.REACT_APP_BACKEND_API + JSON.stringify(data))
        }
        catch (e) {
            //console.log(`Failed to fetch data: ${e}`);
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    return (
        <List className={classes.navContainer}>
        {
            data.map(alert => {
                const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/' + 
                    (alert.createdUser ? alert.createdUser.avatarUrl.fileName : 'defaultAvatar.png')

                return (
                    <>
                    <div className={classes.itemAction}>
                        <ListItem alignItems="center" className={classes.listItem}>
                            <ListItemAvatar>
                                <Avatar alt='' src={picUrl} />
                            </ListItemAvatar>
                            <ListItemText primary={
                                <Typography variant="subtitle1">
                                {alert.createdUser ? alert.createdUser.firstname : ''}
                                </Typography>} />
                            <ListItemSecondaryAction className={classes.listAction}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                            {datetimeFormat(alert.createdAt)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Grid container direction="column" className={classes.listContainer}>
                            <Grid item xs={12} className={classes.paddingBottom}>
                                <Typography variant="subtitle2">{alert.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <Chip label="Уншаагүй" className={classes.listChipError} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider className={classes.listDivider} />
                    </>)
            })
        }
        
        </List>
    );
};

export default AlertsList;
