import React from 'react';
import { withRouter } from 'react-router-dom';
import AdminPageList from './render/pageRender.list'
import ToolbarListPage from 'components/toolbar/toolbarListPage'
import RenderList from './render/renderList'

// material-ui
import { Grid } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { gridSpacing } from 'store/constant';

const ListPage = ({match}) => {
  const { modelName } = match.params || ''
  // console.log('match: ', match.params)

  return (
    <>
      {match.params && modelName && (
        <>
        {/* <MainCard title={modelName}>
          <Grid container spacing={2} alignItems="center"> */}
          <ToolbarListPage modelName={modelName} routeUrl={'admin/' + modelName + '/add'} />
          <AdminPageList modelName={modelName} title={modelName + " жагсаалт"} routeUrl={'admin'} />
          {/* <RenderList modelName={modelName} title={modelName + " жагсаалт"} routeUrl={'admin'} /> */}
          {/* </Grid>
        </MainCard> */}
        </>
      )}
    </>
  );
}

export default withRouter(ListPage);
