import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from 'devextreme-react/button'
import DataGrid, { Column, Paging, Pager, Editing, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
import './index.scss';
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
import { getUser } from '../../api/auth'
import AccountAdd from './add'
import AccountList from './listAccount'
import CoinAccountAdd from './addCoin'
import CoinAccountList from './listCoin'
import IconRemove from '../../data/icons/remove-circle-outline.svg'

class App extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            user: null,
            isLoading: true,
            accountList: [],
            coinAccountList: [],
            popupVisible: false,
            selectedAccountType: 'bank'
        }

        this.showPopup.bind(this)
    }

    async componentDidMount() {
        const userData = await getUser()
        //console.log('loading user ' + JSON.stringify(userData.data))
        this.setState({ user: userData.data })

        const result = await apiService.find('bank');
        if (result.status.success && result.items.length) {
            //console.log('loading wallet ' + JSON.stringify(result.items))
            this.setState({ bankList: result.items })
        }

        const result2 = await apiService.findSearchBy('coin', 'ticker=MNT');
        if (result2.status.success && result2.items.length) {
            //console.log('loading wallet ' + JSON.stringify(result.items))
            this.setState({ coinList: result2.items })
        }

        await this.fetchData('bank')
    }

    async fetchData(accountType) {
        this.setState({ isLoading: true })

        const result = await apiService.findSearchBy('account', 'owner=' + this.state.user._id + ',accountType=' + accountType);
        if (result.status.success && result.items.length) {
            //console.log('loading wallet ' + JSON.stringify(result.items))
            if (accountType == 'bank')
                this.setState({ accountList: result.items })
            else
                this.setState({ coinAccountList: result.items })
        }
        this.setState({ selectedAccountType: accountType })

        this.setState({ isLoading: false })
    }

    render() {
        const { isLoading, accountList, coinAccountList, selectedAccountType } = this.state

        return (
            isLoading ? 
                <LoadIndicator visible={isLoading} />
                :
            <div className="settings mtb15">
            <div className="container-fluid">

                <Button
                    id="submit"
                    text={'Банкны данс'}
                    icon="save"
                    type="default"
                    useSubmitBehavior={true}
                    stylingMode="text"
                    onClick={() => this.fetchData('bank')} />
                
                <Button
                    id="submit"
                    text={'Койн хаягийн дэвтэр'}
                    icon="save"
                    type="default"
                    useSubmitBehavior={true}
                    stylingMode="text"
                    onClick={() => this.fetchData('coin')} />

                <Button
                    id="submit"
                    text={'Шинэ данс нэмэх'}
                    icon="save"
                    type="success"
                    useSubmitBehavior={true}
                    stylingMode="contained"
                    onClick={() => this.setState({ popupVisible: true })} /><br/><br/>

                {
                    selectedAccountType == 'bank' ? 
                        <>
                            <AccountAdd showPopup={this.showPopup} visible={this.state.popupVisible} />
                            <AccountList accountList={accountList} /> 
                        </>
                    :
                        <>
                            <CoinAccountAdd showPopup={this.showPopup} visible={this.state.popupVisible} />
                            <CoinAccountList coinAccountList={coinAccountList} />
                        </>
                }
            </div>
            </div>
        )
    }

    showPopup = (visible) => {
        this.setState({ popupVisible: visible })
    }
}

export default withRouter(App)