import React, { useState } from 'react';
import './profile.scss';
import { Form, SimpleItem, TabbedItem, Tab, ButtonItem, ButtonOptions, Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  CustomRule,
  StringLengthRule,
  AsyncRule } from 'devextreme-react/form';
import { Validator } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import 'devextreme-react/autocomplete';
import { getUser } from '../../api/auth';
import FormEditProfile from './formEditProfile'
import FormChangePassword from './formChangePassword'

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      user: null,
      isLoading: true
    }
  }

  async componentDidMount() {
    const userData = await getUser()
    //console.log(' form user' + JSON.stringify(userData))
    this.setState({ user: userData.data, isLoading: false })
  }

  render() {
    const { avatarUrl, department, roles } = this.state.user || ''
    const { isLoading, user } = this.state

    return (
      <>
      {
        isLoading ? <LoadIndicator visible={isLoading} />
        :
        <>
        <h2 className={'content-block'}>Профайл</h2>

        <div className={'content-block dx-card responsive-paddings'}>
          <div className={'form-avatar'}>
            {
              user ? 
                <img
                  alt={'Avatar'}
                  src={avatarUrl}
                />
              :
                'avatar'
            }
          </div>
          <div>
            <p><b>Харъяа алба / газар:</b> {department ? department.parentDepartment.name : ''}</p>
            <p><b>Хэлтэс:</b> {department ? department.name : ''}</p>
            <p><b>Роль:</b> {roles || ''}</p>
          </div>
        </div>

        <div className={'content-block dx-card responsive-paddings'}>
        <Form id="tabForm">  
            <TabbedItem colSpan={3}>
              
              <Tab title="Хувийн мэдээлэл" className="form-tab">
                <FormEditProfile dataSource={user} />
              </Tab>

              <Tab title="Нууц үг шинэчлэх" className="form-tab">
                  <FormChangePassword />
              </Tab>

              <Tab
                title="Data Protection Policy"
                tabRender={tabRender}
                render={tabContentRender}
                className="form-tab" />
              
            </TabbedItem>
        </Form>
        </div>
        </>
      }
      </>
    )
  }
}

function tabRender(data) {
  return (
      <p style={{color:'blue'}}>{ data.title }</p>
  );
}

function tabContentRender(data) {
  return (
      <p>
          <i>By filling out this form, you agree
          to the terms of the <a href='#'>Data Protection Policy</a></i>
      </p>
  );
}

export default App