import React from 'react';
import { withRouter } from 'react-router-dom';
import 'devextreme/data/odata/store';
import { Link, useHistory } from "react-router-dom";
import List from 'devextreme-react/list';
import DataGrid, { Paging, Pager, Editing, SearchPanel } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import 'whatwg-fetch';

import currentUser from '../utils/default-user'
import backendStore from '../api/backendStore'
import apiService from '../api/apiService'
import { getModel, getColumnsInList } from './helpers'
import { objectRender } from './renderFunctions'

import LoadIndicator from 'devextreme-react/load-indicator';
import ErrorComponent from '../components/error'

class PageRendererList extends React.Component {
    constructor(props) {
        super(props)

        this.formId = this.props.formId
        this.state = {
            model: null,
            columns: null,
            isLoading: true,
            modelStore: null,
            formDatas: [],
            title: ''
        }

        //this.rowRender = this.rowRender.bind(this)
        //this.changeRoute = this.changeRoute.bind(this)
        //this.renderEditButon = this.renderEditButon.bind(this)
    }

    static defaultProps = {
        routeUrl: 'page'
    }

    static getDerivedStateFromProps(nextProps) {
        return {
                formId: nextProps.formId
            };
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.formId !== this.props.formId) {
            this.setState({ isLoading: true })
            await this.fetchData(this.props.formId)
        }
    }

    // static getDerivedStateFromProps(nextProps) {
    //     const propsModel = getModel(nextProps.formId)
    //     const column = getColumnsInList(propsModel);
    //     let store = null
    //     // console.log('propsModel: ', propsModel)
    //     // console.log('column: ', column)

    //     // Loading from Parent
    //     if (nextProps.dataSource && nextProps.formId ) {
    //         store = nextProps.dataSource
    //         console.log('from parent ' + nextProps.formId)
    //     }
    //     // Loading from API service
    //     else {
    //         store = backendStore({entityName: nextProps.formId.toLowerCase()});
    //         console.log('from API ' + nextProps.formId)
    //     }

    //     return {
    //         model: propsModel, columns: column, modelStore: store, isLoading: false, routeUrl: nextProps.routeUrl
    //     };
    // }

    async componentDidMount() {
        await this.fetchData(this.props.formId)
    }

    componentWillUnmount() {
        this.setState({ isLoading: true, modelStore: null, model: null, columns: null })
    }

    async fetchData(formId) {
        //console.log(' fetchData ' + formId)
        if (formId) {
            let items = []
            //console.log(' formId ' + JSON.stringify(formId))
            const propsModel = await getModel(formId)
            //console.log('propsModel : ' + JSON.stringify(propsModel.fields))
            const column = getColumnsInList(propsModel)
            const result = await apiService.findSearchBy('formdata', 'form='+ formId)
            if (result.status.success) {
                //console.log('data : ' + JSON.stringify(res.items))
                result.items.map(currFormData => {
                    const { _id, form, data, createdAt, createdUser } = currFormData
                    const formData = JSON.parse(data)
                    const formDataId = _id
            
                    items.push(
                    {
                        formDataId: _id,
                        user: createdUser,
                        formData: formData,
                        icon: 'user',
                        onClick: () => {
                            this.props.history.push('/formData/view/' + formDataId)
                        }
                    })
                })
                
                this.setState({ formDatas: items })
            }

            this.setState({ columns: column, title: propsModel.form.name })
            //console.log(' formId ' + JSON.stringify(propsModel))
        }

        this.setState({ isLoading: false })
    }

    render() {
        try {
            const { isLoading, formDatas, columns } = this.state
            return (
                <>
                {
                    isLoading ?
                        <LoadIndicator visible={isLoading} />
                    :
                    <>
                        {this.renderTitle()}
                        <div className="kanban">
                            <table>
                            {/* <List items={formDatas} itemRender={this.renderNotifyInfo} /> */}
                            <tr>{this.renderHeadersOfList(columns)}<td></td><td></td></tr>
                            {
                                formDatas.map(item => {
                                    return (
                                    <tr>
                                        {this.renderItemsInList(columns, item.formData)}
                                        <td><div><Button icon="edit" onClick={() => 
                                            this.props.history.push(`/page/${this.formId}/edit/${item.formDataId}`)} /></div></td>
                                        <td><div><Button icon="info" onClick={() => 
                                            this.props.history.push(`/page/${this.formId}/view/${item.formDataId}`)} /></div></td>
                                    </tr>)
                                })
                            }
                            </table>
                        </div>
                    </>
                }
                </>
            )
        }
        catch(e) {
            return ErrorComponent(e)
        }
    }

    // renderNotifyInfo(item) {
    //     return (
    //       <div className="form">
    //         <div>{item.formData.name}</div>
    //         {/* <div><Link to={'/formData/view/'}>харах</Link></div> */}
    //       </div>
    //     )
    // }

    renderTitle() {
        // const title = this.props.title ? this.props.title : '' //this.props.modelName
        //console.log('Title ', this.props.title)
        return <h4>{this.state.title}</h4>
    }

    renderHeadersOfList(columns) {
        return (
            columns.map(col => {
                return <th>{col}</th>
        }))
    }

    renderItemsInList(columns, formData) {
        return (
            columns.map(col => {
                return <td>{formData[col]}</td>
        }))
    }

    // rowRender(columns, row) {
    //     //console.log('Cols with rowRender: ', columns)
    //     console.log('RENDERRow: ' + JSON.stringify(row.data) )
    //     return (
    //       <tbody className={`employee dx-row ${row.rowIndex % 2 ? 'dx-row-alt' : ''}`}>
    //         <tr className="main-row">
    //             {
    //                 row.data && columns && columns.map(r => {
    //                     return <td key={`${r}${row.rowIndex}`} id={row.rowIndex + '_' + row.data._id + '_'}>
    //                         {row.data[r] && this.renderField(r, row.data[r]) }
    //                     </td>
    //                 })
    //             }
    //             {this.renderEditButon(row)}
    //             <td><Button icon="info"
    //                   onClick={() => this.changeRoute(`/${this.props.routeUrl}/${this.props.modelName}/view/${row.data._id}`)} /></td>
    //         </tr>
    //       </tbody>
    //     );
    // }

    // renderField(collectionName, cell) {
    //     if (cell._id)
    //         return objectRender(collectionName, cell)
    //     else
    //         return `${cell}`
    // }

    // renderEditButon(row) {
    //     //console.log(' roles ' + JSON.stringify(currentUser.roles))
    //     if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('MANAGER')) {
    //         return (
    //             <td><Button icon="edit" onClick={() => this.changeRoute(`/${this.props.routeUrl}/${this.props.modelName}/edit/${row.data._id}`)} /></td> 
    //         )                
    //     }
    // }

    // changeRoute(url) {
    //     //this.setState({ isLoading: true })
    //     this.props.history.push(url, { from: "PageList" })
    // }
}

export default withRouter(PageRendererList)