import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { datetimeFormat, numberWithCommas } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const IEOs = ({ isAdmin, filter }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    let [isLoading, setLoading] = useState(true)
    const [ieos, setIEOs] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.findSearchBy('ieo', filter)
            setIEOs(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    let totalMeterSquares = 0
    let totalPuzzles = 0
    let totalPrices = 0

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Байрны IEO'} content={false}>
            <CardActions sx={{ justifyContent: 'flex-start' }}>
                <Button
                    onClick={() => history.push('/admin/ieo/add')}  
                    variant="contained" color="primary">
                    Шинэ IEO
                </Button>
            </CardActions>
            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Байр</TableCell>
                                <TableCell>Хаалганы дугаар</TableCell>
                                <TableCell>Явц</TableCell>
                                <TableCell>Үнэ</TableCell>
                                <TableCell>м.кв</TableCell>
                                <TableCell>Нийт Puzzle</TableCell>
                                <TableCell sx={{ pl: 3 }}>Puzzle</TableCell>
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Төлөв
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ieos && ieos.map((row, index) => {
                                totalMeterSquares += row.roomMeterSquare
                                totalPuzzles += row.totalSupply
                                totalPrices += (row.totalSupply * row.ieoPrice)
                                return (
                                    <TableRow hover key={index}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.doorNumber}</TableCell>
                                    <TableCell>{row.executionProgress.toFixed(2)}%</TableCell>
                                    <TableCell>{numberWithCommas(row.ieoPrice)}₮</TableCell>
                                    <TableCell>{row.roomMeterSquare}</TableCell>
                                    <TableCell>{row.totalSupply}</TableCell>
                                    <TableCell sx={{ pl: 3 }}>{row.coin ? row.coin.name : 'N/A'}</TableCell>
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        <Stack spacing={1} direction="row">
                                            <Chip
                                                onClick={() => history.push('/admin/ieo/edit/' + row._id)}
                                                variant="outlined"
                                                color={'default'}
                                                label={'засах'}
                                                size="small"
                                            />
                                        
                                            <Chip
                                                onClick={() => history.push('/admin/ieo/view/' + row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'цааш'}
                                                size="small"
                                            />

                                            <Chip
                                                onClick={() => history.push('/ieo/pictures/' + row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'2D зураг засах'}
                                                size="small"
                                            />
                                            <Chip
                                                onClick={() => history.push('/ieo/graph/' + row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'Арилжааны үнэ'}
                                                size="small"
                                            />
                                        </Stack>
                                        <Stack spacing={1} direction="row">
                                            <Chip
                                                onClick={() => history.push('/ieo/panoPictures/' + row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'360 зураг засах'}
                                                size="small"
                                            />
                                            <Chip
                                                onClick={() => history.push('/ieo/videos/' + row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'видео засах'}
                                                size="small"
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )})}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{numberWithCommas(totalPrices)} ₮</TableCell>
                                <TableCell>{numberWithCommas(totalMeterSquares)} м.кв</TableCell>
                                <TableCell>{numberWithCommas(totalPuzzles)} ш</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default IEOs;
