import React from 'react';
import { withRouter } from 'react-router-dom';

import TagBox from 'devextreme-react/tag-box';
import CheckBox from 'devextreme-react/check-box';
import backendStore from '../../../api/backendStore';
import LoadIndicator from 'devextreme-react/load-indicator';
import ErrorComponent from '../../../components/error'
import { datetimeFormat } from '../../../utils/helperFunction'
import { accordionRender, objectRender, renderAssets } from './renderFunctions'
import { getModel, getColumns, getCircularReplacer, getDisplayFieldValue, isNotEmpty } from './helpers'

// material-ui
import { Checkbox, Divider, Grid, TextField, FormGroup, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { gridSpacing } from 'store/constant';

import './view.scss'
import apiService from 'api/apiService';
//import '../components/form/form.scss';

class PageRenderer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            model: null,
            submitButtonText: 'Хадгалах',
            isAdded: false,
            _id: null,
            formData: {},
            selectedFiles: [],
            hasAttachFile: false,
            modelStore: null,
            modelData: null
        }
        
        this.renderComponent = this.renderComponent.bind(this)
        this.renderTextBox = this.renderTextBox.bind(this)
        this.renderTextArea = this.renderTextArea.bind(this)
        this.renderRadioGroup = this.renderRadioGroup.bind(this)
        this.renderCheckBox = this.renderCheckBox.bind(this)
        this.renderDateBox = this.renderDateBox.bind(this)
        this.renderNumberBox = this.renderNumberBox.bind(this)
        this.renderLookup = this.renderLookup.bind(this)
        this.renderFileUpload = this.renderFileUpload.bind(this)
        this.renderTitle = this.renderTitle.bind(this)
        this.getFieldValue = this.getFieldValue.bind(this)
        this.getFieldCollectionValue = this.getFieldCollectionValue.bind(this)

        //console.log(' model :::: ' + JSON.stringify(this.model))
    }

    // data from Parent comp props
    static getDerivedStateFromProps(nextProps) {
        const store = backendStore({entityName: nextProps.modelName.toLowerCase()});
        const propsModel = getModel(nextProps.modelName)

        if (nextProps.dataSource) {
            console.log('dataSource from parentComponent ' + nextProps.modelName)

            return {
                model: propsModel, modelData: nextProps.dataSource, modelStore: store
            };
        }
    }

    async componentDidMount() {
        // loading Data from API service
        const propsModel = getModel(this.props.modelName)

        if (this.props.match.params.Id && this.props.modelName) {
            //const store = backendStore({entityName: this.props.modelName.toLowerCase()});
            console.log('data from API ' + this.props.modelName)

            //let result = await store.byKey(this.props.match.params.Id)
            let result = await apiService.findOne(this.props.modelName, this.props.match.params.Id)
            let tempData = []
            if (result.data) {
                tempData = result.data
                //console.log('data from API ' + JSON.stringify(tempData))
                this.setState({ selectedFiles: result.data.assets })
            }

            this.setState({
                ...this.state, model: propsModel, modelData: tempData, //modelStore: store
            })
        }

        this.setState({isLoading: false})
    }

    render() {
        try {
            const { isLoading, model, modelData } = this.state
            const { collectionName, info, attributes } = model || null

        return (
            <>
            {
                isLoading ?
                    <LoadIndicator visible={isLoading} />
                :
                    <MainCard title={this.renderTitle()}>
                        <Grid container spacing={2} alignItems="center">
                
                            {attributes && attributes.map(attr => {
                                return this.renderComponent(attr)
                            })}
                        
                        </Grid>
                    </MainCard>
            }
            </>
        )
        }
        catch(e) {
            return ErrorComponent(e)
        }
    }

    async onFormSubmit(e) {

    }

    renderTitle() {
        const title = this.props.title ? this.props.title : this.props.modelName
        return (<h4>{title}</h4>)
    }

    renderComponent(attr) {
        if (attr.private)
            return ''
        
        switch(attr.editor) {
            case 'TextBox':
            default:
                return this.renderTextBox(attr)
            case 'TagBox':
                return this.renderTagBox(attr)
            case 'TextArea': 
                return this.renderTextArea(attr)
            case 'CheckBox': 
                return this.renderCheckBox(attr)
            case 'RadioGroup':
                return this.renderRadioGroup(attr)
            case 'NumberBox': 
                return this.renderNumberBox(attr)
            case 'FileUpload': 
                return this.renderFileUpload(attr)
            case 'Lookup':
                return this.renderLookup(attr)
            case 'DateBox':
                return this.renderDateBox(attr)
            case 'HtmlEditor': 
                return this.renderHtmlEditor(attr)
            case 'Accordion': 
                return this.renderAccordion(attr)
        }
    }
    
    renderTextBox(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {this.getFieldValue(attr)}
            </Grid>
        )
    }
    
    renderRadioGroup(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {this.getFieldValue(attr)}
            </Grid>
        )
    }

    renderTextArea(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {this.getFieldValue(attr)}
            </Grid>
        )
    }

    renderCheckBox(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    <CheckBox 
                        value={this.getFieldValue(attr)}
                        text="Тийм">
                    </CheckBox>
            </Grid>
        )
    }

    renderNumberBox(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {this.getFieldValue(attr)}
            </Grid>
        )
    }

    renderLookup(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {this.getFieldCollectionValue(attr)}
            </Grid>
        )
    }
    
    renderDateBox(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                {datetimeFormat(this.getFieldValue(attr))}
            </Grid>
        )
    }

    renderTagBox(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {
                        this.getFieldMultipleCollectionValue(attr).map((obj, i) => {
                            return (
                                <li key={obj._id}>{objectRender(attr.name, obj)}</li> //TODO : replace with displayFieldName
                            )
                        })
                    } 
            </Grid>
        )
        return ''
    }

    renderHtmlEditor(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.getFieldValue(attr)
                        }}>
                    </div>
            </Grid>
        )
    }

    renderFileUpload(attr) {
        //console.log(' Media ' + JSON.stringify(this.getFieldMultipleCollectionValue(attr)))
        return (
            <Grid item xs={12} key={attr.name}>
                <InputLabel>{attr.label}</InputLabel>
                    {renderAssets(this.getFieldMultipleCollectionValue(attr))}
            </Grid>
        )
    }

    renderAccordion(attr) {
        return (
            <Grid item xs={12} key={attr.name}>
                {accordionRender(this.getFieldCollectionValue(attr))}
            </Grid>
        )
    }

    getFieldValue(field) {
        const { modelData, isLoading } = this.state
        if (modelData)
            //if ( isNotEmpty(modelData[field.name]) )
            return modelData[field.name]
        
    }

    getFieldCollectionValue(field) {
        const { modelData, isLoading } = this.state
        if (modelData && isNotEmpty(modelData[field.name]) )
            return objectRender(field.name, modelData[field.name])
        else return '{хоосон}'
        //return ''
    }

    // used for TagBox, SelectBox, Lookup
    getFieldMultipleCollectionValue(field) {
        const { modelData, isLoading } = this.state
        const IDs = []
        if (modelData && !isLoading)
            if (isNotEmpty(modelData[field.name]) )
                modelData[field.name].map((curr) => {
                    IDs.push(curr._id)
                })
            return IDs
        return '{Empty}'
        //return ''
    }

    // used for Photo, Videos
    getFieldMultipleCollectionValue(field) {
        const { modelData, isLoading } = this.state
        const IDs = []
        if (modelData && !isLoading)
            if (isNotEmpty(modelData[field.name]) )
                modelData[field.name].map((curr) => {
                    IDs.push(curr)
                })
            return IDs
        return '{Empty}'
        //return ''
    }
}

export default withRouter(PageRenderer)