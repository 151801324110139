import { dashboard } from './dashboard';
import { admin } from './admin';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, admin]
};

export default menuItems;
