import React from 'react';
import { call, put } from 'redux-saga/effects';
import { Link } from 'react-router-dom';
import API from '../../api'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }

    this.onSignIn = this.onSignIn.bind(this)
  }

  

  render() {
    return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <form>
            <span>Sign In</span>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                required
              />
            </div>
            <div className="text-right">
              <Link to="/reset">Forgot Password?</Link>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                Remember me
              </label>
            </div>
            <button type="submit" 
              onClick={this.onSignIn}
              className="btn btn-primary">
              Sign In
            </button>
          </form>
          <h2>
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </h2>
        </div>
      </div>
    </>
    )
  }

  async onSignIn(e) {
    const postBody = {
      email: 'admin@peatio.tech',
      password: '123123',
      otp_code: '123456'
    }

    const sessionsConfig = {
      apiVersion: 'barong',
    }

    try {
      const user = await API.post('http://localhost:9002/api/v2/barong/identity/sessions', postBody)
      console.log(' user ' + JSON.stringify(user))
    }
    catch (error) {
      if (error.code === 401 && error.message.indexOf('identity.session.missing_otp') > -1) {
        console.log(' error otp ' + error)
        //yield put(signInRequire2FA({ require2fa: true }));
        //yield put(signInData());
      } else {
        console.log(' error ' + error)
        // yield put(sendError({
        //     error: error,
        //     processingType: 'alert',
        //     extraOptions: {
        //         actionError: signInError,
        //     },
        // }));
      }
    }
  }
}

export default App
