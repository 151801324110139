import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ToolbarListPage from 'components/toolbar/toolbarListPage'
import IEOs from './IEOs';
import Orders from './Orders';
import OrderedIEO from './OrderedIEO';

import { useAuth } from 'contexts/auth';
import { checkRole } from 'api/auth'

// style constant
const useStyles = makeStyles((theme) => ({
    accountTab: {
        marginBottom: '24px',
        '& button': {
            minWidth: '100px'
        },
        '& a': {
            minHeight: 'auto',
            minWidth: '10px',
            padding: '12px 8px',
            marginRight: '18px',
            color: theme.palette.grey[600]
        },
        '& a.Mui-selected': {
            color: theme.palette.primary.main
        }
    }
}));

// tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

//-----------------------|| PROFILE 3 ||-----------------------//

const Profile3 = () => {
    const classes = useStyles();
    const { user } = useAuth()

    const [isAdmin, setAdmin] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const tmp = await checkRole(user, 'ADMIN')
            tmp ? setAdmin(true) : setAdmin(false)
            // console.log(' isAdmin', JSON.stringify(tmp))
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        }
        //return response.items;
    };

    return (
        !isAdmin ? <p>Access is denied</p> :
        <>
        <ToolbarListPage modelName={'user'} />

        <MainCard title="Удирдах хэсэг">
            <div className={classes.root}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    onChange={handleChange}
                    className={classes.accountTab}
                    aria-label="simple tabs example"
                    variant="scrollable"
                >
                    <Tab component={RouterLink} to="#" label="Бүх IEO" {...a11yProps(0)} />
                    <Tab component={RouterLink} to="#" label="Нээлттэй IEO" {...a11yProps(1)} />
                    <Tab component={RouterLink} to="#" label="Дууссан IEO" {...a11yProps(2)} />
                    <Tab component={RouterLink} to="#" label="IEO Захиалгууд" {...a11yProps(3)} />
                    <Tab component={RouterLink} to="#" label="Захиалсан IEO" {...a11yProps(4)} />
                </Tabs>
                
                <TabPanel value={value} index={0}>
                    <IEOs isAdmin={isAdmin} filter={''} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <IEOs isAdmin={isAdmin} filter={'isDone=false'} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <IEOs isAdmin={isAdmin} filter={'isDone=true'} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Orders isAdmin={isAdmin} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <OrderedIEO />
                </TabPanel>
            </div>
        </MainCard>
        </>
    );
};

export default Profile3;
