import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Pagination,
    Stack,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Typography,
    IconButton
} from '@material-ui/core';
import { FilterList, Clear, Done } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ResetPassword from './resetPassword';
import MyModal from 'components/modal'


import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'
import { datetimeFormat, compareDate } from 'utils/helperFunction'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    },
    ScrollHeight: {
        height: '500px'
    }
}));

const UsersCharged = ({isAdmin}) => {
    const classes = useStyles();
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getData();
    }, [])


    const getData = async (type) => {
        setIsLoading(true)
        try {
            let owners = [];
            const response = await apiService.find('fiatWallet/getAll/nonEmpty')
            response.items.forEach((item) => {
                owners.push(item.owner);
            })
            setUsers(owners);
            console.log('response: ', response);
        } catch(e) {
            setIsLoading(false)
            console.log('failed to fetch data: ', e);
            return 
        } finally {
            setIsLoading(false);
        }
    }

    console.log('users: ', users);

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={`Хэрэглэгчид (${users.length})`} content={false}>
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Овог нэр</TableCell>                                    
                                <TableCell>Имэйл / Утас / ID</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>Бүртгүүлсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Төлөв
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.map((row, index) => {
                                const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/avatar/' +
                                    (row.avatarUrl ? row.avatarUrl.fileName : 'defaultAvatar.png')
                                    return <TableRow hover key={index}>
                                        <TableCell sx={{width: '10px'}}>{index + 1}.</TableCell>
                                        <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                        <TableCell>
                                            {row.userType === 'individual' ? `${row.lastName} ${row.firstName}` : `${row.companyName}`}
                                            <br />
                                            {row.userType === 'individual' && <Typography variant="caption" display="inline" sx={{color: 'white', backgroundColor: '#757ce8', padding: '2px', borderRadius: '2px', fontSize: '10px'}} gutterBottom>(хувь хүн)</Typography>}
                                            {row.userType === 'company' && <Typography variant="caption" display="inline" sx={{color: 'white', backgroundColor: '#ff7961', padding: '2px', borderRadius: '2px', fontSize: '10px'}} gutterBottom>(байгууллага)</Typography>}
                                        </TableCell>
                                        <TableCell>
                                            {row.email}<br/>
                                            {row.phoneNumber}<br/>
                                            {row.registerNumber}<br/>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>phone: {row.phoneValidate === 'validated' ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}} />}</Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>email: {row.emailValidate === 'validated' ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}} />}</Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>2FA: {row.otp ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}} />}</Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>ID: {row.idValidate === 'validated' ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}}/>}</Box>
                                        </TableCell>
                                        <TableCell>{row.doc1 ? 'uploaded' : '   '}</TableCell>
                                        {/* <TableCell>{row.userType}</TableCell> */}
                                        <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right" sx={{ pr: 3 }}>
                                            { isAdmin && <Stack direction={'row'} spacing={1} justifyContent="flex-end"><Chip 
                                                onClick={() => history.push('/admin/user/edit/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'засах'} 
                                                size="small"
                                            />
                                            <Chip 
                                                onClick={() => history.push('/useradmin/userview/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'харах'} 
                                                size="small"
                                            />
                                            <Chip 
                                                onClick={() => history.push('/user/resetpassword/' + row._id)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'нууц үг солих'} 
                                                size="small"
                                            />
                                            <Chip 
                                                // onClick={() => setShowResetPassword(true)} 
                                                variant="outlined" 
                                                color={'default'} 
                                                label={'идэвхгүй болгох'} 
                                                size="small"
                                            /></Stack>}
                                        </TableCell>
                                    </TableRow>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    )
}

export default UsersCharged