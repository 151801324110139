import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule
  } from 'devextreme-react/validator'
import DataGrid, { Column, Paging, Pager, Editing, FormItem, MasterDetail, FilterRow, HeaderFilter  } from 'devextreme-react/data-grid'
import { Lookup, DropDownOptions } from 'devextreme-react/lookup'
import Button from 'devextreme-react/button'
import { Tooltip } from 'devextreme-react/tooltip'
import { Popover } from 'devextreme-react/popover'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import ValidationSummary from 'devextreme-react/validation-summary'
import { renderTextBox, renderNumberBox, ItemRender, FieldRender } from '../withdraw/render'
import { getUser } from '../../api/auth'
import apiService from '../../api/apiService'
import './index.scss';

const loanTermList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

class App extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        isLoading: true,
        coinTicker: [],
        coinList: [],
        selectedCoin: {},
        user: null,
        buyOrderList: null,
        sellOrderList: null
      }

    //   this.valueChanged = this.valueChanged.bind(this)
    //   this.renderDeposit = this.renderDeposit.bind(this)
      this.renderOrder = this.renderOrder.bind(this)
      this.renderMarketOrder = this.renderMarketOrder.bind(this)
      this.renderBuyForm = this.renderBuyForm.bind(this)
      this.renderSellForm = this.renderSellForm.bind(this)
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.coin !== this.props.match.params.coin) {
          this.setState({ isLoading: true })
          const { coin } = this.props.match.params
          await this.resetData()
          await this.fetchData(coin)
        }
      }
    
      async componentDidMount() {
        const { coin } = this.props.match.params
        await this.fetchData(coin)
      }
    
      async resetData() {
        this.setState({ coinTicker: null })
      }
    
      async fetchData(coinTicker) {
        const userData = await getUser()
        //console.log('loading user ' + JSON.stringify(userData.data))
        this.setState({ user: userData.data })
    
        //const result = await apiService.findSearchBy('wallet', 'owner=' + userData.data._id + '&coin=' + coinTicker)
        const result = await apiService.find('coin')
        if (result.status.success && result.items.length) {
          this.setState({ coinList: result.items })
          // 
          result.items.map(selected => {
            if (selected.ticker === coinTicker)
              //console.log('selected coin ' + JSON.stringify(selected))
              this.setState({ selectedCoin: selected })
          })
        }

        // sellOrder
        const result2 = await apiService.find('sellOrder')
        if (result2.status.success && result2.items.length) {
          console.log(' sell ' + JSON.stringify(result2.items))
          this.setState({ sellOrderList: result2.items })
        }
        
        // buyOrder
        const result3 = await apiService.find('buyOrder')
        if (result3.status.success && result3.items.length) {
          console.log(' buy ' + JSON.stringify(result3.items))
          this.setState({ buyOrderList: result3.items })
        }
    
        this.setState({ isLoading: false, coinTicker: coinTicker })
      }

    render() {
        const { isLoading, coinTicker, coinList, selectedCoin, buyOrderList, sellOrderList } = this.state
    
        return ( 
            isLoading ? 
                <LoadIndicator visible={isLoading} />
                :
            <>
              <div className="left">
                <div className="tradeSell">
                    Авах захиалга:
                    {this.renderOrder(buyOrderList, 'Ханш')}
                </div>

                <div className="tradeBuy">
                    Зарах захиалга:
                    {this.renderOrder(sellOrderList, 'Ханш')}
                </div>
              </div>

              <div className="center">
                {/* <CandleChart /> */}
                    {this.renderBuyForm()}
                    
                    {this.renderSellForm()}
              </div>

              <div className="right">
                  Биелсэн арилжаа:
                  {this.renderMarketOrder(sellOrderList)}
              </div>
            </>
        )
    }

    renderBuyForm() {
      return (
        <div className="buyForm">
          

            {renderNumberBox({ name: 'price', label: 'Зарах ханш:', required: true })}

            {renderNumberBox({ name: 'amount', label: 'Тоо хэмжээ', required: true })}

            {renderNumberBox({ name: 'total', label: 'Нийт үнэ', required: true })}
        
            
        
                    <ValidationSummary id="summary"></ValidationSummary>
                    <Button
                        id="submit"
                        text={'Авах'}
                        icon="save"
                        type="success"
                        useSubmitBehavior={true}
                        stylingMode="contained"
                        onClick={this.onFormSubmit} />
                

          
        </div>
      )
    }

    renderSellForm() {
      return (
        <div className="sellForm">
          

            {renderNumberBox({ name: 'price', label: 'Авах ханш:', required: true })}

            {renderNumberBox({ name: 'amount', label: 'Тоо хэмжээ', required: true })}

            {renderNumberBox({ name: 'total', label: 'Нийт үнэ', required: true })}
        
            
        
                    <ValidationSummary id="summary"></ValidationSummary>
                    <Button
                        id="submit"
                        text={'Зарах'}
                        icon="save"
                        type="danger"
                        useSubmitBehavior={true}
                        stylingMode="contained"
                        onClick={this.onFormSubmit} />
                

          
        </div>
      )
    }
  
    renderOrder(dataList, label) {
      return (
        <DataGrid id={`gridContainer${label}`}
        dataSource={dataList}
            keyExpr="_id"
            showBorders={false}
            //rowRender={rowRender}
            width={300}
        >
            <Column dataField="price"
                caption={label}
                //width={150}
            />
            <Column dataField="amount"
                caption={"Хэмжээ"}
                //width={150}
            />
            <Column dataField="total"
                caption={"Нийт"}
                //width={150}
            />
        </DataGrid>
      )
    }
    
    renderMarketOrder(dataList) {
      return (
        <DataGrid id="gridContainer2"
            dataSource={dataList}
            keyExpr="_id"
            showBorders={false}
            //rowRender={rowRender}
            width={300}
        >
            <Column dataField="price"
                caption={'Үнэ'}
                //width={150}
            />
            <Column dataField="amount"
                caption={"Хэмжээ"}
                //width={150}
            />
            <Column dataField="createdAt"
                caption={"Огноо"}
                //width={150}
            />
        </DataGrid>
      )
    }
}

export default withRouter(App)
