import React, { useState } from 'react'
import MainCard from 'ui-component/cards/MainCard'
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'
import MyToast from 'components/toast'
import apiService from 'api/apiService'

const AddFundWallet = ({ user, wallet }) => {
    const coinUrl = process.env.REACT_APP_API_URL + '/uploads/asset/coin/' +
        (wallet && wallet.coin ? wallet.coin.logo : 'default.png')
    
    const [qty, setQty] = useState(0)
    const [showToast, setShowToast] = useState('')

    const addFund = async () => {
        if (parseFloat(qty) > 0) {
            let res = await apiService.post('wallet/addFund', { walletId: wallet._id, fundQty: qty, userId: user._id })
            if (res && res.status && res.status.success)
                setShowToast(' Амжилттай: ' + qty)
            else
            setShowToast(' Алдаатай гарлаа: ' + res.message)
        }
        else
            setShowToast(' Тоо ширхэг алдаатай байна: ' + qty)
    }

    return (
        <MainCard title={'Хэтэвчинд орлого хийх'}>
            <Typography variant="subtitle2" align="center">
                <strong>{showToast}</strong>
            </Typography>
            <CardContent>
                <table>
                    <tr>
                        <td>Хэрэглэгч:</td>
                        <td>{user ? user.lastname + '-н ' + user.firstname : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Хэтэвч:</td>
                        <td><Avatar alt="coverimage" src={coinUrl} /> {wallet ? wallet.coin.ticker : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Цэнэглэх тоо ширхэг:</td>
                        <td>
                            <TextField
                                id="qty"
                                fullWidth
                                label="Тоо ширхэг"
                                variant="outlined"
                                defaultValue={'0'}
                                onChange={(e) => setQty(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>
                        <Button
                            onClick={() => addFund()}
                            variant="contained" color="primary">
                            Хэтэвч цэнэглэх
                        </Button>
                        </td>
                    </tr>
                </table>
            </CardContent>
        </MainCard>
    )
}

export default AddFundWallet