import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IonLoading } from '@ionic/react'
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography, Autocomplete, Divider } from '@material-ui/core';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'
import { hasRole } from 'api/auth'

// style constant
const useStyles = makeStyles((theme) => ({
    accountAvatar: {
        width: '100px',
        //height: '100px',
        margin: '0 auto'
    },
    accountContent: {
        textAlign: 'center'
    }
}));

//-----------------------|| PROFILE 3 - PROFILE ||-----------------------//

const AddcoinToUser = () => {
    const classes = useStyles()
    const { user } = useAuth()
    const history = useHistory()
    const isAdmin = hasRole('ADMIN')

    let [isLoading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [coins, setCoins] = useState([])
    const [allNonUsers, setAllNonUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [coin, setCoin] = useState({})
    const [team, setTeam] = useState({})
    const [assignedUsers, setAssignedUsers] = useState([])
    const [toAllUsers, setToAllUsers] = useState(false)
    const [toAllNonUsers, setToAllNonUsers] = useState(false)

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            let response = await apiService.find('user')
            setUsers(response.items)

            response = await apiService.find('coin')
            setCoins(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    const handleChangeCoin = async (selectedCoin) => {
        setCoin(selectedCoin)
        let res = await apiService.find('user/getAllNonWalletUsers/' + selectedCoin._id)
        if (res && res.status && res.status.success) {
            console.log('all Non wallet users', JSON.stringify(res.items))
            setAllNonUsers(res.items)
        }
    }

    const onSubmit = async () => {
        let insertedUsers = []
        let res = null
        //console.log(' coin ', JSON.stringify(coin), ' users ', JSON.stringify(users))
        //if all users selected
        if (toAllUsers && users && users.length) {
            await Promise.all(users.map( async (assignedUser) => {
                res = await insertCoinData(coin._id, assignedUser._id, user._id)
                if (res) {
                    //
                    insertedUsers.push(assignedUser._id)
                    console.log(' all users inserted ')
                }
            })
            )

            history.goBack()
        }

        else if (toAllNonUsers && allNonUsers && allNonUsers.length) {
            let userIds = []
            allNonUsers.map(assignedUser => {
                userIds.push(assignedUser._id)
            })

            const postData = { coinId: coin._id, userIds }

            const res = await apiService.post('wallet/createMany', postData, '?userId=' + user._id)

            if (res && res.status && res.status.success) {
                console.log(' all non fit wallet users inserted ')
            }

            // await Promise.all(allNonUsers.map( async (assignedUser) => {
            //     res = await insertCoinData(coin._id, assignedUser._id, user._id)
            //     if (res) {
            //         //
            //         insertedUsers.push(assignedUser._id)
            //         console.log(' all non wallet users inserted ')
            //     }
            // })
            // )

            history.goBack()
        }

        // if (assignedUsers && assignedUsers.length && coin && coin._id && user && user._id && user.company._id) {
        //     await Promise.all(assignedUsers.map( async (assignedUser) => {
        //         let found = false

        //         insertedUsers.map(itemUserId => {
        //             if (itemUserId === assignedUser._id)
        //                 found = true
        //         })

        //         if (!found)
        //             res = await insertCoinData(coin._id, assignedUser._id, user._id, user.company._id)
        //         else
        //             console.log(' this user already inserted', assignedUser._id)
        //     })
        //     )

        //     history.goBack()
        // }
    }

    const insertCoinData = async (coinId, assignedUserId, createdUserId) => {
        const postData = {
            coin: coinId,
            owner: assignedUserId,
            address: '111',
            pubKey: '111',
            pass: '111',
            balance: 0,
            balanceInOrder: 0,
            isConfirmed: true
        }
        const res = await apiService.insert('wallet', postData)
        if (res && res.items) {
            //
            console.log(' assignedUser inserted ', assignedUserId)
            return true
        }

        return false
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <>
        <MainCard title="Хэтэвчүүд үүсгэх">
        <Grid container spacing={gridSpacing}>
            <Grid item sm={6} md={4}>
                <SubCard title={'Койн сонгох'} contentClass={classes.accountContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="coin"
                                name="coin"
                                disablePortal
                                options={coins}
                                defaultValue={coin}
                                renderInput={(params) => <TextField variant="outlined" {...params} label={'Сургалтууд'} fullWidth />}
                                getOptionLabel={(option) => option['name'] || 'сонгох'}
                                disabled={!isAdmin}
                                onChange={(e, value) => handleChangeCoin(value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="center">
                                {coin && coin.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item sm={6} md={8}>
                <SubCard title={'Хэрэглэгч сонгох'}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="allUsers"
                                        color="primary"
                                        //indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={toAllUsers}
                                        onChange={(e) => { setToAllUsers(e.target.checked) && console.log('toall user', e.target.checked)}}
                                />
                                }
                                label="Бүх хэрэглэгчид руу илгээх"
                            />
                        
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="allNonUsers"
                                        color="primary"
                                        //indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={toAllNonUsers}
                                        onChange={(e) => { setToAllNonUsers(e.target.checked) && console.log('to all user', e.target.checked)}}
                                />
                                }
                                label="Хэтэвчгүй хэрэглэгчид руу илгээх"
                            />
                        
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="assignedUsers"
                                name="assignedUsers"
                                disablePortal
                                options={users}
                                defaultValue={assignedUsers}
                                renderInput={(params) => <TextField variant="outlined" {...params} label={'Хэрэглэгчдээс нэг бүрчлэн сонгох'} fullWidth />}
                                getOptionLabel={(option) => (option['lastname'] + '. ' + option['firstname'])}
                                disabled={!isAdmin}
                                onChange={(e, value) => { setAssignedUsers(value) } }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button 
                                        onClick={onSubmit}
                                        variant="contained" color="primary">
                                        хэтэвчүүд үүсгэх
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
        </MainCard>
        </>
    );
};

export default AddcoinToUser;
