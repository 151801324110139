import React from 'react'
import { withRouter } from 'react-router-dom'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup'
import TextBox from 'devextreme-react/text-box'
import NumberBox from 'devextreme-react/number-box'
import Lookup from 'devextreme-react/lookup'
import LoadIndicator from 'devextreme-react/load-indicator'
import apiService from '../../api/apiService'
import { getUser } from '../../api/auth'
import notify from 'devextreme/ui/notify'

class App extends React.Component {
    addButtonOptions = null
    closeButtonOptions = null

    constructor(props) {
        super(props)

        this.state = {
            user: null,
            isLoading: true,
            positionOf: '',
            coinList: [],
            selectedAddress: null,
            selectedBank: null,
            selectedCoin: null,
            selectedAddressName: null
        }

        this.showPopup = this.showPopup.bind(this)
        this.addAccount = this.addAccount.bind(this)

        this.addButtonOptions = {
            icon: 'save',
            text: 'Нэмэх',
            onClick: (e) => this.addAccount(e)
        }

        this.closeButtonOptions = {
            text: 'Хаах',
            onClick: () => this.showPopup(false)
        }
    }

    async componentDidMount() {
        const userData = await getUser()
        //console.log('loading user ' + JSON.stringify(userData.data))
        this.setState({ user: userData.data })

        await this.fetchData()

        this.setState({ isLoading: false })
    }

    async fetchData() {
        const result2 = await apiService.findSearchBy('coin');
        if (result2.status.success && result2.items.length) {
            //console.log('loading wallet ' + JSON.stringify(result.items))
            this.setState({ coinList: result2.items, selectedCoin: result2.items[0]._id })
        }
    }

    render() {
        const { isLoading, bankList, coinList, selectedAddressName } = this.state

        return (
            isLoading ? 
                <LoadIndicator visible={isLoading} />
                :
            <>
                <Popup
                    visible={this.props.visible}
                    onHiding={() => this.showPopup(false)}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    title="Койны хаяг нэмэх"
                    container=".dx-viewport"
                    width={500}
                    //height={280}
                >
                    <Position
                        at="middle"
                        my="center"
                        of={this.state.positionOf}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={this.addButtonOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={this.closeButtonOptions}
                    />
                    <p>Хадгалах нэр:
                    <TextBox 
                        defaultValue={selectedAddressName} 
                        stylingMode="filled" 
                        width={200}
                        onValueChanged={(e) => this.setState( { selectedAddressName: e.value }) }
                    >
                    </TextBox></p>
                    <p>Валют:
                    <Lookup 
                        value={coinList[0]._id}
                        stylingMode="filled" 
                        width={200}
                        items={coinList}
                        //dataSource={coinList}
                        displayExpr="name"
                        itemRender={coinItemRender}
                        fieldRender={coinFieldRender}
                        valueExpr="_id"
                        onValueChanged={(e) => this.setState( { selectedCoin: e.value }) }
                    >
                    </Lookup></p>
                    <p>Хаяг:
                    <TextBox 
                        //defaultValue={this.getFieldValue(att)} 
                        stylingMode="filled" 
                        width={200}
                        onValueChanged={(e) => this.setState( { selectedAddress: e.value }) }
                    >
                    </TextBox></p>
                </Popup>
            </>
        )
    }

    showPopup(visible) {
        this.props.showPopup(visible)
    }

    async addAccount(e) {
        const { selectedAddressName, selectedCoin, selectedAddress, user } = this.state
        const postBody = { 
            address: selectedAddress, 
            addressName: selectedAddressName,
            coin: selectedCoin,
            owner: user._id,
            accountType: 'coin'
        }
        console.log(' coin account added: ' + JSON.stringify(postBody))

        if (selectedAddressName && selectedCoin && selectedAddress && user ) {
            const res = await apiService.insert('account', postBody)
            
            if (res.status.success) {
                notify({
                    message: 'Койн хаягийг амжилттай хадгаллаа',
                    position: {
                    my: 'top middle',
                    at: 'top middle'
                    }
                }, 'success', 2000)
            }
            else
                notify({
                    message: 'Уучлаарай, алдаа гарлаа',
                    position: {
                    my: 'top middle',
                    at: 'top middle'
                    }
                }, 'error', 2000)

            this.props.showPopup(false)
        }
        else 
            notify({
                message: 'Хаягийн мэдээлэл алдаатай байна',
                position: {
                my: 'top middle',
                at: 'top middle'
                }
            }, 'error', 2000)
    }
}

function coinItemRender({ name, ticker, logo }) {
    const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + logo
    return (
        <div className="custom-item">
          <img height={15} src={picUrl} />
          <div><b>{`${ticker}`}</b> {`${name }`}</div>
        </div>
    );
}
  
function coinFieldRender(field) {
    if (field) {
      const { name, ticker, logo } = field
      const picUrl = process.env.REACT_APP_BACKEND_API + '/uploads/asset/coin/' + logo
      return (
          <div className="custom-field">
            <img height={20} src={picUrl} />
            <div><b>{`${ticker}`}</b> {`${name }`}</div>
          </div>
      )
    }
    else return ''
}

export default withRouter(App)