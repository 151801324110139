import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IonLoading, IonAlert } from '@ionic/react';
import { dayjsFormat } from "utils/helperFunction";
import apiService from "api/apiService";
import MainCard from 'ui-component/cards/MainCard';

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useForm } from "react-hook-form";

import { makeStyles } from '@material-ui/core/styles';
import {
    CardContent,
    Button,
    TextField,
    Grid,
    Snackbar,
    Alert
} from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));


const IEOGraphEdit = () => {
    const classes = useStyles();
    const routerParams = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [IEO, setIEO] = useState();
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const {
        handleSubmit,
        setValue,
        getValues,
        control,
    } = useForm();
    dayjs.extend(utc)

    useEffect(() => {
        getIeo();
    }, [success])

    if(IEO){
        setValue('startDate', dayjs.utc(IEO.startDate));
        setValue('endDate', dayjs.utc(IEO.endDate));
        setValue('floorPrice', Number(IEO.floorPrice));
        setValue('ceilingPrice', Number(IEO.ceilingPrice));
        setValue('executionProcess', Number(IEO.executionProcess));
        setValue('description', IEO.description);
    }

    const getIeo = async () => {
        const res = await apiService.findOne('ieoGraph', routerParams.id);
        if(res.data){
            setIEO(res.data);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowToast(false);
        setSuccess('')
        setError('')
    };

    const updateRecord = async (data) => {
        try {
            console.log('data.endDate 1: ', data.endDate);
            data.ieo = IEO.ieo._id;
            data._id = IEO._id;
            data.floorPrice = Number(data.floorPrice)
            data.ceilingPrice = Number(data.ceilingPrice)
            data.executionProcess = Number(data.executionProcess);
            data.startDate = dayjs(data.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
            data.endDate = dayjs(data.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
            data.description = data.description ? data.description : '';
            console.log('data.endDate 2: ', data.endDate)
            const response = await apiService.patch('ieoGraph', routerParams.id, data)
            if(response && response.status && response.status.success){
                setShowToast(true)
                setSuccess('Амжилттай засагдлаа!')
            } else {
                setShowToast(true)
                setError(`Алдаа гарлаа! - ${response.error}`);
            }
        } catch (e) {
            setShowToast(true)
            setError(`Алдаа гарлаа!`);
        } finally {

        }
    }

    const deleteRecord = async (data) => {
        try {
            const response = await apiService.remove('ieoGraph', routerParams.id);
            if(response && response.status && response.status.success){
                setShowToast(true)
                setSuccess('Амжилттай устгагдлаа!')
                history.goBack();
            } else {
                setShowToast(true)
                setError(`Алдаа гарлаа! - ${response.error}`);
            }
        } catch(e) {
            setShowToast(true)
            setError(`Алдаа гарлаа!`);
        } finally {

        }
    }

    return (
        loading ? <IonLoading isOpen={loading} /> :
        <MainCard title={`IEO Арилжааны үнэ засах - ${IEO && IEO.ieo.name}`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {success && <Snackbar open={showToast} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>}
                {error && <Snackbar open={showToast} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>}
                <CardContent className={classes.projectTableCard}>
                    <form onSubmit={handleSubmit(updateRecord)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller 
                                    render={({field}) => (
                                        <DatePicker
                                            {...field}
                                            label="Эхлэх огноо"
                                            value={getValues('startDate')}
                                            onChange={(newValue) => setValue("startDate", newValue)}
                                            slotProps={{ textField: { fullWidth: true } }}
                                        />
                                    )}
                                    control={control}
                                    name="startDate"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    render={({field}) => (
                                        <DatePicker
                                            {...field}
                                            label="Дуусах огноо"
                                            value={getValues('endDate')}
                                            onChange={(newValue) => setValue('endDate', newValue)}
                                            slotProps={{ textField: { fullWidth: true } }}
                                        />)
                                    }
                                    control={control}
                                    name="endDate"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            required
                                            id="floorPrice"
                                            label="Шал үнэ"
                                            type="text"
                                            value={Number(getValues('floorPrice'))}
                                            onChange={(event) => setValue('floorPrice', event.target.value)}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="floorPrice"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            required
                                            id="ceilingPrice"
                                            label="Тааз үнэ"
                                            type="text"
                                            value={Number(getValues('ceilingPrice'))}
                                            fullWidth
                                            onChange={(event) => setValue('ceilingPrice', event.target.value)}
                                        />
                                    )}
                                    control={control}
                                    name="ceilingPrice"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            required
                                            id="executionProcess"
                                            label="Гүйцэтгэлийн хувь"
                                            type="number"
                                            value={Number(getValues('executionProcess'))}
                                            onChange={(event) => setValue('executionProcess', event.target.value)}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="executionProcess"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            id="description"
                                            label="Тайлбар"
                                            type="text"
                                            fullWidth
                                            value={getValues('description')}
                                            onChange={(event) => setValue('description', event.target.value)}

                                        />
                                    )}
                                    control={control}
                                    name="description"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    type="button"
                                    onClick={() => history.goBack()}
                                    variant="outlined" color="primary">
                                    Буцах
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="button"
                                    onClick={() => setShowAlertDelete(true)}
                                    variant="contained"
                                    color="error">
                                    Устгах
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained" color="primary">
                                    Хадгалах
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </LocalizationProvider>
            <IonAlert
                isOpen={showAlertDelete}
                header={"Мэдээллийг устгах уу?"}
                buttons={[
                'Болих',
                {
                    text: 'Тийм, устгая',
                    handler: async () => {
                        await deleteRecord();
                    }
                }
                ]}
                onDidDismiss={() => this.setState({ showAlertDelete: false})}
            />
        </MainCard>
    )
}

export default IEOGraphEdit;