import apiService from '../api/apiService'

export async function getModel(formId) {
    const result = await apiService.findOneWithPath('formdata', 'getformfields', formId);

    if (result.status.success)
        return result
    else
        return null
}

export function getColumns(form) {
    let columns = []

    if (form.fields.length)
        columns = form.fields.map(attr => {
        return attr.name
    })

    return columns
}

export function getColumnsInList(form) {
    let columns = []

    if (form.fields.length) {
        form.fields.map(attr => {
            attr.availableInLists && columns.push(attr.name)
        })
    }

    return columns
}

export function getDisplayFieldValue(collectionName) {
    //console.log(' getDisplayFieldValue ' + JSON.stringify(collectionName))
    //return 'form.fields.name'
    let displayFieldName = 'name'
    switch(collectionName) {
        case 'user': displayFieldName = 'username'; break
    }

    return displayFieldName
}

export function getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}