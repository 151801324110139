import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IonLoading } from "@ionic/react";
import { datetimeFormat } from "utils/helperFunction";
import apiService from "api/apiService";
import MainCard from "ui-component/cards/MainCard";

import { makeStyles } from "@material-ui/core/styles";
import {
  CardContent,
  CardActions,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Button,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  projectTableCard: {
    padding: "0px",
  },
}));

const VersionList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await apiService.findSearchBy("versions");
      if (response.items && response.items.length) {
        setData(response.items);
      }
    } catch (e) {
      console.log("failed to fetch : ", e);
      return;
    } finally {
      setLoading(false);
    }
  };

  console.log("data:: ", data);

  return loading ? (
    <IonLoading isOpen={loading} />
  ) : (
    <MainCard title={"Апп хувилбар"} content={false}>
        <CardActions sx={{ justifyContent: 'flex-start' }}>
            <Button
                onClick={() => history.push('/admin/versions/add')}  
                variant="contained" color="primary">
                Хувилбар нэмэх
            </Button>
        </CardActions>
        <Divider />
        <CardContent className={classes.projectTableCard}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Хувилбар</TableCell>
                            <TableCell>Platform</TableCell>
                            <TableCell>Орчин</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Үүсгэсэн огноо</TableCell>
                            <TableCell>Үйлдэл</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((row, index) => {
                            return (
                                <TableRow hover key={row._id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.version}</TableCell>
                                    <TableCell>{row.platform}</TableCell>
                                    <TableCell>{row.environment}</TableCell>
                                    <TableCell>{row.isValid ? 'Идэвхтэй' : 'Идэвхгүй'}</TableCell>
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    <TableCell>
                                        <Stack spacing={1} direction="row">
                                            <Chip
                                                onClick={() => history.push('/admin/versions/edit/' + row._id)}
                                                variant="outlined"
                                                color={'default'}
                                                label={'засах'}
                                                size="small"
                                            />
                                        
                                            <Chip
                                                onClick={() => history.push('/admin/versions/view/' + row._id)}
                                                variant="outlined" 
                                                color={'default'}
                                                label={'цааш'}
                                                size="small"
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    </MainCard>
  );
};

export default VersionList
