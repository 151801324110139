import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Pagination,
    Stack,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Typography,
    IconButton
} from '@material-ui/core';
import { FilterList, Clear, Done } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ResetPassword from './resetPassword';
import MyModal from 'components/modal'


import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'
import { datetimeFormat, compareDate } from 'utils/helperFunction'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    },
    ScrollHeight: {
        height: '500px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const Users = ({ isAdmin }) => {
    const classes = useStyles();
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState([]);
    const [type, setType] = useState('all');
    const [notVerified, setNotVerified] = useState('none')
    const [toggleFilter, setToggleFilter] = useState(false);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [sortType, setSortType] = useState('asc');
    const [sorted, setSorted] = useState(false);

    let startIndex = (page - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage

    useEffect(() => {
        setSortType('asc');
        setSorted(false)
        getData(type);
    }, [type, notVerified, page]);

    const getData = async (type) => {
        try {
            
            setIsLoading(true)
          if (type === "all" && notVerified === "none") {
            const response = await apiService.find("user");
            setUsers(response.items);
          } else if (type === "all" && notVerified !== "none") {
            const result2 = await apiService.findSearchBy("user", notVerified);
            setUsers(result2.items);
          } else if (type !== "all" && notVerified === "none") {
            const result2 = await apiService.findSearchBy("user", `userType=${type}`);
            setUsers(result2.items);
          } else if (type !== "all" && notVerified !== "none") {
            const result2 = await apiService.findSearchBy("user", notVerified);
            const filtered = result2.items.filter((user) => {
              return user.userType === type;
            });
            setUsers(filtered);
          }
          
        } catch (e) {
          console.log("Failed to fetch data: ", e);
          return;
        } finally {
            setIsLoading(false);
        }
    };
      
    const handleType = (event, newType) => {
        setPage(1)
        setType(newType);
    };

    const handleVerifiedFilter = (event) => {
        setPage(1)
        setNotVerified(event.target.value);
    }

    const handlePagination = (event, value) => {
        setPage(value);
    }

    const sortUsers = (event) => {
        if(event.target.value === 'desc'){
            let userList = users;
            const sortedList = userList.map(obj => { return { ...obj, createdAt: new Date(obj.createdAt) } })
            .sort((a, b) => b.createdAt - a.createdAt)
            setSorted(true);
            setSortType(event.target.value)
            setUsers(sortedList);
        } else {
            getData(type)
            setSortType(event.target.value);
            setSorted(false)
        }

    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={`Хэрэглэгчид (${users.length})`} content={false}>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={() => history.push('/admin/user/add')}  
                    variant="contained" color="primary">
                    Шинэ хэрэглэгч
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <ToggleButtonGroup
                        value={type}
                        exclusive
                        onChange={handleType}
                        aria-label="text alignment"
                        size="small"
                        sx={{padding: '1rem'}}
                    >
                        <ToggleButton value="all" aria-label="all" >Бүгд</ToggleButton>
                        <ToggleButton value="individual" aria-label="individual">Хувь хүн</ToggleButton>
                        <ToggleButton value="company" aria-label="company">Байгууллага</ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton color="primary" aria-label="filter" component="label" onClick={() => setToggleFilter(!toggleFilter)}>
                        <FilterList/>
                    </IconButton>
                </Box>
            </CardActions>
            {toggleFilter && 
            <>
                <Divider />
                <Box sx={{display: 'flex', justifyContent: 'flex-start', padding: '1rem'}}>
                    <FormControl sx={{padding:0, minWidth: 200 }} size="small">
                        <InputLabel id="demo-select-small-label">Баталгаажуулалт</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={notVerified}
                            label="Баталгаажуулаагүй"
                            onChange={handleVerifiedFilter}
                        >
                            <MenuItem value={'none'}>select</MenuItem>
                            <MenuItem value={'isEmailValidated=true,isPhoneValidated=true,isBankValidated=true'}><Done sx={{color: 'green'}} />Бүгд</MenuItem>
                            <MenuItem value={'isEmailValidated=true'}><Done sx={{color: 'green'}} />Имэйл</MenuItem>
                            <MenuItem value={'isPhoneValidated=true'}><Done sx={{color: 'green'}} />Утас</MenuItem>
                            <MenuItem value={'isBankValidated=true'}><Done sx={{color: 'green'}} />Данс</MenuItem>
                            <MenuItem value={'isEmailValidated=false,isPhoneValidated=false,isBankValidated=false'}><Clear sx={{color: 'red'}} />Бүгд</MenuItem>
                            <MenuItem value={'isEmailValidated=false'}><Clear sx={{color: 'red'}} />Имэйл</MenuItem>
                            <MenuItem value={'isPhoneValidated=false'}><Clear sx={{color: 'red'}} />Утас</MenuItem>
                            <MenuItem value={'isBankValidated=false'}><Clear sx={{color: 'red'}} />Данс</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{marginLeft: '10px', minWidth: 200 }} size="small">
                        <InputLabel id="demo-select-small-label">Эрэмбэлэх</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={sortType}
                            label="Баталгаажуулаагүй"
                            onChange={sortUsers}
                        >
                            <MenuItem value={'asc'}>Бүртгүүлсэн огноо өсөхөөр</MenuItem>
                            <MenuItem value={'desc'}>Бүртгүүлсэн огноо буурахаар</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
            </>}
            <Divider />
            {/* <PerfectScrollbar className={classes.ScrollHeight}> */}
                <CardContent className={classes.projectTableCard}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Овог нэр</TableCell>                                    
                                    <TableCell>Имэйл / Утас / ID</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Бүртгүүлсэн огноо</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        Төлөв
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users && users.map((row, index) => {
                                    const avatarFullUrl = process.env.REACT_APP_API_URL + '/uploads/asset/avatar/' +
                                        (row.avatarUrl ? row.avatarUrl.fileName : 'defaultAvatar.png')
                                    if(index >= startIndex && index < endIndex){
                                        return <TableRow hover key={index}>
                                            <TableCell sx={{width: '10px'}}>{index + 1}.</TableCell>
                                            <TableCell><Avatar alt="coverimage" src={avatarFullUrl} /></TableCell>
                                            <TableCell>
                                                {row.userType === 'individual' ? `${row.lastName} ${row.firstName}` : `${row.companyName}`}
                                                <br />
                                                {row.userType === 'individual' && <Typography variant="caption" display="inline" sx={{color: 'white', backgroundColor: '#757ce8', padding: '2px', borderRadius: '2px', fontSize: '10px'}} gutterBottom>(хувь хүн)</Typography>}
                                                {row.userType === 'company' && <Typography variant="caption" display="inline" sx={{color: 'white', backgroundColor: '#ff7961', padding: '2px', borderRadius: '2px', fontSize: '10px'}} gutterBottom>(байгууллага)</Typography>}
                                            </TableCell>
                                            <TableCell>
                                                {row.email}<br/>
                                                {row.phoneNumber}<br/>
                                                {row.registerNumber}<br/>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>phone: {row.phoneValidate === 'validated' ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}} />}</Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>email: {row.emailValidate === 'validated' ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}} />}</Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>2FA: {row.otp ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}} />}</Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>ID: {row.idValidate === 'validated' ? <Done fontSize="small" sx={{color: 'green'}} /> : <Clear fontSize="small" sx={{color: 'red'}}/>}</Box>
                                            </TableCell>
                                            <TableCell>{row.doc1 ? 'uploaded' : '   '}</TableCell>
                                            {/* <TableCell>{row.userType}</TableCell> */}
                                            <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                            <TableCell align="right" sx={{ pr: 3 }}>
                                                { isAdmin && <Stack direction={'row'} spacing={1} justifyContent="flex-end"><Chip 
                                                    onClick={() => history.push('/admin/user/edit/' + row._id)} 
                                                    variant="outlined" 
                                                    color={'default'} 
                                                    label={'засах'} 
                                                    size="small"
                                                />
                                                <Chip 
                                                    onClick={() => history.push('/useradmin/userview/' + row._id)} 
                                                    variant="outlined" 
                                                    color={'default'} 
                                                    label={'харах'} 
                                                    size="small"
                                                />
                                                <Chip 
                                                    onClick={() => history.push('/user/resetpassword/' + row._id)} 
                                                    variant="outlined" 
                                                    color={'default'} 
                                                    label={'нууц үг солих'} 
                                                    size="small"
                                                />
                                                <Chip 
                                                    // onClick={() => setShowResetPassword(true)} 
                                                    variant="outlined" 
                                                    color={'default'} 
                                                    label={'идэвхгүй болгох'} 
                                                    size="small"
                                                /></Stack>}
                                            </TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{display: 'flex', justifyContent: 'center', paddingY: '1rem'}}>
                        <Stack spacing={2}>
                            <Pagination count={Math.ceil(users.length / Number(itemsPerPage))} page={page} variant="outlined" shape="rounded" onChange={handlePagination} />
                        </Stack>
                    </Box>
                </CardContent>
            {/* </PerfectScrollbar> */}
        </MainCard>
    );
};

Users.propTypes = {
    isAdmin: PropTypes.bool
};

export default Users;
