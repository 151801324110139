import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Grid, Stack, TextField, Typography } from '@material-ui/core';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import Avatar1 from 'assets/images/users/avatar-1.png';
import { useAuth } from 'contexts/auth'

// style constant
const useStyles = makeStyles((theme) => ({
    accountAvatar: {
        width: '100px',
        height: '100px',
        margin: '0 auto'
    },
    accountContent: {
        textAlign: 'center'
    }
}));

//-----------------------|| PROFILE 3 - PROFILE ||-----------------------//

const Confirmation = () => {
    const classes = useStyles();
    const { user } = useAuth();

    const avatarUrl = process.env.REACT_APP_BACKEND_URL + '/uploads/asset/avatar/' + (user && user.avatarUrl.fileName || 'none.jpeg')

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item sm={6} md={4}>
                <SubCard title="Иргэний үнэмлэх баталгаажуулалт" contentClass={classes.accountContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Avatar alt="User 1" src={avatarUrl} className={classes.accountAvatar} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="center">
                                Иргэний үнэмлэхний зураг оруулах
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button variant="contained" color="primary" size="small">
                                    Зураг хуулах
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item sm={6} md={8}>
                <SubCard title="Имэйл хаяг баталгаажуулалт">
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-basic6"
                                fullWidth
                                label="Имэйл хаяг"
                                variant="outlined"
                                defaultValue={user.email}
                                disabled
                            />
                        </Grid>
                        {/* <Grid item md={6} xs={12}>
                            <TextField id="outlined-basic8" fullWidth label="Birthday" variant="outlined" defaultValue="31/01/2001" />
                        </Grid> */}
                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" color="primary">
                                        Хадгалах
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default Confirmation;
