import { useState, useEffect } from 'react'
import { IonLoading } from '@ionic/react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MainCard from 'ui-component/cards/MainCard';
import apiService from 'api/apiService'

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack
} from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

const OrderedIEO = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [IEOs, setIEOs] = useState([])

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            setIsLoading(true)
            const response = await apiService.find('ieo')
            setIEOs(response.items);
        } catch(e) {
            console.log('failed to fetch: ', e)
            return;
        } finally {
            setIsLoading(false)
        }
    }

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Захиалагдсан IEO'} content={false}>
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Байр</TableCell>
                                <TableCell>Захиалга хийсэн хүний тоо</TableCell>
                                <TableCell>Зарагдсан puzzle</TableCell>
                                <TableCell>Үлдсэн puzzle</TableCell>
                                <TableCell>Төлөв</TableCell>
                                <TableCell>Огноо</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {IEOs && IEOs.map((row, index) => {
                                if(Number(row.totalSoldQty) > 0){
                                    return (
                                        <TableRow hover key={index}>
                                            <TableCell>{index+1}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.totalOwners}</TableCell>
                                            <TableCell>{row.totalSoldQty}</TableCell>
                                            <TableCell>{row.totalLeftQty}</TableCell>
                                            <TableCell>{row.isDone ? 'Дууссан' : 'Нээлттэй'}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    onClick={() => history.push('/ieo/orders/' + row._id)}
                                                    variant="outlined"
                                                    color={'default'}
                                                    label={'дэлгэрэнгүй'}
                                                    size="small"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    )
}

export default OrderedIEO