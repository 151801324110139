import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CardActions,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { datetimeFormat } from 'utils/helperFunction'
import { IonLoading } from '@ionic/react'
import { useAuth } from 'contexts/auth';
import apiService from 'api/apiService'

// style constant
const useStyles = makeStyles((theme) => ({
    projectTableCard: {
        padding: '0px'
    }
}));

// table data
function createData(badgeText, badgeType, subject, dept, date) {
    return { badgeText, badgeType, subject, dept, date };
}

const rows = [
    createData('Open', 'default', 'Website down for one week', 'Support', 'Today 2:00'),
];

//==========================|| DATA WIDGET - RECENT TICKETS CARD ||==========================//

const FiatWallets = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory()

    let [isLoading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiService.findSearchBy('user', 'phoneValidate=validated,emailValidate=validated,idValidate=validated')
            setTrainings(response.items)
        }
        catch (e) {
            console.log('Failed to fetch data: ', e)
            return;
        } finally {
            setLoading(false);
        }
        //return response.items;
    };

    return (
        isLoading ? <IonLoading isOpen={isLoading} /> :
        <MainCard title={'Баталгаажуулсан хэрэглэгчид'} content={false}>
            <CardActions sx={{ justifyContent: 'flex-start' }}>
                <Button
                    onClick={() => history.push('/airdrop/addToUser')}  
                    variant="contained" color="primary">
                    Air drop хийх
                </Button>
            </CardActions>
            <Divider />
            <CardContent className={classes.projectTableCard}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>Хэрэглэгч</TableCell>
                                <TableCell>Баталгаажуулалт</TableCell>
                                <TableCell>Үүсгэсэн огноо</TableCell>
                                <TableCell align="right" sx={{ pr: 3 }}>
                                    Төлөв
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainings && trainings.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ pl: 3 }}>{row.username}</TableCell>
                                    <TableCell>
                                        phone: {row.phoneValidate}<br/>
                                        email: {row.emailValidate}<br/>
                                        ID: {row.idValidate}
                                    </TableCell>
                                    <TableCell>{datetimeFormat(row.createdAt)}</TableCell>
                                    <TableCell align="right" sx={{ pr: 3 }}>
                                        <Chip
                                            onClick={() => history.push('/admin/user/edit/' + row._id)}
                                            variant="outlined"
                                            color={'default'}
                                            label={'засах'}
                                            size="small"
                                        />
                                    
                                        <Chip
                                            onClick={() => history.push('/admin/user/view/' + row._id)}
                                            variant="outlined" 
                                            color={'default'}
                                            label={'цааш'}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </MainCard>
    );
};

// Departments.propTypes = {
//     departments: PropTypes.string
// };

export default FiatWallets;
