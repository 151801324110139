import { AxiosResponse } from 'axios';
import { JsonBody, makeRequest } from './requestBuilder';

export * from './config';

// export interface RequestOptions {
//     apiVersion: 'applogic' | 'peatio' | 'barong' | 'finex' | 'sonic';
//     withHeaders?: boolean;
//     headers?: any;
// }

// export type RequestBody = JsonBody | FormData;

// export type RequestMethod = (
//     config: RequestOptions
// ) => (url: string, body?: RequestBody) => Promise<AxiosResponse['data']>;

// export interface ApiWrapper {
//     get: RequestMethod;
//     post: RequestMethod;
//     patch: RequestMethod;
//     put: RequestMethod;
//     delete: RequestMethod;
// }

export default class API {
    static async post(url, postData) {
        const token = ''
        
        const result = fetch(url, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }
}

export const API2 = {
    get: (config) => async (url) =>
        makeRequest(
            {
                method: 'get',
                url,
            },
            config
        ),

    post: (config) => async (url, body) =>
        makeRequest(
            {
                method: 'post',
                body,
                url,
            },
            config
        ),

    patch: (config) => async (url, body) =>
        makeRequest(
            {
                method: 'patch',
                body,
                url,
            },
            config
        ),

    put: (config) => async (url, body) =>
        makeRequest(
            {
                method: 'put',
                body,
                url,
            },
            config
        ),

    delete: (config) => async (url) =>
        makeRequest(
            {
                method: 'delete',
                url,
            },
            config
        ),
};
