import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */
 
import logoDark from 'assets/images/logo.png';
import logo from 'assets/images/logo.png';

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
      //    <svg
      //    xmlns="http://www.w3.org/2000/svg"
      //    width={92}
      //    height={54}
      //    viewBox="0 0 180.04 245.14"
      //    shapeRendering="geometricPrecision"
      //    textRendering="geometricPrecision"
      //    imageRendering="optimizeQuality"
      //    fillRule="evenodd"
      //    clipRule="evenodd"
      //    >
      //    <path
      //      d="M138.13 44.95a119.327 119.327 0 01-48.11-19.08 119.327 119.327 0 01-48.11 19.08v33.01c0 32.13 18.86 61.32 48.11 74.5 20.96-9.45 36.96-27.35 44.08-49.16-22.34-4.28-42.95-14.87-59.52-30.48l-1.21-1.14 1.42-.87c5.1-3.13 9.91-6.71 14.36-10.7l.84-.76.85.75c18.25 16.16 41.56 24.99 65.93 24.99h1.47l-.11 1.38a101.8 101.8 0 01-1.59 11.4l-.02.09-.33 1.62-.04.17c-.11.51-.22 1.02-.34 1.52l-.06.25c-.11.48-.23.95-.35 1.43l-.08.33c-.1.4-.21.8-.31 1.2-8.69 32.38-32.97 58.57-64.56 69.74l-.42.15-.42-.15c-14.37-5.08-27.42-13.35-38.19-24.12-19.18-19.2-29.85-44.98-29.85-72.12V26.13h1.27c24.57 0 48.04-8.96 66.34-25.35l.85-.76.85.76c18.3 16.38 41.78 25.35 66.34 25.35h1.27v43.71h-1.82c-5.89-.01-11.77-.63-17.53-1.85l-1.01-.21v-22.8zM115.41 244.8c10.04 0 16.6-5.91 19.17-14.05l-9.15-5.28c-.39 5.19-4.6 9.29-10.02 9.29s-10.05-4.5-10.05-10.05v-10.05c0-5.55 4.63-10.05 10.05-10.05 5.42 0 9.63 4.09 10.02 9.29l9.15-5.28c-2.57-8.14-9.12-14.05-19.17-14.05-10.04 0-20.09 9-20.09 20.09v10.05c0 11.1 10.05 20.09 20.09 20.09zm24.45-40.19v-10.05h40.19v10.05h-15.07v40.19h-10.05v-40.19h-15.07zM0 194.56h10.05v20.09h20.09v-20.09h10.05v50.23H30.14V224.7H10.05v20.09H0v-50.23zm67.34 50.57c-6.4 0-14.53-1.54-19.7-5.5l4.92-9.66c4.45 3.26 9.35 4.47 14.84 4.47 3.41 0 9.43.07 9.43-4.52 0-3.2-6.68-3.56-10.62-4.5-8.41-2-17.81-5.04-17.81-15.63 0-11.3 10.98-15.23 20.61-15.23 5.69 0 13 1.89 17.87 4.93l-4.5 8.82c-4.52-2.56-8.92-3.6-13.44-3.6-5.69 0-9.29.47-9.29 5.23 0 4.5 15.1 5.81 19.77 7.55 5.45 2.03 8.68 6.36 8.66 12.48-.04 11.29-11.28 15.16-20.75 15.16z"
      //      fill="#0c4a88"
      //    />
      //  </svg>

      <img height={54} width={54} src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Logo" />
    );
};

export default Logo;
